import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedHomeDetails } from '../../../../../../../../state/home/homeSlice';

const AddressInput = () => {
  const dispatch = useDispatch();
  const selectedHomeDetails = useSelector((state) => state.home.selected_home_details);
  const reduxAddress = selectedHomeDetails?.address || ""; // Ensure a default value

  // 🔥 Local state for smooth typing
  const [inputValue, setInputValue] = useState(reduxAddress);

  useEffect(() => {
    if (reduxAddress !== inputValue) {
      setInputValue(reduxAddress);
    }
  }, [reduxAddress]);

  const handleChange = (e) => {
    const newAddress = e.target.value;
    setInputValue(newAddress);

    // ✅ Update only the address while keeping other home details
    dispatch(updateSelectedHomeDetails({ 
      ...selectedHomeDetails, // Keep all existing details
      address: newAddress // Update only address
    }));
  };

  return (
    <div className="form-group">
      <label htmlFor="address">Address:</label>
      <input
        id="address"
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter home address"
      />
    </div>
  );
};

export default AddressInput;
