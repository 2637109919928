import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  taxBenefits: {
    width: 250,
    height: 186,
    position: "relative",
    overflow: "hidden",
  },
  header: {
    width: 257,
    height: 17,
    position: "absolute",
    left: 3,
    top: 0,
    color: "#243144",
    fontSize: 12,
    fontWeight: "bold",
  },
  separator: {
    alignSelf: "stretch",
    height: 0,
    backgroundColor: "#D37F51",
    borderBottomWidth: 1,
    borderBottomColor: "#D37F51",
    position: "absolute",
    left: 3,
    top: 16, // Adjust this if needed to align properly under the header
    width: 225,
  },
  content: {
    width: 257,
    height: 105,
    position: "absolute",
    left: 3,
    top: 20, // Adjust this if needed to align properly under the separator
    color: "black",
    fontSize: 10,
    fontWeight: "normal",
  },
});

export const TaxBenefitsInfo = () => {
  return (
    <View style={styles.taxBenefits}>
      <Text style={styles.header}>Tax Benefits</Text>
      <View style={styles.separator} />
      <Text style={styles.content}>
        Income: $200000{"\n"}
        Total Interest: $52041.96{"\n"}
        Property Tax: $10000.00{"\n"}
        Filing Status: Jointly{"\n"}
        Standard Deduction: $29200{"\n"}
        Income with Standard Deduction: $170800.00{"\n"}
        Taxes Paid: $27681.66{"\n"}
        Income with Interest & Tax Deductions: $137958.04{"\n"}
        Taxes Paid After Deductions: $20456.43{"\n"}
        Net Tax Benefit: $7225.23{"\n"}
        Monthly Tax Benefit: $602.10
      </Text>
    </View>
  );
};

export default TaxBenefitsInfo;
