import React from 'react';
import { useSelector } from 'react-redux';
import { saveClientAssumptions } from './fetches/saveClientAssumptions';
import CurrentRentInput from './formInputs/CurrentRentInput';
import RentGrowthRateInput from './formInputs/RentGrowthRateInput';
import MonthlyRentInsuranceInput from './formInputs/MonthlyRentInsuranceInput';
import UtilitiesInput from './formInputs/UtilitiesInput';
import OtherRentExpensesInput from './formInputs/OtherRentExpensesInput';
import './styling/rentStyles.css';

const RentInfoMain = () => {
  const selectedAssumptions = useSelector(
    (state) => state.clientAssumptions.selected_assumptions
  );

  const handleSave = async () => {
    const result = await saveClientAssumptions(selectedAssumptions);

    if (result.success) {
      alert("Assumptions saved successfully!");
    } else {
      alert(`Failed to save assumptions: ${result.error}`);
    }
  };

  return (
    <div className="assumptions-main">
      <h2>Buyer Status & Rental Info</h2>
      <form>
        <CurrentRentInput />
        <RentGrowthRateInput />
        <MonthlyRentInsuranceInput />
        <UtilitiesInput />
        <OtherRentExpensesInput />
        <button type="button" className="save-button" onClick={handleSave}>
          Save Assumptions
        </button>
        <p className="disclaimer">
          Note: Please ensure all information is accurate to proceed with the analysis.
        </p>
      </form>
    </div>
  );
};

export default RentInfoMain;
