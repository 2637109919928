// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import {
//   calculatePaymentPerPeriod,
//   calculateTotalInterest,
//   calculateTotalPITI,
//   calculateTaxesPaid
// } from './calculations/taxCalcs.js';
// import TaxBracketModal from '../../onboardingClient/components/UsedInPresentationInfo/Affordability/components/TaxBracketModal';

// const TaxBenefits = ({ onTaxDataChange }) => {
//   // Accessing Redux store
//   const affordabilityDetails = useSelector((state) => state.clientAffordability.selected_affordability_details);
//   const homeDetails = useSelector((state) => state.home.selected_home_details);

//   // Local state for mortgage calculations
//   const loanAmount = homeDetails.home_price - homeDetails.down_payment;
//   const interestRate = homeDetails.interest_rate;
//   const years = homeDetails.term_months / 12; //TODO magic number for years, assuming always using 1 year
//   const paymentsPerYear = homeDetails.payments_per_year;
  
//   const homePrice = homeDetails.home_price;
//   const propertyTaxRate = homeDetails.property_tax_rate;
//   const filingStatus = affordabilityDetails.filing_status === 'single' ? 'Single' : 'Jointly';

//   // Compute values using utility functions
//   const paymentPerPeriod = calculatePaymentPerPeriod(loanAmount, interestRate, years, paymentsPerYear);
//   const totalInterest = calculateTotalInterest(loanAmount, interestRate, years, paymentsPerYear);

//   // Additional tax benefit calculations
//   const income = affordabilityDetails.current_annual_gross_income;
//   const standardDeduction = filingStatus === 'Single' ? 14600 : 29200;

//   const propertyTax = Math.min(((homePrice * propertyTaxRate) / 100), 10000);


//   // Calculating Income after deductions
//   const incomeWithStandardDeduction = income - standardDeduction;
//   const taxesPaid = calculateTaxesPaid(incomeWithStandardDeduction, filingStatus);

//   const incomeWithInterestAndTaxDeductions = income - totalInterest - propertyTax;
//   const taxesPaidWithDeductions = calculateTaxesPaid(incomeWithInterestAndTaxDeductions, filingStatus);

//   // Calculating Net Tax Benefit (simplified approach)
//   const netTaxBenefit = taxesPaid - taxesPaidWithDeductions;

//   // Monthly Tax Benefit (simplified)
//   const monthlyTaxBenefit = netTaxBenefit / 12;

//   useEffect(() => {
//     if (onTaxDataChange) {
//       onTaxDataChange({
//         income,
//         totalInterest,
//         propertyTax,
//         filingStatus,
//         standardDeduction,
//         incomeWithStandardDeduction,
//         taxesPaid,
//         incomeWithInterestAndTaxDeductions,
//         taxesPaidWithDeductions,
//         netTaxBenefit,
//         monthlyTaxBenefit
//       });
//     }
//   }, [
//     income,
//     totalInterest,
//     propertyTax,
//     filingStatus,
//     standardDeduction,
//     incomeWithStandardDeduction,
//     taxesPaid,
//     incomeWithInterestAndTaxDeductions,
//     taxesPaidWithDeductions,
//     netTaxBenefit,
//     monthlyTaxBenefit,
//     onTaxDataChange
//   ]);

//   return (
//     <div className="section tax-benefits">
//       <h2>Tax Benefits</h2>
//       <div>
//         <p><strong>Income:</strong> ${income}</p>
//         <p><strong>Total Interest:</strong> ${totalInterest}</p>
//         <p><strong>Property Tax:</strong> ${propertyTax.toFixed(2)}</p>
//         <p><strong>Filing Status:</strong> {filingStatus}</p>
//         <p><strong>Standard Deduction:</strong> ${standardDeduction}</p>
//         <p><strong>Income with Standard Deduction:</strong> ${incomeWithStandardDeduction.toFixed(2)}</p>
//         <p><strong>Taxes Paid:</strong> ${taxesPaid.toFixed(2)}</p>
//         <p><strong>Income with Interest & Tax Deductions:</strong> ${incomeWithInterestAndTaxDeductions.toFixed(2)}</p>
//         <p><strong>Taxes Paid After Deductions:</strong> ${taxesPaidWithDeductions.toFixed(2)}</p>
//         <p><strong>Net Tax Benefit:</strong> ${netTaxBenefit.toFixed(2)}</p>
//         <p><strong>Monthly Tax Benefit:</strong> ${monthlyTaxBenefit.toFixed(2)}</p>
//       </div>

//       <TaxBracketModal isOpen={false} onClose={() => {}} />
//     </div>
//   );
// };

// export default TaxBenefits;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateCurrentAnnualGrossIncome,
  updateFilingStatus,
  updateStandardDeduction
} from '../../../../../state/client/clientAffordabilitySlice';
import {
  calculatePaymentPerPeriod,
  calculateTotalInterest,
  calculateTotalPITI,
  calculateTaxesPaid
} from './calculations/taxCalcs.js';
import TaxBracketModal from '../../onboardingClient/components/UsedInPresentationInfo/Affordability/components/TaxBracketModal';

const TaxBenefits = ({ onTaxDataChange }) => {
  const dispatch = useDispatch();
  const affordabilityDetails = useSelector((state) => state.clientAffordability.selected_affordability_details);
  const homeDetails = useSelector((state) => state.home.selected_home_details);

  const loanAmount = homeDetails.home_price - homeDetails.down_payment;
  const interestRate = homeDetails.interest_rate;
  const years = homeDetails.term_months / 12;
  const paymentsPerYear = homeDetails.payments_per_year;

  const homePrice = homeDetails.home_price;
  const propertyTaxRate = homeDetails.property_tax_rate;

  const income = affordabilityDetails.current_annual_gross_income ?? 0;
  const filingStatus = affordabilityDetails.filing_status === 'married_filing_jointly' ? 'Jointly' : 'Single';
  const standardDeduction =
    affordabilityDetails.standard_deduction ?? (filingStatus === 'Single' ? 14600 : 29200);

  const paymentPerPeriod = calculatePaymentPerPeriod(loanAmount, interestRate, years, paymentsPerYear);
  const totalInterest = calculateTotalInterest(loanAmount, interestRate, years, paymentsPerYear);
  const propertyTax = Math.min(((homePrice * propertyTaxRate) / 100), 10000);

  const incomeWithStandardDeduction = income - standardDeduction;
  const taxesPaid = calculateTaxesPaid(incomeWithStandardDeduction, filingStatus);

  const incomeWithInterestAndTaxDeductions = income - totalInterest - propertyTax;
  const taxesPaidWithDeductions = calculateTaxesPaid(incomeWithInterestAndTaxDeductions, filingStatus);

  const netTaxBenefit = taxesPaid - taxesPaidWithDeductions;
  const monthlyTaxBenefit = netTaxBenefit / 12;

  useEffect(() => {
    if (onTaxDataChange) {
      onTaxDataChange({
        income,
        totalInterest,
        propertyTax,
        filingStatus,
        standardDeduction,
        incomeWithStandardDeduction,
        taxesPaid,
        incomeWithInterestAndTaxDeductions,
        taxesPaidWithDeductions,
        netTaxBenefit,
        monthlyTaxBenefit
      });
    }
  }, [
    income,
    totalInterest,
    propertyTax,
    filingStatus,
    standardDeduction,
    incomeWithStandardDeduction,
    taxesPaid,
    incomeWithInterestAndTaxDeductions,
    taxesPaidWithDeductions,
    netTaxBenefit,
    monthlyTaxBenefit,
    onTaxDataChange
  ]);

  const rowStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
  };

  const labelStyle = {
    width: '200px',
    fontWeight: 'bold',
  };

  const inputStyle = {
    padding: '6px 10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    width: '160px',
  };

  return (
    <div className="section tax-benefits">
      <h2>Tax Benefits</h2>

      {/* Editable: Income */}
      <div style={rowStyle}>
        <label style={labelStyle}>Income:</label>
        <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        <span>$</span>
          <input
            type="number"
            value={income}
            onChange={(e) => dispatch(updateCurrentAnnualGrossIncome(Number(e.target.value)))}
            style={inputStyle}
          />
          
        </div>
      </div>

{/* Editable: Filing Status */}
<div style={rowStyle}>
  <label style={labelStyle}>Filing Status:</label>
  <div style={{ display: 'flex', alignItems: 'center', gap: '6px', paddingLeft: '14px' }}>
    <select
      value={filingStatus}
      onChange={(e) =>
        dispatch(updateFilingStatus(e.target.value === 'Single' ? 'single' : 'married_filing_jointly'))
      }
      style={inputStyle}
    >
      <option value="Single">Single</option>
      <option value="Jointly">Jointly</option>
    </select>
  </div>
</div>


      {/* Editable: Standard Deduction */}
      <div style={rowStyle}>
        <label style={labelStyle}>Standard Deduction:</label>
        <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        <span>$</span>
          <input
            type="number"
            value={standardDeduction}
            onChange={(e) => dispatch(updateStandardDeduction(Number(e.target.value)))}
            style={inputStyle}
          />
          
        </div>
      </div>

      {/* Readonly */}
      <div style={rowStyle}>
        <label style={labelStyle}>Total Interest:</label>
        <span>$ {totalInterest}</span>
      </div>

      <div style={rowStyle}>
        <label style={labelStyle}>Property Tax:</label>
        <span>$ {propertyTax.toFixed(2)}</span>
      </div>

      {/* Readonly Calculated */}
      <div style={rowStyle}>
        <label style={labelStyle}>Income w/ Std Deduction:</label>
        <span>$ {incomeWithStandardDeduction.toFixed(2)}</span>
      </div>

      <div style={rowStyle}>
        <label style={labelStyle}>Taxes Paid:</label>
        <span>$ {taxesPaid.toFixed(2)}</span>
      </div>

      <div style={rowStyle}>
        <label style={labelStyle}>Income w/ All Deductions:</label>
        <span>$ {incomeWithInterestAndTaxDeductions.toFixed(2)}</span>
      </div>

      <div style={rowStyle}>
        <label style={labelStyle}>Taxes After Deductions:</label>
        <span>$ {taxesPaidWithDeductions.toFixed(2)}</span>
      </div>

      <div style={rowStyle}>
        <label style={labelStyle}>Net Tax Benefit:</label>
        <span>$ {netTaxBenefit.toFixed(2)}</span>
      </div>

      <div style={rowStyle}>
        <label style={labelStyle}>Monthly Tax Benefit:</label>
        <span>$  {monthlyTaxBenefit.toFixed(2)}</span>
      </div>

      <TaxBracketModal isOpen={false} onClose={() => {}} />
    </div>
  );
};

export default TaxBenefits;
