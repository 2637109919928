import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateInterestRate } from '../../../../../../../../state/home/homeSlice';
import FormattedNumberInput from '../../../../utils/FormattedNumberInput';

const LoanInterestRateInput = () => {
  const dispatch = useDispatch();
  const reduxInterestRate = useSelector(
    (state) => state.home.selected_home_details.interest_rate
  );

  // 🔥 Local state for smooth typing experience
  const [inputValue, setInputValue] = useState(reduxInterestRate?.toString() || "");

  useEffect(() => {
    if (reduxInterestRate?.toString() !== inputValue) {
      setInputValue(reduxInterestRate?.toString() || "");
    }
  }, [reduxInterestRate]);

  const handleChange = (value) => {
    // ✅ Allow blank input or just a decimal for smoother typing
    if (value === "" || value === ".") {
      setInputValue(value);
      return;
    }

    // ✅ Ensure valid percentage input (up to 2 decimal places)
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setInputValue(value);
      dispatch(updateInterestRate(parseFloat(value) || 0)); // ✅ Always store as number in Redux
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="loan-interest-rate">Loan Interest Rate (%):</label>
      <FormattedNumberInput
        id="loan-interest-rate"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter loan interest rate"
        allowDecimals={true}
      />
    </div>
  );
};

export default LoanInterestRateInput;
