import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "@mui/material/Slider";
import { updateSelectedMonth } from "../../../../../../state/presentation/adjustInputsSlice";

// Converts months to "X years Y months"
const formatMonthsToYears = (months) => {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;
  if (years > 0 && remainingMonths > 0) {
    return `${years} year${years > 1 ? "s" : ""} ${remainingMonths} month${remainingMonths > 1 ? "s" : ""}`;
  } else if (years > 0) {
    return `${years} year${years > 1 ? "s" : ""}`;
  } else {
    return `${remainingMonths} month${remainingMonths > 1 ? "s" : ""}`;
  }
};

const TimeScroller = () => {
  const dispatch = useDispatch();

  // Get loan term (years) and convert to months
  const loanTermMonths = useSelector((state) => state.adjustInputs.ownInputs.loanTerm * 12);
  const selectedMonth = useSelector((state) => state.adjustInputs.selectedMonth);

  const handleChange = (event, newValue) => {
    dispatch(updateSelectedMonth(newValue)); // Store month value in Redux
  };

  return (
    <div className="time-scroller">
      <label>
        Selected Time: {formatMonthsToYears(selectedMonth)}
      </label>
      <Slider
        value={selectedMonth}
        min={0}
        max={loanTermMonths}
        step={1} // Moves in 1-month increments
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => formatMonthsToYears(value)}
      />
    </div>
  );
};

export default TimeScroller;
