export const saveClientAssumptions = async (assumptions) => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/client_assumptions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(assumptions),
        }
      );
  
      if (response.ok) {
        const result = await response.json();
        console.log("Assumptions saved successfully:", result);
        return { success: true, data: result };
      } else {
        const error = await response.json();
        console.error("Failed to save assumptions:", error);
        return { success: false, error };
      }
    } catch (error) {
      console.error("Error saving assumptions:", error);
      return { success: false, error: error.message };
    }
  };
  