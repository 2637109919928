import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { useSelector, useDispatch } from 'react-redux';
import { updateHomePrice } from '../../../../../../state/presentation/adjustInputsSlice';

const HomePriceInput = () => {
  const dispatch = useDispatch();

  // Fetch home price from Redux (adjustInputsSlice)
  const homePrice = useSelector((state) => state.adjustInputs.ownInputs.homePrice);

  // Local state for immediate UI updates
  const [localValue, setLocalValue] = useState(homePrice ?? 200000); // Default $200,000

  // Sync local state with Redux when Redux value changes
  useEffect(() => {
    if (homePrice !== null && homePrice !== undefined) {
      setLocalValue(homePrice);
    }
  }, []); // ✅ Runs only on mount to prevent unnecessary resets

  const handleChange = (event, newValue) => {
    setLocalValue(newValue); // ✅ Update UI immediately
    dispatch(updateHomePrice(newValue)); // ✅ Persist in Redux
  };

  return (
    <div>
      <label>Home Price: ${localValue.toLocaleString()}</label>
      <Slider
        value={localValue}
        min={50000}
        max={2000000}
        step={10000}
        onChange={(event, newValue) => handleChange(event, newValue)}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `$${value.toLocaleString()}`} // Display formatted values
      />
    </div>
  );
};

export default HomePriceInput;
