import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { useSelector, useDispatch } from 'react-redux';
import { updateMonthlyRent } from '../../../../../../state/presentation/adjustInputsSlice';

const CurrentRentSlider = () => {
  const dispatch = useDispatch();

  // Fetch from Redux
  const monthlyRent = useSelector((state) => state.adjustInputs.rentInputs.monthlyRent);

  // State should initialize from Redux, but not reset when other Redux states update
  const [localValue, setLocalValue] = useState(monthlyRent || 1000);

  // Only initialize local state when component mounts or Redux changes from null to a valid value
  useEffect(() => {
    if (monthlyRent !== null && monthlyRent !== undefined) {
      setLocalValue(monthlyRent);
    }
  }, []); // ✅ Empty dependency array prevents re-renders from resetting value

  const handleChange = (event, newValue) => {
    setLocalValue(newValue); // Keep UI responsive
    dispatch(updateMonthlyRent(newValue)); // ✅ Dispatch update to Redux
  };

  return (
    <div>
      <label>Monthly Rent: ${localValue}</label>
      <Slider
        value={localValue}
        min={500}
        max={5000}
        step={50}
        onChange={(event, newValue) => handleChange(event, newValue)}
        valueLabelDisplay="auto"
      />
    </div>
  );
};

export default CurrentRentSlider;
