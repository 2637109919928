import React from 'react';

const SummaryBox = ({ title, content, backgroundColor }) => {


  
  return (
    <div className="summary-box" style={{ backgroundColor }}>
      <h3>{title}</h3>
      {/* Split content by newline and display each line with bolded words */}
      <p>
        {content.split('\n').map((line, index) => {
          // Split the line into text and number
          const [text, number] = line.split(':');

          // Conditional styling for the "More Per Month" line
          if (text === 'More Per Month') {
            const isMore = parseFloat(number.replace(/[^0-9.-]+/g, "")) > 0; // Check if the value is positive or negative
            const style = {
              color: isMore ? 'red' : 'green', // red for more, green for less
            };

            return (
              <span key={index}>
                <strong>{text}:</strong> <span style={style}>{number}</span><br />
              </span>
            );
          } else {
            return (
              <span key={index}>
                <strong>{text}:</strong> {number}<br />
              </span>
            );
          }
        })}
      </p>
    </div>
  );
};

export default SummaryBox;


// import React, { useState } from 'react';

// const calculationExplanations = {
//   'Rent vs Total PITI': {
//     'Total PITI': `
// **Total PITI** = Payment Per Period + Property Tax + Insurance + PMI + HOA  
// \`\`\`js
// calculateTotalPITI(paymentPerPeriod, propertyTax, insurance, pmi, hoa)
// \`\`\`
//     `,
//     'Current Rent': `
// This is the client's current monthly rent input.
//     `,
//     'More Per Month': `
// **More Per Month** = Total PITI - Current Rent  
// \`\`\`js
// morePerMonth = totalPITI - currentRent
// \`\`\`
//     `
//   }
// };

// const SummaryBox = ({ title, content, backgroundColor }) => {
//   const [hoveredKey, setHoveredKey] = useState(null);

//   const explanations = calculationExplanations[title] || {};

//   return (
//     <div className="summary-box" style={{ backgroundColor, position: 'relative' }}>
//       <h3>{title}</h3>
//       <p style={{ position: 'relative' }}>
//         {content.split('\n').map((line, index) => {
//           const [text, number] = line.split(':');
//           const trimmedText = text?.trim();

//           const isMore = trimmedText === 'More Per Month';
//           const isPositive = parseFloat(number?.replace(/[^0-9.-]+/g, '')) > 0;

//           const style = isMore ? { color: isPositive ? 'red' : 'green' } : {};

//           return (
//             <span
//               key={index}
//               onMouseEnter={() => setHoveredKey(trimmedText)}
//               onMouseLeave={() => setHoveredKey(null)}
//               style={{ position: 'relative', cursor: explanations[trimmedText] ? 'help' : 'default', display: 'inline-block' }}
//             >
//               <strong>{text}:</strong>{' '}
//               <span style={style}>{number}</span>
//               <br />

//               {/* Tooltip */}
//               {hoveredKey === trimmedText && explanations[trimmedText] && (
//                 <div
//                   style={{
//                     position: 'absolute',
//                     top: '-110px',
//                     left: '50%',
//                     transform: 'translateX(-50%)',
//                     background: '#f9f9f9',
//                     color: '#333',
//                     padding: '10px',
//                     borderRadius: '8px',
//                     width: '300px',
//                     zIndex: 9999,
//                     fontSize: '0.85rem',
//                     boxShadow: '0 2px 10px rgba(0,0,0,0.25)',
//                     border: '1px solid #ccc',
//                     whiteSpace: 'pre-wrap',
//                     lineHeight: '1.4',
//                   }}
//                 >
//                   {explanations[trimmedText]}
//                 </div>
//               )}
//             </span>
//           );
//         })}
//       </p>
//     </div>
//   );
// };

// export default SummaryBox;
