export const fetchClientGeneralInfo = async (clientId) => {
  if (!clientId || clientId === -1) {
      console.error("❌ Invalid client ID provided to fetchClientGeneralInfo");
      return null;
  }

  const token = localStorage.getItem('token');
  if (!token) {
      console.error("❌ No token found, please log in.");
      return null;
  }

  try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_client_general_info/${clientId}`, {
          method: "GET",
          headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
          },
      });

      if (!response.ok) {
          console.error(`❌ HTTP Error: ${response.status} ${response.statusText}`);
          return null;
      }

      const data = await response.json();
      return data || { 
        client_id: clientId, 
        inflation_rate: 0, 
        expected_investment_growth: 0, 
        general_monthly_expenses: 0, 
        expected_salary_increase: 0,
    }; // ✅ Ensure default values

  } catch (error) {
      console.error(`❌ An error occurred while fetching general info: ${error.message}`);
      return null;
  }
};
