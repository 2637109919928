import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setClients,
  updateSelectedClient,
} from '../../../../state/client/clientSlice';
import {
  setHomes,
  updateSelectedHome,
  updateSelectedHomeDetails,
  addHome as addHomeRedux,
  updateHome as updateHomeRedux,
} from '../../../../state/home/homeSlice';
import { addHome } from '../../../../utils/addHome';
import { updateHome } from '../../../../utils/updateHome';
import { fetchHomes } from '../../../../apiFetch/fetchHomes';
// import { Dropdown } from 'primereact/dropdown'; OLD DROPDOWN
import HomeDropdown from '../PresentMode/LoanPacket/HomeDropdown';
import FormattedNumberInput from '../onboardingClient/utils/FormattedNumberInput';
import '../styling/AddHomePage.css';
import { calculateInterestRate } from '../PresentMode/utils/CreditUtils';
import InterestRateModal from './LoanInterestModal';
import { deleteHome } from '../../../../utils/deleteHome';

const AddHomePage = () => {
  const dispatch = useDispatch();

  // Access Redux state
  const clients = useSelector((state) => state.client.clients);
  const selectedClientId = useSelector((state) => state.client.selected_client_id);
  const affordability = useSelector(
    (state) => state.clientAffordability.selected_affordability_details
  );
  const homes = useSelector((state) =>
    Object.values(state.home.homes).filter((home) => home.client_id === selectedClientId)
  );
  const selectedHomeId = useSelector((state) => state.home.selected_home_id);
  const selectedHomeDetails = useSelector((state) => state.home.selected_home_details);

  const [isEditing, setIsEditing] = useState(false);
  const [isDownPaymentPercentage, setIsDownPaymentPercentage] = useState(false); // Default to dollar format

  const [isInterestRateModalOpen, setIsInterestRateModalOpen] = useState(false);

  const handleOpenInterestRateModal = () => setIsInterestRateModalOpen(true);
  const handleCloseInterestRateModal = () => setIsInterestRateModalOpen(false);

  const [baseTaxRate, setBaseTaxRate] = useState(1); // Default to 1
  const [additionalLocalTaxRate, setAdditionalLocalTaxRate] = useState(null); // Optional input


  const handleDownPaymentChange = (value) => {
    if (isDownPaymentPercentage) {
      const homePrice = parseFloat(selectedHomeDetails.home_price) || 0;
      const dollarAmount = (value / 100) * homePrice; // Convert percentage to dollar amount
      handleChange('down_payment', Math.round(dollarAmount));
    } else {
      handleChange('down_payment', value); // Store dollar amount directly
    }
  };

  useEffect(() => {
    if (selectedClientId && selectedHomeId !== -1 && !selectedHomeId) {
      fetchHomes(selectedClientId, (fetchedHomes) => {
        dispatch(setHomes(fetchedHomes)); // Populates `homes` in the Redux store
        console.log("fetched homes", fetchedHomes)
      });

    }
  }, [selectedClientId, dispatch]);

  useEffect(() => {
    const additionalRate = additionalLocalTaxRate || 0; // Default additional rate to 0 if null
    const calculatedPropertyTaxRate = baseTaxRate + additionalRate;
    handleChange("property_tax_rate", calculatedPropertyTaxRate.toFixed(2));
  }, [baseTaxRate, additionalLocalTaxRate]);

  useEffect(() => {
    console.log('Selected Home Details:', selectedHomeDetails);
  }, [selectedHomeDetails]);

  useEffect(() => {
    setIsEditing(selectedHomeId !== -1);
  }, [selectedHomeId]);

  useEffect(() => {
    const loanAmount = (selectedHomeDetails.home_price || 0) - (selectedHomeDetails.down_payment || 0);
    const homeValue = selectedHomeDetails.home_price || 0;
    const ficoScore = affordability.credit_status || 0;
    const loanTerm = selectedHomeDetails.term_months > 180 ? "30-year" : "15-year";
  
    if (loanAmount > 0 && homeValue > 0 && ficoScore > 0) {
      const calculatedRate = calculateInterestRate({
        loanTerm,
        ficoScore,
        loanAmount,
        homeValue,
        marketRiskPremium: 0, // Adjust this if necessary
        loanTermAdjustment: 0, // Adjust this if necessary
      });
  
      handleChange('interest_rate', calculatedRate.toFixed(2)); // Automatically update the interest rate field
    }
  }, [
    selectedHomeDetails.home_price,
    selectedHomeDetails.down_payment,
    selectedHomeDetails.fico_score,
    selectedHomeDetails.term_months,
  ]);
  
  // Handle form input changes
  const handleChange = (name, value) => {
    dispatch(updateSelectedHomeDetails({ ...selectedHomeDetails, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedClientId) {
      alert('Please select a client before adding or updating a home.');
      return;
    }

    const payload = {
      client_id: selectedClientId,
      address: selectedHomeDetails.address,
      type: 'N/A',
      home_price: parseFloat(selectedHomeDetails.home_price) || 0,
      down_payment: parseFloat(selectedHomeDetails.down_payment) || 0,
      interest_rate: parseFloat(selectedHomeDetails.interest_rate) || 0,
      property_tax_rate: parseFloat(selectedHomeDetails.property_tax_rate) || 0,
      home_insurance_fee: parseFloat(selectedHomeDetails.home_insurance_fee) || 0,
      pmi_rate: parseFloat(selectedHomeDetails.pmi_rate) || 0,
      hoa: parseFloat(selectedHomeDetails.hoa) || 0,
      term_months: parseInt(selectedHomeDetails.term_months, 10) || 0,
      payments_per_year: parseInt(selectedHomeDetails.payments_per_year, 10) || 12,
      appreciation_rate: parseFloat(selectedHomeDetails.appreciation_rate) || 3.5,
      // loan_type: selectedHomeDetails.loan_type || '',
      // interest_only_months: parseInt(selectedHomeDetails.interest_only_months, 10) || 0,
      // balloon_payment_months: parseInt(selectedHomeDetails.balloon_payment_months, 10) || 0,
    };

    try {
      let result;
      if (isEditing) {
        result = await updateHome(selectedHomeId, payload);
        if (result) {
          if (!selectedHomeId) {
            console.error('selectedHomeId is undefined');
            alert('No home is selected for editing.');
            return;
          }
          console.log('selectedHomeId is', selectedHomeId);
          dispatch(
            updateHomeRedux({
              ...payload,
              home_id: selectedHomeId, // Ensure the ID is preserved
            })
          );
          alert('Home updated successfully!');
        } else {
          alert('Failed to update home.');
        }
      } else {
        result = await addHome(payload);
        if (result) {
          dispatch(addHomeRedux(result));
          alert('Home added successfully!');
          fetchHomes(selectedClientId, (fetchedHomes) => {
            dispatch(setHomes(fetchedHomes)); // Update Redux store with new list
        
            console.log("Re-fetched homes after addition:", fetchedHomes);
        
            // Find the newly added home in the re-fetched homes
            const newlyAddedHome = fetchedHomes.find(
              (home) => home.home_id === result.home_id
            );
        
            if (newlyAddedHome) {
              // Dispatch updateSelectedHome AFTER setHomes to ensure proper state
              dispatch(updateSelectedHome(newlyAddedHome.home_id));
            }
          });
        } else {
          alert('Failed to add home.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An unexpected error occurred. Please try again.');
    }
  };

  const handleDeleteHome = async () => {
    if (selectedHomeId === -1) return; // Do nothing if no home is selected
  
    const confirmation = window.confirm(
      "Are you sure you want to delete this home? This action cannot be undone."
    );
  
    if (!confirmation) return;
  
    try {
      const result = await deleteHome(selectedHomeId);
  
      if (result.success) {
        alert("Home deleted successfully!");
  
        // Refresh homes from the server and update Redux store
        fetchHomes(selectedClientId, (fetchedHomes) => {
          dispatch(setHomes(fetchedHomes));
  
          // Clear the selected home
          dispatch(updateSelectedHome(-1));
        });
      } else {
        alert(`Failed to delete home: ${result.message}`);
      }
    } catch (error) {
      console.error("Error deleting home:", error);
      alert("An unexpected error occurred while deleting the home.");
    }
  };  

  return (
  <div className="add-home-page">
    <div className="form-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
    <h1 className="form-title">{isEditing ? 'Update Home' : 'Add a New Home'}</h1>
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <HomeDropdown />
        <button
          onClick={() => {
            dispatch(updateSelectedHome(-1)); // Trigger "add" mode
          }}
          type="submit" className="btn update"
        >
          + Add New
        </button>
      </div>
    </div>
    <form onSubmit={handleSubmit} >
      <div className="form-body packet-container">
      <div className="row">
        {/* Left Column */}
        <div className="flex-column">
          {/* Address */}
          <div className="input-row address">
            <label className="input-label">Address:</label>
            <div className="input-container">
              <input
                className="input-field"
                type="text"
                value={selectedHomeDetails.address || ''}
                onChange={(e) => handleChange('address', e.target.value)}
              />
            </div>
          </div>

          {/* Home Price */}
          <div className="input-row">
            <label className="input-label">Home Price: ($)</label>
            <div className="input-container">
              {/* <span className="symbol-spacer">$</span> */}
              <FormattedNumberInput
                className="input-field"
                value={selectedHomeDetails.home_price || ''}
                onChange={(value) => handleChange('home_price', value)}
              />
            </div>
          </div>

{/* Down Payment */}
<div className="input-row">
  <label className="input-label">Down Payment:</label>

  <div className="input-container" style={{ display: 'flex', alignItems: 'center', gap: '12px', flex: '1' }}>
    {/* Toggle inline */}
    <div style={{ display: 'flex', gap: '10px' }}>
      <label style={{ display: 'flex', gap: '4px' }}>
        <input
          type="radio"
          name="downPaymentFormat"
          value="dollar"
          checked={!isDownPaymentPercentage}
          onChange={() => setIsDownPaymentPercentage(false)}
        />
        ($)
      </label>
      <label style={{ display: 'flex', gap: '4px' }}>
        <input
          type="radio"
          name="downPaymentFormat"
          value="percentage"
          checked={isDownPaymentPercentage}
          onChange={() => setIsDownPaymentPercentage(true)}
        />
        (%)
      </label>
    </div>

    {/* Input */}
    <div style={{ display: 'flex', width: '100%', gap: '8px' }}>
      <FormattedNumberInput
        className="input-field"
        value={
          isDownPaymentPercentage
            ? ((selectedHomeDetails.down_payment || 0) / (selectedHomeDetails.home_price || 1)) * 100
            : selectedHomeDetails.down_payment || ''
        }
        onChange={(value) => handleDownPaymentChange(value)}
        placeholder={isDownPaymentPercentage ? 'Enter %' : 'Enter amount'}
      />
    </div>
  </div>
</div>



          {/* Property Tax Rate */}
          <div className="input-row">
            <label className="input-label">Property Tax Rate: (%)</label>
            <div className="input-container">
              <FormattedNumberInput
                className="input-field"
                value={selectedHomeDetails.property_tax_rate || ''}
                onChange={(value) => handleChange('property_tax_rate', value)}
              />
              {/* <span className="symbol-spacer">%</span> */}
            </div>
          </div>

          {/* Home Insurance Fee */}
          <div className="input-row">
            <label className="input-label">Home Insurance Fee: ($)</label>
            <div className="input-container">
              {/* <span className="symbol-spacer">$</span> */}
              <FormattedNumberInput
                className="input-field"
                value={selectedHomeDetails.home_insurance_fee || ''}
                onChange={(value) => handleChange('home_insurance_fee', value)}
              />
            </div>
          </div>

         {/* PMI Rate */}
          <div className="input-row">
            <label className="input-label">PMI Rate: (%)</label>
            <div className="input-container">
              <FormattedNumberInput
                className="input-field"
                value={selectedHomeDetails.pmi_rate || ''}
                onChange={(value) => handleChange('pmi_rate', value)}
              />
              {/* <span className="symbol-spacer">%</span> */}
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="flex-column">


          {/* HOA */}
          <div className="input-row">
            <label className="input-label">Monthly HOA Fee: ($)</label>
            <div className="input-container">
              {/* <span className="symbol-spacer">$</span> */}
              <FormattedNumberInput
                className="input-field"
                value={selectedHomeDetails.hoa || ''}
                onChange={(value) => handleChange('hoa', value)}
              />
            </div>
          </div>

          {/* Loan Term */}
          <div className="input-row">
            <label className="input-label">Loan Term (Months):</label>
            <div className="input-container">
              <FormattedNumberInput
                className="input-field"
                value={selectedHomeDetails.term_months || ''}
                onChange={(value) => handleChange('term_months', value)}
              />
            </div>
          </div>

          {/* Payments Per Year */}
          <div className="input-row">
            <label className="input-label">Payments Per Year:</label>
            <div className="input-container">
              <FormattedNumberInput
                className="input-field"
                value={selectedHomeDetails.payments_per_year || ''}
                onChange={(value) => handleChange('payments_per_year', value)}
              />
            </div>
          </div>

          {/* Interest Rate */}
          <div className="input-row">
            <label className="input-label">Estimated Loan Interest Rate: (%)</label>
            <div className="input-container">
              <FormattedNumberInput
                className="input-field"
                value={selectedHomeDetails.interest_rate || ''}
                onChange={(value) => handleChange('interest_rate', value)}
              />
              {/* <span className="symbol-spacer">%</span> */}
            </div>
          </div>

          {/* Appreciation Rate */}
          <div className="input-row">
            <label className="input-label">Appreciation Rate: (%)</label>
            <div className="input-container">
              <FormattedNumberInput
                className="input-field"
                value={
                  selectedHomeDetails.appreciation_rate !== undefined
                    ? selectedHomeDetails.appreciation_rate
                    : 3.5
                }
                onChange={(value) => handleChange('appreciation_rate', value)}
              />
              {/* <span className="symbol-spacer">%</span> */}
            </div>
          </div>
        </div>
      </div></div>

      {/* Buttons */}
      <div className="button-row">
      <button type="submit" className="btn update"
          onClick={handleSubmit}>
          {isEditing ? 'Update Home' : 'Add Home'}
        </button>
        <button
          type="button"
          className="btn delete"
          disabled={selectedHomeId === -1}
          onClick={handleDeleteHome}
        >
          Delete Home
        </button>
      </div></form>
      

      </div>

  );
};

export default AddHomePage;
