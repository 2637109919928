import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { calculateAmortization } from "../../../calculations/presentModeCalculations/amortizationSchedule";

const OwnBreakdownPieChart = ({ selectedHome, selectedMonth, ownInputs, generalInputs, affordability }) => {
  // === Ensure all values are properly converted to numbers ===
  const parseNumber = (value) => (value ? parseFloat(value) : 0);
  console.log("OWN IN", ownInputs);

  // === Helper function to apply annual inflation to monthly values over time ===
  const applyInflation = (initialValue, inflationRate, months) => {
    const years = months / 12; 
    return initialValue * Math.pow(1 + inflationRate, years);
  };

  // === 1) Annual Income & Salary Growth ===
  const annualIncome =
    parseNumber(affordability?.current_annual_gross_income) ||
    parseNumber(generalInputs?.salary) ||
    100000;

  const expectedSalaryIncrease = parseNumber(generalInputs?.expectedSalaryIncrease) / 100 || 0;
  // Apply salary growth over the selected month
  const adjustedAnnualIncome = annualIncome * Math.pow(1 + expectedSalaryIncrease, (selectedMonth || 1) / 12);
  // Monthly base income after salary increase
  const totalIncome = adjustedAnnualIncome / 12;

  // === 2) Investment Growth (optional additional monthly income) ===
  const investmentGrowthRate = parseNumber(generalInputs?.investmentGrowth) / 100 || 0;
  const baseInvestmentIncome = parseNumber(ownInputs?.investmentIncome) || 0;
  const adjustedInvestmentIncome = baseInvestmentIncome * Math.pow(1 + investmentGrowthRate, (selectedMonth || 1) / 12);

  // === Combine base salary + investment for total monthly inflow ===
  const totalMonthlyIncome = totalIncome + adjustedInvestmentIncome;

  // === 3) Inflation Rate for Expenses ===
  const inflationRate = parseNumber(generalInputs?.inflationRate) / 100 || 0.03; // Default to 3%

  // === Monthly Tax Calculation ===
  const standardDeduction = parseNumber(affordability?.standard_deduction) / 12; // Convert annual to monthly
  const taxableIncome = Math.max(totalMonthlyIncome - standardDeduction - 2000, 0);  //TODO  HARDCODED VALUE FOR TAX WRITEOFFS
  const taxBracket = parseNumber(affordability?.tax_bracket) / 100; // Convert % to decimal
  const monthlyIncomeTax = taxableIncome * taxBracket; // Monthly tax

  // === Home Expenses ===
  const homePrice = parseNumber(ownInputs?.homePrice);
  const loanTerm = parseNumber(ownInputs?.loanTerm);
  const mortgageRate = parseNumber(ownInputs?.mortgageRate);
  const propertyTaxRate = parseNumber(ownInputs?.propertyTaxRate) / 100;
  const hoaFees = parseNumber(ownInputs?.hoaFee);
  const utilities = parseNumber(ownInputs?.homeUtilities);
  const homeOther = parseNumber(ownInputs?.homeOther);
  const pmiRate = parseNumber(selectedHome?.pmi_rate) / 100;
  const downPayment = parseNumber(ownInputs?.downPayment);
  const loanAmount = homePrice - downPayment; // Loan principal

  // === Get Loan Breakdown for Selected Month ===
  const { principalPaid, interestPaid, remainingBalance, monthlyPayment } = 
    calculateAmortization(selectedMonth || 1, loanAmount, mortgageRate, loanTerm);

  console.log(
    `Month ${selectedMonth}: Payment - ${monthlyPayment.toFixed(2)}, Principal - ${principalPaid.toFixed(
      2
    )}, Interest - ${interestPaid.toFixed(2)}, Remaining Balance - ${remainingBalance.toFixed(2)}`
  );

  // === Monthly Property Tax Calculation (before inflation) ===
  const basePropertyTax = (propertyTaxRate * homePrice) / 12;

  // === PMI Calculation (Only if down payment < 20%) ===
  const pmi = downPayment / homePrice < 0.2 ? (loanAmount * pmiRate) / 12 : 0;

  // === Apply inflation to the main monthly expenses ===
  const adjustedPropertyTax = applyInflation(basePropertyTax, inflationRate, selectedMonth || 1);
  const adjustedUtilities = applyInflation(utilities, inflationRate, selectedMonth || 1);
  const adjustedHoaFees = applyInflation(hoaFees, inflationRate, selectedMonth || 1);
  const adjustedHomeOther = applyInflation(homeOther, inflationRate, selectedMonth || 1);

  // === Total Expenses Calculation (now inflation-adjusted) ===
  const totalExpenses =
    monthlyIncomeTax +
    adjustedPropertyTax +
    principalPaid +
    interestPaid +
    adjustedUtilities +
    adjustedHoaFees +
    pmi +
    adjustedHomeOther;

  // === Remaining Savings after all expenses ===
  const remainingSavings = totalMonthlyIncome - totalExpenses;
  const finalSavings = remainingSavings < 0 ? 0 : remainingSavings;

  // === Pie Chart Data (Total Monthly Income is 100%) ===
  const data = [
    { id: "Income Tax", value: monthlyIncomeTax, color: "#dc7373" }, // Soft red
    { id: "Property Tax", value: adjustedPropertyTax, color: "#e18181" }, // Soft red
    { id: "Loan Principal", value: principalPaid, color: "#81c784" }, // Soft green (good)
    { id: "Loan Interest", value: interestPaid, color: "#e69090" }, // Soft red
    { id: "Utilities", value: adjustedUtilities, color: "#f0acac" }, // Soft red
    { id: "HOA Fees", value: adjustedHoaFees, color: "#f4bbbb" }, // Soft red
    { id: "PMI", value: pmi, color: "#f9c9c9" }, // Soft red
    { id: "Other Expenses", value: adjustedHomeOther, color: "#fdd7d7" }, // Soft red
    { id: "Remaining Savings", value: finalSavings, color: "#a5d6a7" }, // Soft green (good)
  ];
  

  console.log("CHART DATA", data);

  return (
    <div style={{ height: 400 }}>
      <h3>Monthly Income Breakdown</h3>
      <p>
        This chart represents your <strong>total monthly income</strong> (including salary and optional
        investment income) and how it's allocated across different expenses.
      </p>

      <ResponsivePie
  data={data}
  margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
  innerRadius={0.6} // Larger hole for readability
  padAngle={3} // More spacing between slices
  cornerRadius={8} // Smooth rounded edges
  colors={{ datum: "data.color" }}
  borderWidth={2}
  borderColor={{ from: "color", modifiers: [["darker", 0.3]] }}
  enableRadialLabels={true}
  radialLabelsSkipAngle={10}
  radialLabelsTextXOffset={6}
  radialLabelsTextColor="#333333"
  radialLabelsLinkColor={{ from: "color" }}
  radialLabelsLinkStrokeWidth={2}
  sliceLabelsSkipAngle={10}
  sliceLabelsTextColor="#ffffff"
  sliceLabel={(d) =>
    `${((d.value / data.reduce((acc, item) => acc + item.value, 0)) * 100).toFixed(1)}%`
  } // Show actual percentage of the total
  tooltip={({ datum }) => (
    <div
      style={{
        background: "#ffffff",
        padding: "8px",
        borderRadius: "5px",
        border: `1px solid ${datum.color}`,
        boxShadow: "0px 0px 6px rgba(0,0,0,0.1)",
      }}
    >
      <strong>{datum.id}:</strong> ${datum.value.toFixed(2)}
    </div>
  )}
  legends={[
    {
      anchor: "bottom",
      direction: "row",
      justify: false,
      translateY: 60,
      itemsSpacing: 20,
      itemWidth: 120,
      itemHeight: 20,
      itemTextColor: "#333",
      symbolSize: 18,
      symbolShape: "circle",
    },
  ]}
/>;



    </div>
  );
};

export default OwnBreakdownPieChart;
