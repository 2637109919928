import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { useSelector, useDispatch } from 'react-redux';
import { updateDownPayment } from '../../../../../../state/presentation/adjustInputsSlice';

const DownPaymentInput = () => {
  const dispatch = useDispatch();

  // Fetch values from Redux (adjustInputsSlice)
  const downPayment = useSelector((state) => state.adjustInputs.ownInputs.downPayment);
  const homePrice = useSelector((state) => state.adjustInputs.ownInputs.homePrice);

  // Local state for smooth UI updates
  const [localValue, setLocalValue] = useState(downPayment ?? 0);
  const percentage = homePrice > 0 ? ((localValue / homePrice) * 100).toFixed(1) : 0;

  // Sync local state with Redux value on mount
  useEffect(() => {
    if (downPayment !== null && downPayment !== undefined) {
      setLocalValue(downPayment);
    }
  }, []); // ✅ Prevents resetting when other sliders update

  const handleChange = (event, newValue) => {
    setLocalValue(newValue); // ✅ Update UI immediately
    dispatch(updateDownPayment(newValue)); // ✅ Persist in Redux
  };

  return (
    <div>
      <label>
        Down Payment: ${localValue.toLocaleString()} ({percentage}%)
      </label>
      <Slider
        value={localValue}
        min={0}
        max={homePrice || 1000000} // Default max to $1M if home price is unavailable
        step={1000} // Smoother increments
        onChange={(event, newValue) => handleChange(event, newValue)}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `$${value.toLocaleString()}`} // Display formatted values
      />
    </div>
  );
};

export default DownPaymentInput;
