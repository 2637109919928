export const fetchClientHomes = async (client_id) => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.log('No token found, please log in.');
    return [];
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_homes?client_id=${client_id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      console.error(`Failed to fetch homes: ${response.status}`);
      return [];
    }

    const result = await response.json();
    console.log('Fetched Homes:', result);
    return result;
  } catch (error) {
    console.error('An error occurred while fetching homes:', error.message);
    return [];
  }
};
