import { reset as resetUser } from '../../../state/user/userSlice';
import { reset as resetUserContact } from '../../../state/user/userContactSlice';
import { reset as resetHome } from '../../../state/home/homeSlice';
import { reset as resetClient } from '../../../state/client/clientSlice';
import { reset as resetClientInfo } from '../../../state/client/clientInfoSlice';
import { reset as resetClientGoals } from '../../../state/client/clientGoalsSlice';
import { reset as resetClientExpenditure } from '../../../state/client/clientExpenditureSlice';
import { reset as resetClientContact } from '../../../state/client/clientContactSlice';
import { reset as resetClientAssumptions } from '../../../state/client/clientAssumptionsSlice';
import { reset as resetClientAffordability } from '../../../state/client/clientAffordabilitySlice';
import { reset as resetCompareHomes } from '../../../state/presentation/presentationCompareSlice'

export const handleSignOut = async (dispatch, navigate) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/logout`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    // ✅ Remove token from local storage
    localStorage.removeItem('token');

    // ✅ Reset Redux state to clear user data
    dispatch(resetUser());
    dispatch(resetUserContact());
    dispatch(resetHome());
    dispatch(resetClient());
    dispatch(resetClientInfo());
    dispatch(resetClientGoals());
    dispatch(resetClientExpenditure());
    dispatch(resetClientContact());
    dispatch(resetClientAssumptions());
    dispatch(resetClientAffordability());
    dispatch(resetCompareHomes());

    // ✅ Clear OAuth session without logging out of Google account
    await fetch(`${process.env.REACT_APP_SERVER_URL}/api/sheets/logout-google`, {
      method: 'POST',
      credentials: 'include',
    });

    // ✅ Redirect to login page
    navigate('/');

  } catch (error) {
    console.error('Error during logout:', error);
  }
};
