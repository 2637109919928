import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { fetchClientHomes } from '../fetches/fetchClientHomes';
import {
  setHomes,
  updateSelectedHome,
  updateSelectedHomeDetails,
} from '../../../../../../../../state/home/homeSlice';

const HomeDropdown = ({ setIsNewHome }) => {
  const dispatch = useDispatch();
  const selectedClientId = useSelector((state) => state.client.selected_client_id);
  const homes = useSelector((state) =>
    Object.values(state.home.homes).filter((home) => home.client_id === selectedClientId)
  );
  const selectedHomeId = useSelector((state) => state.home.selected_home_id);

  useEffect(() => {
    const loadHomes = async () => {
      if (!selectedClientId && !selectedHomeId) return;
      const fetchedHomes = await fetchClientHomes(selectedClientId);
      dispatch(setHomes(fetchedHomes));

      // ✅ Auto-select first home if available
      if (fetchedHomes.length > 0) {
        const firstHome = fetchedHomes[0];
        console.log("🏡 Auto-selecting first home:", firstHome);
        dispatch(updateSelectedHome(firstHome.home_id));
        dispatch(updateSelectedHomeDetails(firstHome));
        setIsNewHome(false);
      } else {
        console.log("⚠️ No homes found for client:", selectedClientId);
      }
    };

    loadHomes();
  }, [selectedClientId, dispatch, setIsNewHome]);

  const handleSelectHome = (homeId) => {
    if (homeId === null) {
      console.log("❌ Clearing Home Selection");
      setIsNewHome(true);  // ✅ Mark as new home
      dispatch(updateSelectedHome(-1));
      dispatch(updateSelectedHomeDetails({
        user_id: -1,
        client_id: selectedClientId,  // ✅ Keep the client ID
        address: '',
        home_price: 0,
        down_payment: 0,
        interest_rate: 0,
        property_tax_rate: 0,
        home_insurance_fee: 0,
        pmi_rate: 0,
        hoa_fee: 0,
        term_months: 360,
        loan_type: '',
        interest_only_months: 0,
        balloon_payment_months: 0,
      }));
    } else {
      console.log("🏠 Selecting Existing Home:", homeId);
      setIsNewHome(false);
      const selectedHome = homes.find((home) => home.home_id === homeId);
      if (selectedHome) {
        dispatch(updateSelectedHome(homeId));
        dispatch(updateSelectedHomeDetails(selectedHome));
      }
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="home-dropdown">Select Home:</label>
      <Dropdown
        id="home-dropdown"
        value={selectedHomeId || null}
        options={homes.map((home) => ({
          label: home.address || 'Unknown Address',
          value: home.home_id,
        }))}
        onChange={(e) => handleSelectHome(e.value)}
        placeholder="Select a home"
        filter
        showClear // ✅ Enables the "X" button to clear selection
      />
    </div>
  );
};

export default HomeDropdown;
