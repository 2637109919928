export const updateHomeFetch = async (selected_home_id, selectedHomeDetails) => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token found, please log in.');
    return null;
  }

  const payload = {
    home_price: selectedHomeDetails.home_price,
    down_payment: selectedHomeDetails.down_payment,
    interest_rate: selectedHomeDetails.interest_rate,
    property_tax_rate: selectedHomeDetails.property_tax_rate,
    home_insurance_fee: selectedHomeDetails.home_insurance_fee,
    pmi_rate: selectedHomeDetails.pmi_rate,
    hoa: selectedHomeDetails.hoa, 
    term_months: selectedHomeDetails.term_months,
    loan_type: selectedHomeDetails.loan_type,
    interest_only_months: selectedHomeDetails.interest_only_months,
    balloon_payment_months: selectedHomeDetails.balloon_payment_months,
    address: selectedHomeDetails.address,
    type: selectedHomeDetails.type,
    utilities: selectedHomeDetails.utilities,
    other: selectedHomeDetails.other
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_home/${selected_home_id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      console.error(`HTTP Error: ${response.status} ${response.statusText}`);
      return null;
    }

    const result = await response.json();
    return result.status;
  } catch (error) {
    console.error(`An error occurred: ${error.message}`);
    return null;
  }
};
