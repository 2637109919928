import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: "100%",
  },
});

export const FlexContainer = ({ children }) => {
  return <View style={styles.flexContainer}>{children}</View>;
};

export default FlexContainer;
