import React from "react";
import { useSelector } from "react-redux";
import TimeScroller from "./components/TimeScroller";
import OwnBreakdownPieChart from "./components/OwnBreakdownPieChart";
import RentBreakdownMain from "./RentBreakdown/RentBreakdownMain";
import './RentOwnBreakdown.css';

const RentVsOwnBreakdown = ({ home, ownInputs, rentInputs, generalInputs, affordabilityInputs }) => {
  const selectedMonth = useSelector((state) => state.adjustInputs.selectedMonth);

  return (
    <div className="rent-vs-own-breakdown">
      <h3 className="title">Rent vs Own Breakdown</h3>

      {/* Time Scroller for adjusting month */}
      <TimeScroller />

      <div className="breakdown-container">
        {/* Left: Rent Breakdown */}
        <div className="chart-container">
          <h4 className="chart-title">Rent Breakdown (Month {selectedMonth})</h4>
          <div className="chart-wrapper">
            <RentBreakdownMain selectedMonth={selectedMonth} rentInputs={rentInputs} />
          </div>
        </div>

        {/* Right: Own Breakdown */}
        <div className="chart-container">
          <h4 className="chart-title">Own Breakdown (Month {selectedMonth})</h4>
          <div className="chart-wrapper">
            <OwnBreakdownPieChart selectedHome={home} selectedMonth={selectedMonth} ownInputs={ownInputs} generalInputs={generalInputs} affordability={affordabilityInputs} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentVsOwnBreakdown;
