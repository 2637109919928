import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateFilingStatus } from "../../../../../../../../state/client/clientAffordabilitySlice";

const FilingStatusInput = () => {
  const dispatch = useDispatch();
  const filingStatus = useSelector(
    (state) => state.clientAffordability.selected_affordability_details.filing_status || ""
  );

  const handleChange = (e) => {
    const value = e.target.value;
    dispatch(updateFilingStatus(value));
  };

  return (
    <div className="form-group">
      <label htmlFor="filing-status">Filing Status:</label>
      <select id="filing-status" value={filingStatus} onChange={handleChange}>
        <option value="" disabled>
          Select filing status
        </option>
        <option value="single">Single</option>
        <option value="married_filing_jointly">Married Filing Jointly</option>
        <option value="married_filing_separately">Married Filing Separately</option>
        <option value="head_of_household">Head Of Household</option>
      </select>
    </div>
  );
};

export default FilingStatusInput;
