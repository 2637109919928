// import React from "react";
// import { HorizontalMiddle } from "./HorizontalMiddle";
// import "./MortgageCoachInfo.css";

// export const MortgageCoachInfo = () => {
//   return (
//     <div className="mortgage-coach-info">
//       <p className="mortgage-coach">Mortgage Coach Contact Information</p>

//       <div className="overlap-group">
//         <HorizontalMiddle
//           className="horizontal-middle-inset"
//           divider="image.svg"
//           dividerClassName="horizontal-middle-instance"
//         />
//         <div className="flexcontainer">
//           <p className="text">
//             <span className="text-wrapper">Nick Hogue</span>
//             <br />
//           </p>
//           <p className="text">
//             <span className="text-wrapper">nick.hogue@gmail.com</span>
//             <br />
//           </p>
//           <p className="text">
//             <span className="text-wrapper">Phone Number: N/A</span>
//             <br />
//           </p>
//           <p className="text">
//             <span className="text-wrapper">Company Name: Pathfinder</span>
//             <br />
//           </p>
//           <p className="text">
//             <span className="text-wrapper">Cell Phone: N/A</span>
//             <br />
//           </p>
//           <p className="text">
//             <span className="text-wrapper">Fax: N/A</span>
//             <br />
//           </p>
//           <p className="text">
//             <span className="text-wrapper">
//               Business Address: (Street Address), (City), (State), (Zip Code)
//             </span>
//             <br />
//           </p>
//           <p className="text">
//             <span className="text-wrapper">Website: N/A</span>
//             <br />
//           </p>
//           <p className="text">
//             <span className="text-wrapper">Start Now URL: N/A</span>
//             <br />
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  mortgageCoachInfo: {
    width: 257,
    height: 214,
    position: "relative",
    overflow: "hidden",
    padding: 10,
    top: 20,
  },
  header: {
    width: 260,
    height: 17,
    position: "absolute",
    top: 0,
    left: 3,
    color: "#243144",
    fontSize: 12,
    fontWeight: "bold",
  },
  separator: {
    width: "100%",
    height: 0,
    position: "absolute",
    top: 17,
    left: 3,
    backgroundColor: "#D37F51",
    borderBottomWidth: 1,
    borderBottomColor: "#D37F51",
  },
  content: {
    width: 260,
    height: 214,
    position: "absolute",
    top: 18,
    left: 3,
    color: "black",
    fontSize: 10,
    fontWeight: "normal",
  },
});

export const MortgageCoachInfo = () => {
  return (
    <View style={styles.mortgageCoachInfo}>
      <Text style={styles.header}>Mortgage Coach Contact Information</Text>
      <View style={styles.separator} />
      <Text style={styles.content}>
        Nick Hogue{"\n"}
        nick.hogue@gmail.com{"\n"}
        Phone Number: N/A{"\n"}
        Company Name: Pathfinder{"\n"}
        Cell Phone: N/A{"\n"}
        Fax: N/A{"\n"}
        Business Address: (Street Address), (City), (State), (Zip Code){"\n"}
        Website: N/A{"\n"}
        Start Now URL: N/A
      </Text>
    </View>
  );
};

export default MortgageCoachInfo;
