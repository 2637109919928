import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  box: {
    height: 142,
    width: 584,
    position: "relative",
  },
  mortgageCalcs: {
    backgroundColor: "#d9d9d9",
    borderRadius: 5,
    height: 142,
    width: 584,
  },
});

export const Box = () => {
  return (
    <View style={styles.box}>
      <View style={styles.mortgageCalcs} />
    </View>
  );
};

export default Box;