// import React from "react";
// // import divider from "./divider.svg";
// import "./ClientContact.css";

// export const ClientContact = () => {
//   return (
//     <div className="client-contact">
//       <div className="text-wrapper">Client Contact Information</div>

//       <div className="flexcontainer">
//         <p className="text">
//           <span className="span">trevor.thayer32@gmail.com</span>
//         </p>
//         <p className="text">
//           <span className="span">Home Phone: N/A</span>
//         </p>
//         <p className="text">
//           <span className="span">Cell Phone: 510-697-3377</span>
//         </p>
//         <p className="text">
//           <span className="span">Fax: N/A</span>
//         </p>
//         <p className="text">
//           <span className="span">Mailing Address: 129 Requa Rd, Oakland, CA 94611</span>
//         </p>
//         <p className="text">
//           <span className="span">Referred By: Riley</span>
//         </p>
//         <p className="text">
//           <span className="span">Partner Email: riley@gmail.com</span>
//         </p>
//         <p className="text">
//           <span className="span">Co-borrower First Name: N/A</span>
//         </p>
//         <p className="text">
//           <span className="span">Co-borrower Last Name: N/A</span>
//         </p>
//         <p className="text">
//           <span className="span">Co-borrower DOB: N/A</span>
//         </p>
//         <p className="text">
//           <span className="span">Current Address: 5667 Dorothy Way, San Diego, CA 92115</span>
//         </p>
//         <p className="text">
//           <span className="span">Current Estimated Home Value: $0.00</span>
//         </p>
//         <p className="text">
//           <span className="span">Is First Time Buyer: Yes</span>
//         </p>
//         <p className="text">
//           <span className="span">Partner Phone: 5106973377</span>
//         </p>
//       </div>
//       {/* <img className="divider" alt="Divider" src={divider} /> */}
//     </div>
//   );
// };
import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  clientContact: {
    width: 334,
    height: 214,
    position: "relative",
    overflow: "hidden",
    padding: 30,
    top: 20,
  },
  header: {
    width: 257,
    height: 17,
    position: "absolute",
    top: 0,
    left: 3,
    color: "#243144",
    fontSize: 12,
    fontWeight: "bold",
  },
  separator: {
    width: 225,
    height: 0,
    position: "absolute",
    top: 17,
    left: 3,
    backgroundColor: "#D37F51",
    borderBottomWidth: 1,
    borderBottomColor: "#D37F51",
  },
  content: {
    width: 280,
    height: 214,
    position: "absolute",
    top: 18,
    left: 3,
    color: "black",
    fontSize: 10,
    fontWeight: "normal",
  },
});

export const ClientContact = () => {
  return (
    <View style={styles.clientContact}>
      <Text style={styles.header}>Client Contact Information</Text>
      <View style={styles.separator} />
      <Text style={styles.content}>
        trevor thayer{"\n"}
        trevor.thayer32@gmail.com{"\n"}
        Home Phone: N/A{"\n"}
        Cell Phone: 510-697-3377{"\n"}
        Fax: N/A{"\n"}
        Mailing Address: 129 Requa Rd, Oakland, CA 94611{"\n"}
        Referred By: Riley{"\n"}
        Partner Email: riley@gmail.com{"\n"}
        Co-borrower First Name: N/A{"\n"}
        Co-borrower Last Name: N/A{"\n"}
        Co-borrower DOB: N/A{"\n"}
        Current Address: 5667 Dorothy Way, San Diego, CA 92115{"\n"}
        Current Estimated Home Value: $0.00{"\n"}
        Is First Time Buyer: Yes{"\n"}
        Partner Phone: 5106973377
      </Text>
    </View>
  );
};

export default ClientContact;