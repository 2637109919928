import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updatePropertyTaxRate } from '../../../../../../../../state/home/homeSlice';
import FormattedNumberInput from '../../../../utils/FormattedNumberInput';

const PropertyTaxRateInput = () => {
  const dispatch = useDispatch();
  const reduxValue = useSelector(
    (state) => state.home.selected_home_details.property_tax_rate
  );

  // 🔥 Local state to allow smooth user input
  const [inputValue, setInputValue] = useState(reduxValue?.toString() || "");

  useEffect(() => {
    // Only update local state if Redux value changes (avoids mid-typing resets)
    if (reduxValue?.toString() !== inputValue) {
      setInputValue(reduxValue?.toString() || "");
    }
  }, [reduxValue]);

  const handleChange = (value) => {
    // ✅ Allow blank input or just a decimal for smooth editing
    if (value === "" || value === ".") {
      setInputValue(value);
      dispatch(updatePropertyTaxRate(null)); // ✅ Set NULL instead of 0
      return;
    }

    // ✅ Ensure valid number input
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setInputValue(value); // Update UI immediately
      dispatch(updatePropertyTaxRate(parsedValue)); // Update Redux
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="property-tax-rate">Property Tax Rate (%):</label>
      <FormattedNumberInput
        id="property-tax-rate"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter property tax rate"
        allowDecimals={true}
      />
    </div>
  );
};

export default PropertyTaxRateInput;
