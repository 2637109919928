import React, { useEffect } from 'react';
import { useMortgageCalculations } from './calculations/mortgageCalculations';
import { calculateTotalPITI } from './calculations/taxCalcs';

const MortgageCalculator = ({
  onMortgageDataChange
}) => {
  const {
    loanAmount,
    interestRate,
    loanTermYears,
    paymentsPerYear,
    totalPayments,
    paymentPerPeriod,
    propertyTaxInDollars,
    homeInsuranceFee,
    pmiInDollars,
    hoa
  } = useMortgageCalculations();

  // Ensure safe number conversions for UI rendering
  const formattedLoanAmount = loanAmount.toLocaleString();
  const formattedInterestRate = interestRate.toFixed(2);
  const formattedPaymentPerPeriod = parseFloat(paymentPerPeriod).toLocaleString();
  const formattedPropertyTaxes = parseFloat(propertyTaxInDollars).toLocaleString();
  const formattedHomeInsurance = parseFloat(homeInsuranceFee).toLocaleString();
  const formattedPMI = parseFloat(pmiInDollars).toLocaleString();
  const formattedHOA = parseFloat(hoa).toLocaleString();
  
  // Calculate Total PITI
  const calculatedTotalPITI = calculateTotalPITI(paymentPerPeriod, propertyTaxInDollars, homeInsuranceFee, pmiInDollars, hoa);
  const formattedTotalPITI = parseFloat(calculatedTotalPITI).toLocaleString();

  useEffect(() => {
    if (onMortgageDataChange) {
      onMortgageDataChange({
        totalPITI: calculatedTotalPITI,
        loanAmount,
        interestRate,
        totalPayments,
        paymentPerPeriod,
        propertyTax: propertyTaxInDollars,
        homeInsurance: homeInsuranceFee,
        pmi: pmiInDollars,
        hoa
      });
    }
  }, [
    calculatedTotalPITI, 
    loanAmount, 
    interestRate, 
    totalPayments, 
    paymentPerPeriod, 
    propertyTaxInDollars, 
    homeInsuranceFee, 
    pmiInDollars, 
    hoa, 
    onMortgageDataChange
  ]);


  return (
    <div className="section mortgage-calculator">
      <h2>Mortgage Calculator</h2>
      <div>
        <p><strong>Loan Amount:</strong> ${formattedLoanAmount}</p>
        {/* <p><strong>Years:</strong> {loanTermYears.toFixed(1)} years</p>
        <p><strong>Payments per Year:</strong> {paymentsPerYear}</p> */}
        <p><strong>Total # Payments:</strong> {totalPayments}</p>
        <p><strong>Payment per period:</strong> ${formattedPaymentPerPeriod}</p>
        <p><strong>Property Taxes:</strong> ${formattedPropertyTaxes}</p>
        <p><strong>Insurance:</strong> ${formattedHomeInsurance}</p>
        <p><strong>PMI:</strong> ${formattedPMI}</p>
        <p><strong>HOA:</strong> ${formattedHOA}</p>
        <p><strong>Total PITI:</strong> ${formattedTotalPITI}</p>
      </div>
    </div>
  );
};

export default MortgageCalculator;
