import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import { useSelector, useDispatch } from "react-redux";
import { updateInflationRate } from "../../../../../../state/presentation/adjustInputsSlice";

const InflationRate = () => {
  const dispatch = useDispatch();

  // Get inflation rate from Redux (adjustInputsSlice)
  const inflationRate = useSelector(
    (state) => state.adjustInputs.generalInputs.inflationRate
  );

  // Local state for smooth UI updates
  const [localValue, setLocalValue] = useState(inflationRate ?? 3); // Default to 2%

  // Sync local state with Redux when Redux value changes
  useEffect(() => {
    if (inflationRate !== null && inflationRate !== undefined) {
      setLocalValue(inflationRate);
    }
  }, [inflationRate]); // ✅ Syncs when Redux state changes

  const handleChange = (event, newValue) => {
    setLocalValue(newValue); // ✅ Update UI immediately
    dispatch(updateInflationRate(newValue)); // ✅ Persist in Redux
  };

  return (
    <div>
      <label>Inflation Rate: {localValue}%</label>
      <Slider
        value={localValue}
        min={0}
        max={10}
        step={0.1}
        onChange={(event, newValue) => handleChange(event, newValue)}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${value}%`}
      />
    </div>
  );
};

export default InflationRate;
