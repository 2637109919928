import React from "react";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { calculateAmortization } from "../../calculations/presentModeCalculations/amortizationSchedule";

const CostsOverTimeChart = ({ ownInputs, rentInputs, generalInputs }) => {
  const years = ownInputs.loanTerm / 12 + 1 || 31; // Extend to 31 years

  const {
    homePrice,
    downPayment,
    loanTerm,
    mortgageRate,
    propertyTaxRate,
    hoaFee,
    homeUtilities,
    homeOther,
  } = ownInputs;

  const { monthlyRent, annualRentIncrease, monthlyRentInsurance, utilities, otherRentExpenses } = rentInputs;

  const { inflationRate } = generalInputs;

  const loanAmount = homePrice - downPayment;

  const data = Array.from({ length: years }, (_, i) => {
    const year = i + 1;
    const isMortgagePaidOff = year * 12 > loanTerm ; // Mortgage ends after `loanTerm` years

    // **Renting Costs Calculation (No Inflation Applied)**
    const yearlyRent = monthlyRent * 12 * Math.pow(1 + annualRentIncrease / 100, i);
    const yearlyRentInsurance = monthlyRentInsurance * 12 * Math.pow(1 + inflationRate / 100, i); // Inflation applied
    const yearlyRentUtilities = utilities * 12 * Math.pow(1 + inflationRate / 100, i); // Inflation applied
    const yearlyRentOther = otherRentExpenses * 12 * Math.pow(1 + inflationRate / 100, i); // Inflation applied
    const totalYearlyRent = yearlyRent + yearlyRentInsurance + yearlyRentUtilities + yearlyRentOther;

    // **Homeownership Costs Calculation**
    const amortization = isMortgagePaidOff
      ? { monthlyPayment: 0 } // No mortgage payments after loan term
      : calculateAmortization(year * 12, loanAmount, mortgageRate, loanTerm) || {};
    
    const yearlyMortgagePayment = isMortgagePaidOff ? 0 : amortization.monthlyPayment * 12; // Stops after loan term
    const yearlyPropertyTax = (propertyTaxRate / 100) * homePrice * Math.pow(1 + inflationRate / 100, i); // Inflation applied
    const yearlyHOA = hoaFee * 12 * Math.pow(1 + inflationRate / 100, i); // Inflation applied
    const yearlyHomeUtilities = homeUtilities * 12 * Math.pow(1 + inflationRate / 100, i); // Inflation applied
    const yearlyHomeOther = homeOther * 12 * Math.pow(1 + inflationRate / 100, i); // Inflation applied
    
    // Total homeownership costs
    const totalYearlyHomeCosts =
      yearlyMortgagePayment + yearlyPropertyTax + yearlyHOA + yearlyHomeUtilities + yearlyHomeOther;

    return {
      year,
      rentCost: totalYearlyRent, // No inflation on rent itself
      homeCost: totalYearlyHomeCosts, // Inflation applied to non-mortgage expenses
    };
  });

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" label={{ value: "Years", position: "insideBottom", offset: -5 }} />
        <YAxis label={{ value: "Cost ($)", angle: -90, position: "insideLeft" }} />
        <Tooltip />
        <Line type="monotone" dataKey="rentCost" stroke="#82ca9d" strokeWidth={2} name="Renting Costs" />
        <Line type="monotone" dataKey="homeCost" stroke="#8884d8" strokeWidth={2} name="Homeownership Costs" />
      </LineChart>
    </ResponsiveContainer>
  );
};

CostsOverTimeChart.propTypes = {
  ownInputs: PropTypes.shape({
    homePrice: PropTypes.number.isRequired,
    downPayment: PropTypes.number.isRequired,
    loanTerm: PropTypes.number.isRequired,
    mortgageRate: PropTypes.number.isRequired,
    propertyTaxRate: PropTypes.number.isRequired,
    hoaFee: PropTypes.number.isRequired,
    appreciationRate: PropTypes.number.isRequired,
    homeUtilities: PropTypes.number.isRequired,
    homeOther: PropTypes.number.isRequired,
  }).isRequired,
  rentInputs: PropTypes.shape({
    monthlyRent: PropTypes.number.isRequired,
    annualRentIncrease: PropTypes.number.isRequired,
    monthlyRentInsurance: PropTypes.number.isRequired,
    utilities: PropTypes.number.isRequired,
    otherRentExpenses: PropTypes.number.isRequired,
  }).isRequired,
  generalInputs: PropTypes.shape({
    inflationRate: PropTypes.number.isRequired,
  }).isRequired,
};

export default CostsOverTimeChart;
