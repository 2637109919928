import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateLoanTermMonths } from '../../../../../../../../state/home/homeSlice';
import FormattedNumberInput from '../../../../utils/FormattedNumberInput';

const LoanTermInput = () => {
  const dispatch = useDispatch();
  const reduxLoanTerm = useSelector(
    (state) => state.home.selected_home_details.term_months
  );

  // 🔥 Local state for smooth typing experience
  const [inputValue, setInputValue] = useState(reduxLoanTerm?.toString() || "");

  useEffect(() => {
    if (reduxLoanTerm?.toString() !== inputValue) {
      setInputValue(reduxLoanTerm?.toString() || "");
    }
  }, [reduxLoanTerm]);

  const handleChange = (value) => {
    // ✅ Allow blank input for smooth user experience
    if (value === "") {
      setInputValue(value);
      return;
    }

    // ✅ Ensure only valid whole numbers (loan terms in months)
    if (/^\d*$/.test(value)) {
      setInputValue(value);
      dispatch(updateLoanTermMonths(parseInt(value, 10) || 0)); // Store as integer in Redux
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="loan-term">Loan Term (Months):</label>
      <FormattedNumberInput
        id="loan-term"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter loan term in months"
      />
    </div>
  );
};

export default LoanTermInput;
