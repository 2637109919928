import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { useSelector, useDispatch } from 'react-redux';
import { updateAppreciationRate } from '../../../../../../state/presentation/adjustInputsSlice';

const LoanTermInput = () => {
  const dispatch = useDispatch();

  // Fetch loan term (years) from Redux (adjustInputsSlice)
  const appreciationRate = useSelector((state) => state.adjustInputs.ownInputs.appreciationRate);

  // Local state for immediate UI updates
  const [localValue, setLocalValue] = useState(appreciationRate ?? 3.5); // Default to 30 years

  // Sync local state with Redux when Redux value changes
  useEffect(() => {
    if (appreciationRate !== null && appreciationRate !== undefined) {
      setLocalValue(appreciationRate);
    }
  }, []); // ✅ Runs only on mount to prevent unnecessary resets

  const handleChange = (event, newValue) => {
    setLocalValue(newValue); // ✅ Update UI immediately
    dispatch(updateAppreciationRate(newValue)); // ✅ Persist in Redux (convert years to months)
  };

  return (
    <div>
      <label>Appreciation Rate: {localValue}%</label>
      <Slider
        value={localValue}
        min={0.1} // Minimum rate of 0.1%
        max={8.0} // Maximum rate of 8%
        step={0.05} // Increment by 0.05%
        onChange={(event, newValue) => handleChange(event, newValue)}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${value}%`} // Display percentage when hovering
      />
    </div>
  );
};

export default LoanTermInput;
