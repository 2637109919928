// import React from "react";
// import { HorizontalMiddle } from "./HorizontalMiddle";
// import "./RentVsPiti.css";

// export const RentVsPiti = () => {
//   return (
//     <div className="rent-vs-PITI">
//       <div className="overlap-group">
//         <div className="rectangle" />
//       </div>

//       <div className="flexcontainer">
//         <p className="text">
//           <span className="text-wrapper">
//             Total PITI: $6261.53 <br />
//           </span>
//         </p>

//         <p className="text">
//           <span className="text-wrapper">Current Rent: $3,500 <br /></span>
//         </p>

//         <p className="text">
//           <span className="text-wrapper">More Per Month: $2761.53</span>
//         </p>

//         <HorizontalMiddle
//           className="horizontal-middle-inset"
//           divider="image.svg"
//           dividerClassName="horizontal-middle-instance"
//         />
//         <div className="div">Rent vs. Total PITI</div>
//       </div>
//     </div>
//   );
// };

import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  rentVsPiti: {
    width: 189,
    height: 70,
    position: "relative",
    overflow: "hidden",
    borderRadius: 5,
  },
  backgroundBox: {
    width: 208,
    height: 132,
    position: "absolute",
    left: -7,
    top: 0,
    backgroundColor: "#243144",
  },
  header: {
    width: 146,
    height: 17,
    position: "absolute",
    left: 3,
    top: 0,
    color: "#D37F51",
    fontSize: 12,
    fontWeight: "semibold",
  },
  separator: {
    alignSelf: "stretch",
    height: 0,
    backgroundColor: "#D37F51",
    borderBottomWidth: 1,
    borderBottomColor: "#D37F51",
    position: "absolute",
    left: 3,
    top: 16, 
    width: 146,
  },
  content: {
    width: 186,
    height: 52,
    position: "absolute",
    left: 3,
    top: 20,
    color: "#D9D9D9",
    fontSize: 10,
    fontWeight: "normal",
  },
});

export const RentVsPiti = () => {
  return (
    <View style={styles.rentVsPiti}>
      <View style={styles.backgroundBox} />
      <Text style={styles.header}>Rent vs. Total PITI</Text>
      <View style={styles.separator} />
      <Text style={styles.content}>
        Total PITI: $6261.53{"\n"}
        Current Rent: $3,500{"\n"}
        More Per Month: $2761.53
      </Text>
    </View>
  );
};

export default RentVsPiti;
