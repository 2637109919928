// import React from "react";
// import { HorizontalMiddle } from "./HorizontalMiddle";
// // import "./InvestmentSummary.css";

// export const InvestmentSummary = () => {
//   return (
//     <div className="investment-summary">
//       <div className="overlap-group">
//         <div className="rectangle" />
//       </div>

//       <div className="flexcontainer">
//         <p className="text">
//           <span className="text-wrapper">
//             Total PITI: $6261.53 <br />
//           </span>
//         </p>

//         <p className="text">
//           <span className="text-wrapper">Current Rent: $3,500 <br /></span>
//         </p>

//         <p className="text">
//           <span className="text-wrapper">More Per Month: $2761.53</span>
//         </p>

//         <HorizontalMiddle
//           className="horizontal-middle-inset"
//           divider="image.svg"
//           dividerClassName="horizontal-middle-instance"
//         />
//         <div className="div">Investment Summary</div>
//       </div>
//     </div>
//   );
// };

import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { HorizontalMiddle } from "./HorizontalMiddle";

const styles = StyleSheet.create({
  investmentSummary: {
    borderRadius: 5,
    height: 70,
    overflow: "hidden",
    width: 194,
    padding: 10,
  },
  overlapGroup: {
    height: 133,
    position: "relative",
    top: -1,
    left: -13,
    width: 273,
  },
  rectangle: {
    backgroundColor: "#243144",
    height: 132,
    width: 208,
    position: "absolute",
    left: 6,
    top: 1,
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    height: 105,
    position: "absolute",
    left: 16,
    top: 18,
    width: 257,
  },
  text: {
    fontSize: 10,
    color: "#d9d9d9",
  },
  textWrapper: {
    fontSize: 10,
    color: "#d9d9d9",
  },
  dividerText: {
    fontSize: 12,
    fontWeight: "semibold",
    color: "#d37f51",
    position: "absolute",
    left: 16,
    top: 0,
    width: 257,
  },
});

export const InvestmentSummary = () => {
  return (
    <View style={styles.investmentSummary}>
      <View style={styles.overlapGroup}>
        <View style={styles.rectangle} />
      </View>
      <View style={styles.flexContainer}>
        <Text style={styles.text}><Text style={styles.textWrapper}>Total PITI: $6261.53</Text></Text>
        <Text style={styles.text}><Text style={styles.textWrapper}>Current Rent: $3,500</Text></Text>
        <Text style={styles.text}><Text style={styles.textWrapper}>More Per Month: $2761.53</Text></Text>
        <HorizontalMiddle />
        <Text style={styles.dividerText}>Investment Summary</Text>
      </View>
    </View>
  );
};

export default InvestmentSummary;