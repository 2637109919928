import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateOther } from '../../../../../../../../state/home/homeSlice';
import FormattedNumberInput from '../../../../utils/FormattedNumberInput';

const OtherInput = () => {
  const dispatch = useDispatch();
  const reduxOther = useSelector(
    (state) => state.home.selected_home_details.other
  );

  // 🔥 Local state for smooth typing experience
  const [inputValue, setInputValue] = useState(reduxOther?.toString() || "");

  useEffect(() => {
    if (reduxOther?.toString() !== inputValue) {
      setInputValue(reduxOther?.toString() || "");
    }
  }, [reduxOther]);

  const handleChange = (value) => {
    // ✅ Allow blank input or just a decimal for smoother typing
    if (value === "" || value === ".") {
      setInputValue(value);
      return;
    }

    // ✅ Ensure valid number input (allows two decimals)
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setInputValue(value);
      dispatch(updateOther(parseFloat(value) || 0)); // ✅ Always store as number in Redux
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="home-price">Other Monthly Payments:</label>
      <FormattedNumberInput
        id="home-price"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter other monthly payments (Landscaping, maintenance, etc.)"
        allowDecimals={true}
      />
    </div>
  );
};

export default OtherInput;
