import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateSavingsBalance } from "../../../../../../../../state/client/clientAffordabilitySlice";
import FormattedNumberInput from "../../../../utils/FormattedNumberInput";

const SavingBalanceInput = () => {
  const dispatch = useDispatch();

  const savingsBalance = useSelector(
    (state) =>
      state.clientAffordability.selected_affordability_details.savings_balance ?? 0
  );

  const [inputValue, setInputValue] = useState(savingsBalance.toString());

  useEffect(() => {
    if (savingsBalance.toString() !== inputValue) {
      setInputValue(savingsBalance.toString());
    }
  }, [savingsBalance]);

  const handleChange = (value) => {
    if (value === "" || value === ".") {
      setInputValue(value);
      return;
    }

    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setInputValue(value);
      dispatch(updateSavingsBalance(parseFloat(value) || 0));
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="savings-balance">Savings Balance ($):</label>
      <FormattedNumberInput
        id="savings-balance"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter savings balance"
        allowDecimals={true}
      />
    </div>
  );
};

export default SavingBalanceInput;
