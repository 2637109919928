import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCreditStatus } from "../../../../../../../../state/client/clientAffordabilitySlice";

const FicoScoreInput = () => {
  const dispatch = useDispatch();

  // Get FICO score from Redux
  const reduxValue = useSelector(
    (state) => state.clientAffordability.selected_affordability_details.credit_status ?? ""
  );

  const [inputValue, setInputValue] = useState(reduxValue.toString());

  useEffect(() => {
    if (reduxValue.toString() !== inputValue) {
      setInputValue(reduxValue.toString());
    }
  }, [reduxValue]);

  const handleChange = (value) => {
    if (value === "" || /^[0-9]*$/.test(value)) {
      setInputValue(value);

      const numericValue = parseInt(value, 10);

      if (!isNaN(numericValue) && numericValue >= 300 && numericValue <= 850) {
        dispatch(updateCreditStatus(numericValue));
      } else if (value === "") {
        dispatch(updateCreditStatus(""));
      }
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="fico-score">FICO Score:</label>
      <input
        type="text"
        id="fico-score"
        value={inputValue}
        onChange={(e) => handleChange(e.target.value)}
        placeholder="Enter FICO score (300-850)"
        className="number-input"
        maxLength={3}
      />
    </div>
  );
};

export default FicoScoreInput;
