import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom"; 
import { pdf } from '@react-pdf/renderer'; // Import pdf function to generate a Blob
import HomeDropdown from './HomeDropdown';
import HomePurchaseDetails from './HomePurchaseDetails';
import MortgageCalculator from './MortgageCalculator';
import TaxBenefits from './TaxBenefits';
import AmortizationBenefit from './AmortizationBenefits';
import InvestmentBenefit from './InvestmentBenefit';
import SummaryBox from './SummaryBox';
import MyDocument from './pdfGeneration/pdfGeneration';
import './LoanPacket.css';
import { downloadExcel } from "./utils/generateExcel";
import { authenticateGoogleAndGenerateSheet } from "./utils/generateGoogleSheet";  

const LoanPacket = () => {
  const currentRent = useSelector((state) => state.clientAssumptions.selected_assumptions.current_rent);  
  const affordabilityDetails = useSelector((state) => state.clientAffordability.selected_affordability_details);
  const selectedHome = useSelector((state) => state.home.selected_home_details);
  const selectedAssumptions = useSelector((state) => state.clientAssumptions.selected_assumptions);
  const userContact = useSelector((state) => state.userContact.selected_contact_details);
  const clientInfo = useSelector((state) => state.clientInfo.selected_client_info); 
  const clientContact = useSelector((state) => state.clientContact.selected_contact_details);
  const client = useSelector((state) => state.client.selected_client_details);

  const generalInfo = useSelector((state) => state.clientGeneralInfo.selected_general_info);

  const [totalPrincipalPayments, settotalPrincipalPayments] = useState(0); 
  const [investmentBenefit, setInvestmentBenefit] = useState(0); 

  const [mortgageData, setMortgageData] = useState({
    totalPITI: 0,
    loanAmount: 0,
    interestRate: 0,
    totalPayments: 0,
    paymentPerPeriod: 0,
    propertyTax: 0,
    homeInsurance: 0,
    pmi: 0,
    hoa: 0
  });

  const [taxData, setTaxData] = useState({
    income: 0,
    totalInterest: 0,
    propertyTax: 0,
    filingStatus: '',
    standardDeduction: 0,
    incomeWithStandardDeduction: 0,
    taxesPaid: 0,
    incomeWithInterestAndTaxDeductions: 0,
    taxesPaidWithDeductions: 0,
    netTaxBenefit: 0,
    monthlyTaxBenefit: 0,
  });

  const location = useLocation(); 
  useEffect(() => {
    if (location.pathname === "/success") {
      const savedInputs = JSON.parse(localStorage.getItem("loanInputs")) || {};
      authenticateGoogleAndGenerateSheet(savedInputs);
    }
  }, [location]);

  const handleTaxDataChange = useCallback((value) => {
    setTaxData(value);
  }, []);

  const handleMortgageDataChange = useCallback((newMortgageData) => {
    setMortgageData(newMortgageData);
  }, []);

  const handleTotalPrincipalChange = (newTotalPrincipal) => {
    settotalPrincipalPayments(newTotalPrincipal);
  };

  const handleInvestmentChange = (newInvestmentBenefit) => {
    setInvestmentBenefit(newInvestmentBenefit);
  };

  const rentDifference = mortgageData.totalPITI - currentRent;

  const ownInputs = useSelector((state) => state.adjustInputs.ownInputs);

  const generateAndOpenPDF = async () => {
    // Generate the PDF as a Blob
    const doc = (
      <MyDocument
        taxData={taxData}
        mortgageData={mortgageData}
        totalPrincipalPayments={totalPrincipalPayments}
        investmentBenefit={investmentBenefit}
        currentRent={currentRent}
        selectedHome={selectedHome}
        affordabilityDetails={affordabilityDetails}
        userContact={userContact}
        clientInfo={clientInfo}
        clientContact={clientContact}
        client={client}
      />
    );
    
    const blob = await pdf(doc).toBlob(); // Convert React PDF document to a Blob
    const url = URL.createObjectURL(blob); // Create an object URL from the Blob

    // Open the generated PDF in a new tab
    window.open(url, '_blank');
  };

  const styles = {
    letterContainer: {
      width: "612px", // Letter size width
      height: "792px", // Letter size height
      backgroundColor: "white",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      border: "1px solid black", // Just to visualize the boundary
      margin: "20px auto", // Centers the document
    },
  };

  return (
    <div className="loan-packet-container">
      {/* Home selection dropdown in a separate component */}
        {/* Buttons for generating Google Spreadsheet and triggering PDF */}
        <div style={{display: 'flex' }}>
          {/* <button 
            onClick={() => authenticateGoogleAndGenerateSheet()} 
            className="generate-sheet-button"
            style={{
              backgroundColor: '#4CAF50',
              color: 'white',
              padding: '10px 20px',
              marginRight: '10px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            Generate Google Spreadsheet
          </button> */}
          <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between', 
            width: '100%', 
            marginBottom: '20px' 
          }}>
            {/* Left: Home Dropdown */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <HomeDropdown />
            </div>

            {/* Right: Excel + PDF Buttons */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button 
                onClick={() => downloadExcel(selectedHome, selectedAssumptions, affordabilityDetails, generalInfo)}
                style={{
                  backgroundColor: '#e17e62',
                  color: 'white',
                  padding: '10px 20px',
                  marginRight: '10px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  height: '40px', // Align with dropdown height
                
                }}
              >
                Download Excel
              </button>

              <button 
                onClick={generateAndOpenPDF} 
                className="generate-pdf-button"
                style={{
                  backgroundColor: '#5f83a7',
                  color: 'white',
                  padding: '10px 20px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  height: '40px' // Align with dropdown height
                }}
              >
                Generate PDF
              </button>
            </div>
          </div>
        </div>

      {/* Home Purchase Details and Mortgage Calculator */}
      <div className="row">
          <HomePurchaseDetails selectedHome={selectedHome} />
          <MortgageCalculator 
            totalPITI={mortgageData.totalPITI} 
            onMortgageDataChange={handleMortgageDataChange} 
          />
      {/* Rent vs Total PITI Summary */}
        <SummaryBox 
          title="Rent vs Total PITI" 
          content={`Total PITI: $${mortgageData.totalPITI}\nCurrent Rent: $${currentRent}\nMore Per Month: $${rentDifference.toFixed(2)}`} 
          backgroundColor="#E2E5EA" 
        />
      </div>

      {/* Tax Benefits and Summary */}
      <div className="row">
        <TaxBenefits onTaxDataChange={handleTaxDataChange} />
        <SummaryBox 
          title="Tax Benefits" 
          content={`Monthly Tax Benefit: $${taxData.monthlyTaxBenefit ? taxData.monthlyTaxBenefit.toFixed(2) : 'N/A'}\nMore Per Month: $${(rentDifference - taxData.monthlyTaxBenefit).toFixed(2)}`} 
          backgroundColor="#f7f8f9" 
        />
      </div>

      {/* Amortization Benefit and Summary */}
      <div className="row">
        <AmortizationBenefit onTotalPrincipalChange={handleTotalPrincipalChange} />
        <SummaryBox 
          title="Amortization Summary" 
          content={`Amortization Benefit: $${(totalPrincipalPayments / 12).toFixed(2)}\nMore Per Month: $${((rentDifference - taxData.monthlyTaxBenefit) - (totalPrincipalPayments/12)).toFixed(2)}`} 
          backgroundColor="#E2E5EA" 
        />
      </div>

      {/* Investment Benefit and Summary */}
      <div className="row">
        <InvestmentBenefit onInvestmentBenefitChange={handleInvestmentChange} />
        <SummaryBox 
          title="Investment Summary" 
          content={`Investment Benefit: $${(investmentBenefit / 12).toFixed(2)}\nMore Per Month: $${(rentDifference - taxData.monthlyTaxBenefit - (totalPrincipalPayments/12) - (investmentBenefit / 12)).toFixed(2)}`} 
          backgroundColor="white" 
        />
      </div>
    </div>
  );
};

export default LoanPacket;