import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  generalInfoByClient: {}, // Holds all general info per client_id
  selected_client_id: null,
  selected_general_info: {
    client_id: null,
    inflation_rate: 3,
    expected_investment_growth: 10,
    general_monthly_expenses: 0,
  },
};

export const clientGeneralInfoSlice = createSlice({
  name: 'clientGeneralInfo',
  initialState,
  reducers: {
    setGeneralInfo: (state, action) => {
        const info = action.payload;
        if (!info || !info.client_id) return;
      
        state.generalInfoByClient[info.client_id] = info;
        state.selected_general_info = { ...info };
        state.selected_client_id = info.client_id;
      },
      

    updateSelectedGeneralInfo: (state, action) => {
      const clientId = action.payload;
      state.selected_client_id = clientId;

      if (state.generalInfoByClient[clientId]) {
        state.selected_general_info = {
          ...state.generalInfoByClient[clientId],
          client_id: clientId,
        };
      } else {
        // Initialize empty if not found
        const defaultInfo = {
          client_id: clientId,
          inflation_rate: 3,
          expected_investment_growth: 10,
          general_monthly_expenses: 0,
        };
        state.generalInfoByClient[clientId] = defaultInfo;
        state.selected_general_info = { ...defaultInfo };
      }
    },

    updateExpectedInvestmentGrowth: (state, action) => {
      state.selected_general_info.expected_investment_growth = action.payload;
      if (state.selected_client_id) {
        state.generalInfoByClient[state.selected_client_id].expected_investment_growth = action.payload;
      }
    },

    updateInflationRate: (state, action) => {
      state.selected_general_info.inflation_rate = action.payload;
      if (state.selected_client_id) {
        state.generalInfoByClient[state.selected_client_id].inflation_rate = action.payload;
      }
    },

    updateGeneralMonthlyExpenses: (state, action) => {
      state.selected_general_info.general_monthly_expenses = action.payload;
      if (state.selected_client_id) {
        state.generalInfoByClient[state.selected_client_id].general_monthly_expenses = action.payload;
      }
    },

    updateExpectedSalaryIncrease: (state, action) => {
        state.selected_general_info.expected_salary_increase = action.payload;
        if (state.selected_client_id) {
            state.generalInfoByClient[state.selected_client_id].expected_salary_increase = action.payload;
        }
    },

    reset: () => initialState,
  },
});

export const {
  setGeneralInfo,
  updateSelectedGeneralInfo,
  updateExpectedInvestmentGrowth,
  updateInflationRate,
  updateGeneralMonthlyExpenses,
  updateExpectedSalaryIncrease,
  reset,
} = clientGeneralInfoSlice.actions;

export default clientGeneralInfoSlice.reducer;
