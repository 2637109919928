import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateExpectedSalaryIncrease } from '../../../../../../../../state/client/clientGeneralInfoSlice';
import FormattedNumberInput from '../../../../utils/FormattedNumberInput';

const ExpectedSalaryIncreaseInput = () => {
  const dispatch = useDispatch();
  const reduxValue = useSelector((state) => state.clientGeneralInfo?.selected_general_info.expected_salary_increase ?? 0); // ✅ Ensure it's always defined

  const [inputValue, setInputValue] = useState(reduxValue.toString());

  useEffect(() => {
    if (reduxValue.toString() !== inputValue) {
      setInputValue(reduxValue.toString());
    }
  }, [reduxValue]);

  const handleChange = (value) => {
    if (value === "" || value === ".") {
      setInputValue(value);
      return;
    }

    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setInputValue(value);
      dispatch(updateExpectedSalaryIncrease(parseFloat(value) || 0 ));
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="expected-salary-increase">Expected Salary Increase (%):</label>
      <FormattedNumberInput
        id="expected-salary-increase"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter expected salary increase"
        allowDecimals={true}
      />
    </div>
  );
};

export default ExpectedSalaryIncreaseInput;
