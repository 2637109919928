import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HomeDropdown from './components/HomeDropdown';
import HomePriceInput from './formInputs/HomePriceInput';
import PropertyTaxRateInput from './formInputs/PropertyTaxRateInput';
import HOAFeeInput from './formInputs/HOAFeeInput';
import LoanTermInput from './formInputs/LoanTermInput';
import LoanInterestRateInput from './formInputs/LoanInterestRateInput';
import DownPaymentInput from './formInputs/DownPaymentInput';
import AddressInput from './formInputs/AddressInput';
import UtilitiesInput from './formInputs/UtilitiesInput';
import OtherInput from './formInputs/OtherInput';

import { updateHomeFetch } from './fetches/updateHomeFetch';
import { addHomeFetch } from './fetches/addHomeFetch';

import { updateSelectedHome } from '../../../../../../../state/home/homeSlice';
import './styling/ownStyles.css';

const OwnInfoMain = () => {
  const dispatch = useDispatch();
  const selectedHomeId = useSelector((state) => state.home.selected_home_id);
  const selectedHomeDetails = useSelector((state) => state.home.selected_home_details);
  const selectedClientId = useSelector((state) => state.client.selected_client_id);

  const [isNewHome, setIsNewHome] = useState(false);

  const handleSave = async () => {
    console.log("🔍 Before Save | selectedHomeId:", selectedHomeId, "| isNewHome:", isNewHome);
    
    if (!selectedClientId || selectedClientId === -1) {
      alert("❌ No client is selected. Please select a client before adding a home.");
      return;
    }

    if (!selectedHomeDetails.address.trim()) {
      alert("❌ Address cannot be empty.");
      return;
    }
  
    if (selectedHomeId !== -1) {
      console.log("🛠 Updating home with ID:", selectedHomeId);
      try {
        const status = await updateHomeFetch(selectedHomeId, selectedHomeDetails);
        if (status) {
          alert("✅ Home updated successfully!");
        } else {
          alert("❌ Failed to save home details.");
        }
      } catch (error) {
        console.error("❌ Error saving home details:", error);
        alert("An unexpected error occurred while saving home details.");
      }
    } else {
      console.log("➕ Adding new home");
      try {
        const result = await addHomeFetch({
          client_id: selectedClientId,
          ...selectedHomeDetails,
        });
        if (result) {
          alert("✅ New home added successfully!");
          setIsNewHome(false);
          dispatch(updateSelectedHome(result.home_id));
        } else {
          alert("❌ Failed to add new home.");
        }
      } catch (error) {
        console.error("❌ Error adding new home:", error);
        alert("An unexpected error occurred while adding the home.");
      }
    }
  };

  return (
    <div className="own-info-main">
      <h2>Own Information</h2>
      <form>
        <HomeDropdown setIsNewHome={setIsNewHome} />
        <AddressInput />
        <HomePriceInput />
        <DownPaymentInput />
        <PropertyTaxRateInput />
        <HOAFeeInput />
        <LoanTermInput />
        <LoanInterestRateInput />
        <UtilitiesInput />
        <OtherInput />
        
        <button type="button" onClick={handleSave} className="save-button">
          {isNewHome ? 'Add New Home' : 'Save Home Details'}
        </button>
      </form>
    </div>
  );
};

export default OwnInfoMain;
