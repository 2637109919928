// import React from "react";
// import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
// import { AmortizationBenefitInfo } from "./AmortizationBenefitInfo";
// import { AmortizationSummary } from "./AmortizationSummary";
// import { ClientContact } from "./ClientContact";
// import { HomePurchase } from "./HomePurchase";
// import { InvestmentBenefitInfo } from "./InvestmentBenefitInfo";
// import { InvestmentSummary } from "./InvestmentSummary";
// import { MortgageCalculator } from "./MortgageCalculator";
// import { MortgageCoachInfo } from "./MortgageCoachInfo";
// import { RentVsPiti } from "./RentVsPiti";
// import { FlexContainer } from "./FlexContainer";
// import Box from "./MortgageCalcBox";

// const styles = StyleSheet.create({
//   letter: {
//     backgroundColor: "#ffffff",
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     width: 612,
//     height: 792,
//   },
//   container: {
//     backgroundColor: "#ffffff",
//     width: 612,
//     height: 792,
//     position: "relative",
//     padding: 20,
//   },
//   section: {
//     backgroundColor: "#d9d9d9",
//     borderRadius: 5,
//     padding: 10,
//     marginVertical: 5,
//     width: "100%",
//   },
//   headerText: {
//     color: "#243144",
//     fontSize: 16,
//     fontWeight: "bold",
//     textAlign: "center",
//     marginBottom: 10,
//   },
//   overlapContainer: {
//     position: "relative",
//     width: "100%",
//   },
//   overlapSection: {
//     backgroundColor: "#d9d9d9",
//     borderRadius: 5,
//     padding: 10,
//     position: "absolute",
//   },
// });

// export const MyDocument = () => {
//   return (
//     <Document>
//       <Page size="A4" style={styles.letter}>
//         <View style={styles.container}>
//           <Text style={styles.headerText}>Loan Pre Approval Packet</Text>
//           <View style={[styles.section, styles.overlapContainer]}>
//             <Box></Box>
//             {/* <FlexContainer> */}
//               <HomePurchase />
//               <MortgageCalculator />
//               <RentVsPiti />
//             {/* </FlexContainer> */}
//           </View>
//           <View style={styles.section}>
//             <MortgageCoachInfo />
//             <ClientContact />
//           </View>
//           <View style={[styles.section, styles.overlapContainer]}>
//             <FlexContainer>
//               <AmortizationBenefitInfo />
//               <AmortizationSummary />
//             </FlexContainer>
//           </View>
//           <View style={[styles.section, styles.overlapContainer]}>
//             <FlexContainer>
//               <InvestmentBenefitInfo />
//               <InvestmentSummary />
//             </FlexContainer>
//           </View>
//         </View>
//       </Page>
//     </Document>
//   );
// };

// export default MyDocument;

import React from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { AmortizationBenefitInfo } from "./AmortizationBenefitInfo";
import { AmortizationSummary } from "./AmortizationSummary";
import { ClientContact } from "./ClientContact";
import { HomePurchase } from "./HomePurchase";
import { InvestmentBenefitInfo } from "./InvestmentBenefitInfo";
import { InvestmentSummary } from "./InvestmentSummary";
import { MortgageCalculator } from "./MortgageCalculator";
import { MortgageCoachInfo } from "./MortgageCoachInfo";
import { RentVsPiti } from "./RentVsPiti";
import { FlexContainer } from "./FlexContainer";
import Box from "./MortgageCalcBox";
import TaxBenefitsInfo from "./TaxBenefitsInfo";
import TaxSummary from "./TaxSummary";

const styles = StyleSheet.create({
  letter: {
    backgroundColor: "#ffffff",
    width: 612,
    height: 792,
    position: "relative",
    padding: 10,
    marginHorizontal: 6,
  },
  headerText: {
    color: "#243144",
    fontSize: 16,
    top: 20,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  section: {
    backgroundColor: "#d9d9d9",
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
  },
  absolutePosition: {
    position: "absolute",
  },
});

export const MyDocument = () => {
  return (
    <Document>
      <Page size="A4" style={styles.letter}>
        <Text style={styles.headerText}>Loan Pre Approval Packet</Text>

        <View style={{ flexDirection: "row", justifyContent: "space-between", width: "100%", gap: 20 }}>
          <MortgageCoachInfo />
          <ClientContact />
        </View>
        
        {/* Overlapping Sections */}
        <View style={[styles.absolutePosition, { top: 0, width: 584, height: 142, position: "relative" }]}>
          {/* Background Box Positioned Absolutely */}
          <View style={{ position: "absolute", width: "100%", height: "100%", backgroundColor: "#D9D9D9", borderRadius: 5 }} />

          {/* Content in a Row */}
          <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", width: "100%", padding: 10 }}>
            <HomePurchase />
            <MortgageCalculator />
            <RentVsPiti />
          </View>
        </View>

        
        <View style={[styles.section, styles.absolutePosition, { top: 400, width: 254, height: 269 }]}>
          {/* Placeholder for Tax Calculations */}
          <TaxBenefitsInfo />
          <TaxSummary />
        </View>


        
        <View style={[styles.section, styles.absolutePosition, { top: 400, left: 274, width: 324, height: 116 }]}>
          <AmortizationBenefitInfo />
          <AmortizationSummary />
        </View>
        
        <View style={[styles.section, styles.absolutePosition, { top: 562, left: 274, width: 324, height: 147 }]}>
          <FlexContainer>
            <InvestmentBenefitInfo />
            <InvestmentSummary />
          </FlexContainer>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;