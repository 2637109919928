import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ownInputs: {
    homePrice: 0,
    downPayment: 0,
    loanTerm: 0,
    mortgageRate: 0,
    propertyTaxRate: 0,
    hoaFee: 0,
    appreciationRate: 5,
    homeUtilities: 0,
    homeOther: 0,
  },
  rentInputs: {
    monthlyRent: 0,
    annualRentIncrease: 0,
    monthlyRentInsurance: 0,
    utilities: 0,
    otherRentExpenses: 0,
  },
  generalInputs: {
    investmentGrowth: 10,
    inflationRate: 3,
    monthlyExpenses: 0,
    generalMonthlyExpenses: 0,
    expectedSalaryIncrease: 0,
  },
  affordabilityInputs: {
    currentAnnualGrossIncome: 0,
    creditStatus: "",
    savingsBalance: 0,
    savingsInterestRate: 0,
    standardDeduction: 0,
    taxBracket: 0,
    filingStatus: "",
    // annualMcCTaxCredit: 0,
    // maximumMcCTaxCredit: 0,
  },
  selectedMonth: 0,
};

const adjustInputsSlice = createSlice({
  name: 'adjustInputs',
  initialState,
  reducers: {
    // OWN INPUTS UPDATERS
    updateHomePrice: (state, action) => {
      state.ownInputs.homePrice = action.payload;
    },
    updateDownPayment: (state, action) => {
      state.ownInputs.downPayment = action.payload;
    },
    updateLoanTerm: (state, action) => {
      state.ownInputs.loanTerm = action.payload;
    },
    updateMortgageRate: (state, action) => {
      state.ownInputs.mortgageRate = action.payload;
    },
    updatePropertyTaxRate: (state, action) => {
      state.ownInputs.propertyTaxRate = action.payload;
    },
    updateHoaFee: (state, action) => {
      state.ownInputs.hoaFee = action.payload;
    },
    updateAppreciationRate: (state, action) => {
      state.ownInputs.appreciationRate = action.payload;
    },
    updateHomeUtilities: (state, action) => {
      state.ownInputs.homeUtilities = action.payload;
    },
    updateHomeOther: (state, action) => {
      state.ownInputs.homeOther = action.payload;
    },
    updateGeneralMonthlyExpenses: (state, action) => {
      state.generalInputs.generalMonthlyExpenses = action.payload;
    },
    updateExpectedSalaryIncrease: (state, action) => {
      state.generalInputs.expectedSalaryIncrease = action.payload;
    },
    
    // RENT INPUTS UPDATERS
    updateMonthlyRent: (state, action) => { state.rentInputs.monthlyRent = action.payload; },
    updateAnnualRentIncrease: (state, action) => { state.rentInputs.annualRentIncrease = action.payload; },
    updateMonthlyRentInsurance: (state, action) => { state.rentInputs.monthlyRentInsurance = action.payload; },
    updateUtilities: (state, action) => { state.rentInputs.utilities = action.payload; },
    updateOtherRentExpenses: (state, action) => { state.rentInputs.otherRentExpenses = action.payload; },

    // GENERAL INPUTS UPDATERS
    updateInvestmentGrowth: (state, action) => { state.generalInputs.investmentGrowth = action.payload; },
    updateInflationRate: (state, action) => { state.generalInputs.inflationRate = action.payload; },

    // AFFORDABILITY INPUTS UPDATERS
    updateCurrentAnnualGrossIncome: (state, action) => { state.affordabilityInputs.currentAnnualGrossIncome = action.payload; },
    updateCreditStatus: (state, action) => { state.affordabilityInputs.creditStatus = action.payload; },
    updateSavingsBalance: (state, action) => { state.affordabilityInputs.savingsBalance = action.payload; },
    updateSavingsInterestRate: (state, action) => { state.affordabilityInputs.savingsInterestRate = action.payload; },
    updateStandardDeduction: (state, action) => { state.affordabilityInputs.standardDeduction = action.payload; },
    updateTaxBracket: (state, action) => { state.affordabilityInputs.taxBracket = action.payload; },
    updateFilingStatus: (state, action) => { state.affordabilityInputs.filingStatus = action.payload; },
    // updateAnnualMcCTaxCredit: (state, action) => { state.affordabilityInputs.annualMcCTaxCredit = action.payload; },
    // updateMaximumMcCTaxCredit: (state, action) => { state.affordabilityInputs.maximumMcCTaxCredit = action.payload; },

    updateSelectedMonth: (state, action) => { state.selectedMonth = action.payload; },

    // RESET FUNCTION
    resetInputs: () => initialState,
  },
});

export const {
  updateHomePrice,
  updateDownPayment,
  updateLoanTerm,
  updateMortgageRate,
  updatePropertyTaxRate,
  updateHoaFee,
  updateMonthlyRent,
  updateAnnualRentIncrease,
  updateMonthlyRentInsurance,
  updateUtilities,
  updateOtherRentExpenses,
  updateInvestmentGrowth,
  updateInflationRate,
  updateSelectedMonth,
  updateCurrentAnnualGrossIncome,
  updateCreditStatus,
  updateSavingsBalance,
  updateSavingsInterestRate,
  updateStandardDeduction,
  updateTaxBracket,
  updateFilingStatus,
  // updateAnnualMcCTaxCredit,
  // updateMaximumMcCTaxCredit,
  resetInputs,
  updateAppreciationRate, 
  updateHomeUtilities, 
  updateGeneralMonthlyExpenses,
  updateExpectedSalaryIncrease,
  updateHomeOther
} = adjustInputsSlice.actions;

export default adjustInputsSlice.reducer;
