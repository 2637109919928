import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { useSelector, useDispatch } from 'react-redux';
import { updateUtilities } from '../../../../../../state/presentation/adjustInputsSlice';

const RentUtilities = () => {
  const dispatch = useDispatch();

  // Fetch utilities from Redux (adjustInputsSlice)
  const utilities = useSelector(
    (state) => state.adjustInputs.rentInputs.utilities
  );

  // Initialize local state with Redux value or default to $100
  const [localValue, setLocalValue] = useState(utilities ?? 100);

  // Ensure local state initializes correctly without resetting unnecessarily
  useEffect(() => {
    if (utilities !== null && utilities !== undefined) {
      setLocalValue(utilities);
    }
  }, []); // ✅ Prevents unnecessary resets when other sliders change

  const handleChange = (event, newValue) => {
    setLocalValue(newValue); // ✅ Update UI immediately
    dispatch(updateUtilities(newValue)); // ✅ Persist in Redux
  };

  return (
    <div>
      <label>Utilities: ${localValue}</label>
      <Slider
        value={localValue}
        min={0}
        max={500}
        step={10}
        onChange={(event, newValue) => handleChange(event, newValue)}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `$${value}`} // Display as currency
      />
    </div>
  );
};

export default RentUtilities;
