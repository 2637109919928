import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setHomes, updateSelectedHome } from '../../../../../state/home/homeSlice';
import { fetchHomes } from '../../../../../apiFetch/fetchHomes';

const HomeDropdown = () => {
  const dispatch = useDispatch();

  const selectedClientId = useSelector((state) => state.client.selected_client_id);
  const selectedHomeId = useSelector((state) => state.home.selected_home_id);
  const homes = useSelector((state) => Object.values(state.home.homes)).filter(
    (home) => home.client_id === selectedClientId
  );

  useEffect(() => {
    if (selectedClientId) {
      fetchHomes(selectedClientId, (fetchedHomes) => {
        dispatch(setHomes(fetchedHomes));
      });
    }
  }, [selectedClientId, dispatch]);

  return (
    <div
      className="home-dropdown"
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center', // aligns label with dropdown vertically
        marginRight: '20px',
        height: '40px' // same as button/dropdown height
      }}
    >
      <label
        style={{
          marginRight: '10px',
          fontWeight: 'bold',
          fontSize: '16px', // match button text size
          lineHeight: '1',   // fixes any vertical shift
        }}
      >
        Select a Home:
      </label>
  
      <select
        onChange={(e) => {
          const homeId = parseInt(e.target.value, 10);
          dispatch(updateSelectedHome(homeId));
        }}
        value={selectedHomeId}
        style={{
          fontSize: '16px',
          padding: '6px 12px',            // slightly reduced vertical padding
          borderRadius: '5px',
          border: '2px solid #c1c1c1',
          backgroundColor: 'white',
          color: '#333',
          cursor: 'pointer',
          minWidth: '250px',
          height: '44px',                 // a bit taller than before
          lineHeight: 'normal',          // ensures text fits properly
          appearance: 'none',
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          backgroundImage: `url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/></svg>")`,
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'calc(100% - 12px)',
          backgroundPositionY: 'center',
          boxSizing: 'border-box'        // ensures padding doesn’t overflow height
        }}
      >
        <option value={-1} disabled>Select a Home</option>
        {homes.map((home) => (
          <option key={home.home_id} value={home.home_id}>
            {home.address}
          </option>
        ))}
      </select>
    </div>
  );
  
};

export default HomeDropdown;
