import React, { useState, useEffect } from 'react';
import { formatNumber } from '../../../utils/calcUtils/mathUtils';
import './ComparisonSectionClient.css';
import { saveEditedComparisonDetail } from './ComparisonUtilsClient';

const ComparisonSectionClient = ({ home }) => {
  const [comparisonResults, setComparisonResults] = useState([]);
  const [expandedYear, setExpandedYear] = useState(null);
  const [expandedRentYear, setExpandedRentYear] = useState(null);
  const [highlightedCell, setHighlightedCell] = useState({ year: null, type: null });
  const [editingField, setEditingField] = useState({ year: null, field: null });
  const [editedValue, setEditedValue] = useState('');

  useEffect(() => {
    const calculateComparisonData = () => {
      // Use default values if any of the required fields are undefined
      const home_price = home.home_price/*|| 0*/;
      const down_payment = home.down_payment/*|| 0*/;
      const interest_rate = home.interest_rate/*|| 0*/;
      const rent_amount = home.rent_amount/*|| 0*/;
      const rent_increases = home.rent_increases/*|| 0.03*/; // Assuming default rent increase is 3%
  
      // Log home data for debugging
      console.log("Home data:", home);
  
      if (home_price > 0 && interest_rate > 0) {
        const years = [5, 10, 15, 20]; // Example years for comparison
        const data = years.map(year => ({
          years: year,
          futureRentExpense: calculateFutureRentExpenses(rent_amount, rent_increases, year),
          netWealth: calculateNetWealth(home_price, down_payment, interest_rate, year),
          sp500Value: calculateSP500Value(year)
        }));
  
        // Log calculated data for debugging
        console.log("Calculated comparison results:", data);
  
        setComparisonResults(data);
      } else {
        console.log("Missing home data for calculations.");
      }
    };
  
    calculateComparisonData();
  }, [home]);
  

  const calculateFutureRentExpenses = (rentAmount, rentIncreases, years) => {
    if (rentAmount !== undefined && rentIncreases !== undefined) {
      return 12 * rentAmount * ((Math.pow(1 + rentIncreases, years) - 1) / rentIncreases);
    }
    return 0;
  };

  const calculateNetWealth = (homePrice, downPayment, interestRate, years) => {
    // Replace with your own logic to calculate net wealth
    if (homePrice !== undefined && downPayment !== undefined && interestRate !== undefined) {
      return (homePrice - downPayment) * Math.pow(1 + interestRate, years);
    }
    return 0;
  };

  const calculateSP500Value = (years) => {
    // Replace with logic to calculate SP500 value
    return years * 10000; // Placeholder calculation
  };

  const handleExpandYear = (year) => {
    setExpandedYear(expandedYear === year ? null : year);
  };

  const handleCellClick = (year, type) => {
    setHighlightedCell({ year, type });
  };

  const handleDoubleClick = (year, field) => {
    setEditingField({ year, field });
    const value = comparisonResults.find(result => result.years === year).details[field];
    setEditedValue(value.toString());
  };

  const handleBlur = async (year, field) => {
    const currentResult = comparisonResults.find(result => result.years === year);
    if (currentResult && editedValue !== currentResult.details[field].toString()) {
      await saveEditedComparisonDetail(year, field, editedValue);
      setComparisonResults(prevResults => 
        prevResults.map(result => 
          result.years === year ? { ...result, details: { ...result.details, [field]: parseFloat(editedValue) }} : result
        )
      );
    }
    setEditingField({ year: null, field: null });
    setEditedValue('');
  };

  const handleKeyDown = (e, year, field) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      handleBlur(year, field);
    }
  };

  return (
    <div className="comparison-section">
      <h1>Rent vs Own Comparison</h1>
      <div className="comparison-container">
        <table>
          <thead>
            <tr>
              <th>Years</th>
              <th>Rent Expense</th>
              <th>Own Net Wealth</th>
              <th>SP500 Value</th>
            </tr>
          </thead>
          <tbody>
            {comparisonResults.map((result) => (
              <React.Fragment key={result.years}>
                <tr>
                  <td>{result.years}</td>
                  <td
                    style={{ color: 'red', cursor: 'pointer' }}
                    onClick={() => { handleExpandYear(result.years); handleCellClick(result.years, 'rent'); }}
                  >
                    ${formatNumber(result.futureRentExpense)}
                  </td>
                  <td
                    style={{ color: 'green', cursor: 'pointer' }}
                    onClick={() => { handleExpandYear(result.years); handleCellClick(result.years, 'own'); }}
                  >
                    ${formatNumber(result.netWealth)}
                  </td>
                  <td
                    style={{ color: 'black', cursor: 'pointer' }}
                    onClick={() => handleCellClick(result.years, 'sp500')}
                  >
                    ${formatNumber(result.sp500Value)}
                  </td>
                </tr>
                {expandedYear === result.years && (
                  <tr>
                    <td colSpan="4">
                      <div className="comparison-box">
                        <p>Years: {result.years}</p>
                        <p>Future Rent Expense: ${formatNumber(result.futureRentExpense)}</p>
                        <p>Net Wealth: ${formatNumber(result.netWealth)}</p>
                        <p>SP500 Value: ${formatNumber(result.sp500Value)}</p>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ComparisonSectionClient;
