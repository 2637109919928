import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateInflationRate } from '../../../../../../../../state/client/clientGeneralInfoSlice';
import FormattedNumberInput from '../../../../utils/FormattedNumberInput';

const InflationRateInput = () => {
  const dispatch = useDispatch();
  const reduxValue = useSelector((state) => state.clientGeneralInfo?.selected_general_info.inflation_rate ?? 0); // ✅ Fix: Ensure it's always defined

  const [inputValue, setInputValue] = useState(reduxValue.toString());

  useEffect(() => {
    if (reduxValue.toString() !== inputValue) {
      setInputValue(reduxValue.toString());
    }
  }, [reduxValue]);

  const handleChange = (value) => {
    if (value === "" || value === ".") {
      setInputValue(value);
      return;
    }

    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setInputValue(value);
      dispatch(updateInflationRate(parseFloat(value) || 0 ));
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="inflation-rate">Inflation Rate (%):</label>
      <FormattedNumberInput
        id="inflation-rate"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter inflation rate"
        allowDecimals={true}
      />
    </div>
  );
};

export default InflationRateInput;
