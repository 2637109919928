import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { calculateTotalPrincipal } from './calculations/amortizationCalcs';

const AmortizationBenefit = ({onTotalPrincipalChange}) => {

  // Accessing data from Redux store
  const affordabilityDetails = useSelector((state) => state.clientAffordability.selected_affordability_details);
  const homeDetails = useSelector((state) => state.home.selected_home_details);

  const loanAmount = homeDetails.home_price - homeDetails.down_payment;
  const interestRate = homeDetails.interest_rate;
  const years = homeDetails.term_months / 12; //TODO magic number for years, assuming always using 1 year
  const paymentsPerYear = homeDetails.payments_per_year;

  
  // Calculate the principal for each payment
  const totalPrincipal = calculateTotalPrincipal(loanAmount, interestRate, years, paymentsPerYear);


  // Format the result to two decimal places
  const formattedTotalPrincipal = totalPrincipal;

    useEffect(() => {
      if (onTotalPrincipalChange) {
        onTotalPrincipalChange(totalPrincipal);
      }
    }, [totalPrincipal, onTotalPrincipalChange]);

  return (
    <div className="section amortization-benefit">
      <h2>Amortization Benefit: Total Principal Payments</h2>
      <div>
        <p>Total Principal: ${formattedTotalPrincipal}</p>
      </div>
    </div>
  );
};

export default AmortizationBenefit;
