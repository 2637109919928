import React from "react";
import { ResponsivePie } from "@nivo/pie";
import getRentBreakdownData from "./getRentBreakdownData";

const RentBreakdownPieChart = ({ selectedMonth, rentInputs, monthlyIncome, generalMonthlyExpenses, inflationRate, expectedSalaryIncrease, affordabilityInputs }) => {
  const data = getRentBreakdownData(
    selectedMonth,
    rentInputs,
    monthlyIncome,
    generalMonthlyExpenses,
    inflationRate,  
    expectedSalaryIncrease,
    affordabilityInputs  // ✅ Pass affordabilityInputs so tax can be calculated
  );

  const total = data.reduce((acc, slice) => acc + slice.value, 0);

  return (
    <div style={{ height: 400 }}>
      <h3>Monthly Rent Breakdown</h3>
      <p>This chart represents how your **total monthly income** is allocated across rent expenses.</p>

      <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={1}
        cornerRadius={5}
        colors={{ datum: "data.color" }}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextColor="#333333"
        radialLabelsLinkColor={{ from: "color" }}
        sliceLabelsSkipAngle={10}
        sliceLabelsTextColor="#ffffff"
        sliceLabel={(datum) => {
          const percentage = ((datum.value / total) * 100).toFixed(1);
          return `${percentage}%`;
        }}
        tooltip={({ datum }) => (
          <div style={{ background: "#fff", padding: "5px", borderRadius: "3px", color: datum.color }}>
            <strong>{datum.id}:</strong> <br />
            ${Number(datum.value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <br />
            ({((datum.value / total) * 100).toFixed(1)}%)
          </div>
        )}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateY: 56,
            itemsSpacing: 10,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: "#999",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 14,
            symbolShape: "circle",
          },
        ]}
      />
    </div>
  );
};

export default RentBreakdownPieChart;
