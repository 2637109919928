import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { useSelector, useDispatch } from 'react-redux';
import { updateLoanTerm } from '../../../../../../state/presentation/adjustInputsSlice';

const LoanTermInput = () => {
  const dispatch = useDispatch();

  // Fetch loan term (years) from Redux (adjustInputsSlice)
  const loanTermYears = useSelector((state) => state.adjustInputs.ownInputs.loanTerm);

  // Local state for immediate UI updates
  const [localValue, setLocalValue] = useState(loanTermYears ?? 30); // Default to 30 years

  // Sync local state with Redux when Redux value changes
  useEffect(() => {
    if (loanTermYears !== null && loanTermYears !== undefined) {
      setLocalValue(loanTermYears);
    }
  }, []); // ✅ Runs only on mount to prevent unnecessary resets

  const handleChange = (event, newValue) => {
    setLocalValue(newValue); // ✅ Update UI immediately
    dispatch(updateLoanTerm(newValue * 12)); // ✅ Persist in Redux (convert years to months)
  };

  return (
    <div>
      <label>Loan Term: {localValue} years</label>
      <Slider
        value={localValue}
        min={5} // Minimum loan term of 5 years
        max={30} // Maximum loan term of 30 years
        step={1} // Increment by 1 year
        onChange={(event, newValue) => handleChange(event, newValue)}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${value} years`} // Display years on hover
      />
    </div>
  );
};

export default LoanTermInput;
