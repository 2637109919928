const SERVER_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:8080";

export const downloadExcel = async (selectedHome, selectedAssumptions, affordabilityDetails, generalInfo) => {
    try {
        console.log("📤 Sending request to generate Excel...");

        if (!selectedHome || Object.keys(selectedHome).length === 0) {
            console.error("❌ No home details available to export.");
            alert("No home selected. Please select a home first.");
            return;
        }

        console.log("📤 Sending home data:", selectedHome);
        console.log("🏠 Sending rent assumptions:", selectedAssumptions);

        const response = await fetch(`${SERVER_URL}/api/excel/generate-excel`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                selected_home_details: selectedHome,
                selected_assumptions: selectedAssumptions,
                selected_affordability: affordabilityDetails, 
                client_general_info: generalInfo,
            }),                      
        });

        if (!response.ok) {
            throw new Error("Failed to generate Excel file.");
        }

        // ✅ Create a Blob from the response
        const data = await response.blob();
        const url = window.URL.createObjectURL(data);

        // ✅ Create a link and trigger download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Loan_Details.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        console.log("✅ Excel file downloaded successfully");
    } catch (error) {
        console.error("❌ Error generating Excel file:", error);
        alert("Failed to generate Excel file.");
    }
};
