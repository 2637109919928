import React from "react";
import { useSelector } from "react-redux";
import RentBreakdownPieChart from "./RentBreakdownPieChart";

const RentBreakdownMain = ({ selectedMonth, rentInputs, affordabilityInputs = {} }) => {  // ✅ Ensure default empty object
  const reduxAffordabilityInputs = useSelector((state) => state.adjustInputs.affordabilityInputs) || {};
  const generalMonthlyExpenses = useSelector((state) => state.adjustInputs.generalInputs.generalMonthlyExpenses) || 0;
  const inflationRate = useSelector((state) => state.adjustInputs.generalInputs.inflationRate) || 0;
  const expectedSalaryIncrease = useSelector((state) => state.adjustInputs.generalInputs.expectedSalaryIncrease) || 0;

  const baseAnnualIncome = affordabilityInputs.currentAnnualGrossIncome || reduxAffordabilityInputs.currentAnnualGrossIncome || 0;
  const baseMonthlyIncome = baseAnnualIncome / 12;

  const adjustedMonthlyIncome = baseMonthlyIncome * Math.pow(1 + expectedSalaryIncrease / 100, selectedMonth / 12);

  return (
    <div className="rent-breakdown-main">
      <h3>Rent Breakdown for Month {selectedMonth}</h3>
      <p><strong>Monthly Income:</strong> ${adjustedMonthlyIncome.toLocaleString()}</p>

      <RentBreakdownPieChart 
          selectedMonth={selectedMonth} 
          rentInputs={rentInputs} 
          monthlyIncome={adjustedMonthlyIncome}  
          generalMonthlyExpenses={generalMonthlyExpenses} 
          inflationRate={inflationRate}  
          expectedSalaryIncrease={expectedSalaryIncrease}  
          affordabilityInputs={reduxAffordabilityInputs} 
        />

    </div>
  );
};

export default RentBreakdownMain;
