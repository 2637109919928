import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import proper icons
import CurrentRentSlider from './rentInputs/CurrentRent';
import RentGrowthRate from './rentInputs/RentGrowthRate';
import MonthlyRentInsurance from './rentInputs/MonthlyRentInsurance';
import RentUtilities from './rentInputs/RentUtilities';
import OtherRentExpensesInput from './rentInputs/OtherRentExpensesInput';

import HomePriceInput from './ownInputs/HomePrice';
import DownPaymentInput from './ownInputs/DownPayment';
import LoanTermInput from './ownInputs/LoanTerm';
import MortgageRateInput from './ownInputs/MortgageRate';
import PropertyTaxRateInput from './ownInputs/PropertyTaxRate';
import MonthlyHOAFeeInput from './ownInputs/MonthlyHOAFee';
import ExpectedInvestmentGrowth from "./generalInputs/ExpectedInvestmentGrowth";
import InflationRate from "./generalInputs/InflationRate";
import HomeAppreciationRate from "./ownInputs/HomeAppreciationRate";
import GeneralMonthlyExpenses from "./generalInputs/GeneralMonthlyExpenses";
import ExpectedSalaryIncrease from "./generalInputs/ExpectedSalaryIncrease";

import './sliderLook/sliderStyles.css';

const InputSlidersMain = ({ clientId }) => {
  const location = useLocation();
  const isPresentationMode = location.pathname.includes("/ClientLayout/PresentationPage");

  // Dropdown states
  const [ownOpen, setOwnOpen] = useState(false);
  const [rentOpen, setRentOpen] = useState(false);
  const [generalOpen, setGeneralOpen] = useState(false);

  return (
    <div className={`input-sliders-main ${isPresentationMode ? 'sidebar-sliders' : ''}`}>
      <div className="sliders-container">

        {/* Own Inputs Section */}
        <div className="slider-section">
          <button className="dropdown-header" onClick={() => setOwnOpen(!ownOpen)}>
            <span>Own Inputs</span>
            {ownOpen ? <FaChevronUp className="dropdown-icon" /> : <FaChevronDown className="dropdown-icon" />}
          </button>
          {ownOpen && (
            <div className="dropdown-content">
              <HomePriceInput />
              <DownPaymentInput />
              <LoanTermInput />
              <MortgageRateInput />
              <PropertyTaxRateInput />
              <MonthlyHOAFeeInput />
              <HomeAppreciationRate />
            </div>
          )}
        </div>

        {/* Rent Inputs Section */}
        <div className="slider-section">
          <button className="dropdown-header" onClick={() => setRentOpen(!rentOpen)}>
            <span>Rent Inputs</span>
            {rentOpen ? <FaChevronUp className="dropdown-icon" /> : <FaChevronDown className="dropdown-icon" />}
          </button>
          {rentOpen && (
            <div className="dropdown-content">
              <CurrentRentSlider />
              <RentGrowthRate />
              <MonthlyRentInsurance />
              <RentUtilities />
              <OtherRentExpensesInput />
            </div>
          )}
        </div>

        {/* General Inputs Section */}
        <div className="slider-section">
          <button className="dropdown-header" onClick={() => setGeneralOpen(!generalOpen)}>
            <span>General Inputs</span>
            {generalOpen ? <FaChevronUp className="dropdown-icon" /> : <FaChevronDown className="dropdown-icon" />}
          </button>
          {generalOpen && (
            <div className="dropdown-content">
              <ExpectedInvestmentGrowth />
              <InflationRate />
              <GeneralMonthlyExpenses />
              <ExpectedSalaryIncrease />
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default InputSlidersMain;
