import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  taxSummary: {
    width: 192,
    height: 70,
    position: "relative",
    overflow: "hidden",
    borderRadius: 5,
    alignSelf: "center", // Centers the element within its parent
},
  backgroundBox: {
    width: 208,
    height: 73,
    position: "absolute",
    left: -7,
    top: 0,
    backgroundColor: "#243144",
  },
  content: {
    width: 182,
    height: 44,
    position: "absolute",
    left: 3,
    top: 18,
    color: "#D9D9D9",
    fontSize: 10,
    fontWeight: "normal",
  },
  separator: {
    width: 146,
    height: 0,
    position: "absolute",
    top: 16, // Adjusted to appear below the header
    left: 0,
    backgroundColor: "#D37F51",
    borderBottomWidth: 1,
    borderBottomColor: "#D37F51",
  },
  
  header: {
    width: 101,
    height: 17,
    position: "absolute",
    left: 3,
    top: 0,
    color: "#D37F51",
    fontSize: 12,
    fontWeight: "semibold",
  },
});

export const TaxSummary = () => {
  return (
    <View style={styles.taxSummary}>
      <View style={styles.backgroundBox} />
      <Text style={styles.header}>Tax Summary</Text>
      <View style={styles.separator} />
      <Text style={styles.content}>
        Monthly Tax Benefit: $602.10{"\n"}
        More Per Month: $2159.43
      </Text>
    </View>
  );
};

export default TaxSummary;