// import React from "react";
// import { HorizontalMiddle } from "./HorizontalMiddle";
// import "./MortgageCalculator.css";

// export const MortgageCalculator = () => {
//   return (
//     <div className="mortgage-calculator">
//       <div className="text-wrapper">Mortgage Calculator</div>

//       <div className="overlap-group">
//         <HorizontalMiddle
//           className="horizontal-middle-inset"
//           divider="image.svg"
//           dividerClassName="horizontal-middle-instance"
//         />
//         <div className="flexcontainer">
//           <p className="text">
//             <span className="span">Loan Amount: $760,000</span>
//           </p>
//           <p className="text">
//             <span className="span">Total # Payments: 360</span>
//           </p>
//           <p className="text">
//             <span className="span">Payment per Period: $4995.2</span>
//           </p>
//           <p className="text">
//             <span className="span">Property Taxes: $833.33</span>
//           </p>
//           <p className="text">
//             <span className="span">Insurance: $150</span>
//           </p>
//           <p className="text">
//             <span className="span">PMI: $133.00</span>
//           </p>
//           <p className="text">
//             <span className="span">HOA: $150</span>
//           </p>
//           <p className="text">
//             <span className="span">Total PITI: $6261.53</span>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { HorizontalMiddle } from "./HorizontalMiddle";

const styles = StyleSheet.create({
  mortgageCalculator: {
    height: 132,
    overflow: "hidden",
    position: "relative",
    width: 201,
    padding: 10,
  },
  textWrapper: {
    color: "#243144",
    fontSize: 12,
    fontWeight: "semibold",
    position: "absolute",
    top: -1,
    width: 257,
  },
  overlapGroup: {
    height: 105,
    position: "absolute",
    top: 17,
    width: 273,
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    height: 105,
    position: "absolute",
    top: 0,
    width: 257,
  },
  separator: {
    alignSelf: "stretch",
    height: 0,
    backgroundColor: "#D37F51",
    borderBottomWidth: 1,
    borderBottomColor: "#D37F51",
    position: "absolute",
    left: 6,
    top: 14, // Adjust this to fine-tune positioning
    width: 146,
  },  
  text: {
    fontSize: 10,
    color: "#000000",
  },
  span: {
    fontSize: 10,
    color: "#000000",
  },
});

export const MortgageCalculator = () => {
  return (
    <View style={styles.mortgageCalculator}>
      <Text style={styles.textWrapper}>Mortgage Calculator</Text>
      <View style={styles.separator} />
      <View style={styles.overlapGroup}>
        
        <View style={styles.flexContainer}>
          <Text style={styles.text}><Text style={styles.span}>Loan Amount: $760,000</Text></Text>
          <Text style={styles.text}><Text style={styles.span}>Total # Payments: 360</Text></Text>
          <Text style={styles.text}><Text style={styles.span}>Payment per Period: $4995.2</Text></Text>
          <Text style={styles.text}><Text style={styles.span}>Property Taxes: $833.33</Text></Text>
          <Text style={styles.text}><Text style={styles.span}>Insurance: $150</Text></Text>
          <Text style={styles.text}><Text style={styles.span}>PMI: $133.00</Text></Text>
          <Text style={styles.text}><Text style={styles.span}>HOA: $150</Text></Text>
          <Text style={styles.text}><Text style={styles.span}>Total PITI: $6261.53</Text></Text>
        </View>
      </View>
    </View>
  );
};

export default MortgageCalculator;
