// export const calculatePaymentPerPeriod = (loanAmount, interestRate, years, paymentsPerYear) => {
//     const totalPayments = years * paymentsPerYear;
//     const monthlyInterestRate = (interestRate / 100) / paymentsPerYear;
  
//     // PMT formula calculation
//     if (monthlyInterestRate === 0) {
//       return loanAmount / totalPayments; // Edge case for 0% interest
//     }
  
//     const payment = (-loanAmount * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments)) /
//                     (Math.pow(1 + monthlyInterestRate, totalPayments) - 1)).toFixed(2);
  
//     return payment;
//   };
export const calculatePaymentPerPeriod = (loanAmount, interestRate, years, paymentsPerYear) => {
  const totalPayments = years * paymentsPerYear;
  const monthlyInterestRate = (interestRate / 100) / paymentsPerYear;

  // PMT formula calculation
  if (monthlyInterestRate === 0) {
    return loanAmount / totalPayments; // Edge case for 0% interest
  }

  // Calculate payment using the formula
  const payment = (-loanAmount * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments)) /
                  (Math.pow(1 + monthlyInterestRate, totalPayments) - 1));

  // Use Math.floor to truncate the decimal places and return a fixed value rounded to two decimal places
  return Math.floor(payment * 100) / 100; // This floors the payment value to two decimal places
};

  
  
  export const calculateTotalInterest = (loanAmount, interestRate, years, paymentsPerYear) => {
    const totalPayments = Math.min(12, years * paymentsPerYear); // Only calculate for the first 12 payments
    const yearlyInterestRate = interestRate / 100;
    let totalInterest = 0;
    let remainingLoan = loanAmount;
    const paymentPerPeriod = calculatePaymentPerPeriod(loanAmount, interestRate, years, paymentsPerYear); // Fixed payment per period

    // Calculate the interest for each payment period (up to 12 payments)
    for (let i = 0; i < totalPayments; i++) {
      const interestPayment = remainingLoan * yearlyInterestRate / paymentsPerYear;
      totalInterest += interestPayment;
  
  
      // Correctly calculate principal payment and update remaining loan balance

      const principalPayment = (paymentPerPeriod * -1) - interestPayment;
      remainingLoan -= principalPayment; // Subtract principal from remaining loan balance
    }
  
    return totalInterest.toFixed(2);
  };
  
  
  
  
  
  export const calculateTotalPITI = (paymentPerPeriod, propertyTax, insurance, pmi, hoa) => {
    return (
      parseFloat(paymentPerPeriod) +
      parseFloat(propertyTax || 0) +
      parseFloat(insurance || 0) +
      parseFloat(pmi || 0) +
      parseFloat(hoa || 0)
    ).toFixed(2);
  };
  
  export const calculateTaxesPaid = (incomeAfterDeduction, filingStatus) => {
  
    // Tax Brackets for Single and Married Filing Jointly
    const taxBrackets = {
      Single: [
        { min: 0, max: 11600, rate: 0.1 },
        { min: 11601, max: 47150, rate: 0.12 },
        { min: 47151, max: 100525, rate: 0.22 },
        { min: 100526, max: 191950, rate: 0.24 },
        { min: 191951, max: 243725, rate: 0.32 },
        { min: 243726, max: 609350, rate: 0.35 },
        { min: 609351, max: Infinity, rate: 0.37 },
      ],
      Jointly: [
        { min: 0, max: 23200, rate: 0.1 },
        { min: 23201, max: 94300, rate: 0.12 },
        { min: 94301, max: 201050, rate: 0.22 },
        { min: 201051, max: 383900, rate: 0.24 },
        { min: 383901, max: 487450, rate: 0.32 },
        { min: 487451, max: 731200, rate: 0.35 },
        { min: 731201, max: Infinity, rate: 0.37 },
      ],
    };
  
    const brackets = taxBrackets[filingStatus];
  
    if (!brackets) {
      console.error("No tax brackets found for the filing status:", filingStatus);
      return null;
    }
  
    let taxesPaid = 0;
    let previousMax = 0;
  
    for (let i = 0; i < brackets.length; i++) {
      const bracket = brackets[i];
  
      if (incomeAfterDeduction > bracket.min) {
        const taxableIncomeInBracket = Math.min(incomeAfterDeduction, bracket.max) - bracket.min;
        taxesPaid += taxableIncomeInBracket * bracket.rate;
        previousMax = bracket.max; // update the previousMax for the next iteration
      } else {
        break; // No need to check further brackets
      }
    }
  
    return taxesPaid;
  };
  