import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateCurrentAnnualGrossIncome } from "../../../../../../../../state/client/clientAffordabilitySlice";
import FormattedNumberInput from "../../../../utils/FormattedNumberInput";

const AnnualGrossIncomeInput = () => {
  const dispatch = useDispatch();
  const reduxValue = useSelector(
    (state) => Number(state.clientAffordability.selected_affordability_details.current_annual_gross_income) || 0
  );
  

  const [inputValue, setInputValue] = useState(reduxValue.toString());

  useEffect(() => {
    if (reduxValue.toString() !== inputValue) {
      setInputValue(reduxValue.toString());
    }
  }, [reduxValue]);

  const handleChange = (value) => {
    if (value === "" || value === ".") {
      setInputValue(value);
      return;
    }

    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setInputValue(value);
      dispatch(updateCurrentAnnualGrossIncome(parseFloat(value) || 0));
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="annual-gross-income">Annual Gross Income ($):</label>
      <FormattedNumberInput
        id="annual-gross-income"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter annual income"
        allowDecimals={true}
      />
    </div>
  );
};

export default AnnualGrossIncomeInput;
