import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateExpectedInvestmentGrowth } from '../../../../../../../../state/client/clientGeneralInfoSlice';
import FormattedNumberInput from '../../../../utils/FormattedNumberInput';

const ExpectedInvestmentGrowthInput = () => {
  const dispatch = useDispatch();
  const reduxValue = useSelector((state) => state.clientGeneralInfo.selected_general_info.expected_investment_growth);

  const [inputValue, setInputValue] = useState(reduxValue.toString());

  useEffect(() => {
    if (reduxValue.toString() !== inputValue) {
      setInputValue(reduxValue.toString());
    }
  }, [reduxValue]);

  const handleChange = (value) => {
    if (value === "" || value === ".") {
      setInputValue(value);
      return;
    }

    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setInputValue(value);
      dispatch(updateExpectedInvestmentGrowth(parseFloat(value) || 10));
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="investment-growth">Expected Investment Growth (%):</label>
      <FormattedNumberInput
        id="investment-growth"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter expected investment growth"
        allowDecimals={true}
      />
    </div>
  );
};

export default ExpectedInvestmentGrowthInput;
