const getRentBreakdownData = (selectedMonth, rentInputs, monthlyIncome, generalMonthlyExpenses, inflationRate, expectedSalaryIncrease, affordabilityInputs = {}) => {
  const rent = parseFloat(rentInputs.monthlyRent) || 0;
  const insurance = parseFloat(rentInputs.monthlyRentInsurance) || 0;
  const utilities = parseFloat(rentInputs.utilities) || 0;
  const otherExpenses = parseFloat(rentInputs.otherRentExpenses) || 0;
  const generalExpenses = parseFloat(generalMonthlyExpenses) || 0;

  const rentGrowthRate = parseFloat(rentInputs.annualRentIncrease) || 0;
  const salaryGrowthRate = parseFloat(expectedSalaryIncrease) || 0;
  const inflationRateValue = parseFloat(inflationRate) || 0;

  // Adjust expenses over time
  const adjustedRent = rent * Math.pow(1 + rentGrowthRate / 100, selectedMonth / 12);
  const adjustedInsurance = insurance * Math.pow(1 + inflationRateValue / 100, selectedMonth / 12);
  const adjustedUtilities = utilities * Math.pow(1 + inflationRateValue / 100, selectedMonth / 12);
  const adjustedOtherExpenses = otherExpenses * Math.pow(1 + inflationRateValue / 100, selectedMonth / 12);
  const adjustedGeneralExpenses = generalExpenses * Math.pow(1 + inflationRateValue / 100, selectedMonth / 12);
  const adjustedMonthlyIncome = monthlyIncome * Math.pow(1 + salaryGrowthRate / 100, selectedMonth / 12);

  // ✅ FIX Income Tax Calculation
  const standardDeductionAnnual = parseFloat(affordabilityInputs?.standardDeduction) || 0;
  const standardDeductionMonthly = standardDeductionAnnual / 12;  // Convert to monthly
  const taxBracketPercentage = parseFloat(affordabilityInputs?.taxBracket) || 0;
  const taxBracketDecimal = taxBracketPercentage / 100;

  const taxableIncome = Math.max(adjustedMonthlyIncome - standardDeductionMonthly, 0);
  const monthlyIncomeTax = taxableIncome * taxBracketDecimal;

  // ✅ Fix: Ensure total expenses include tax
  const totalExpenses = adjustedRent + adjustedInsurance + adjustedUtilities + adjustedOtherExpenses + adjustedGeneralExpenses + monthlyIncomeTax;
  const leftover = adjustedMonthlyIncome - totalExpenses;

  return [
    { id: "Income Tax", value: monthlyIncomeTax, color: "#FF5733" },  // ✅ Fixed
    { id: "Rent", value: adjustedRent, color: "#3380FF" },
    { id: "Rent Insurance", value: adjustedInsurance, color: "#FF33A8" },
    { id: "Utilities", value: adjustedUtilities, color: "#FFC733" },
    { id: "Other Expenses", value: adjustedOtherExpenses, color: "#33FFF5" },
    { id: "General Monthly Expenses", value: adjustedGeneralExpenses, color: "#FFA500" },
    { id: "Leftover Savings", value: leftover, color: leftover < 0 ? "#FF0000" : "#57A8FF" },
  ];
};

export default getRentBreakdownData;
