import React, { useState, useEffect, createContext } from 'react';
import { useNavigate, Outlet, NavLink } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaEllipsisV, FaExpand, FaCompress, FaChevronLeft, FaUser, FaChartLine, FaFileAlt, FaHome } from 'react-icons/fa';
import './ClientLayout.css'; // Your CSS file
import { updateSelectedClient } from '../../../state/client/clientSlice';
import { setHomes } from '../../../state/home/homeSlice'; // Redux action to set homes
import { useSelector, useDispatch } from 'react-redux';
import { fetchHomes } from '../../../apiFetch/fetchHomes'; // Function to fetch homes
import { handleSignOut } from '../Settings/handleSignOut';
import InputSlidersMain from '../clientPresentationLayout/PresentMode/inputSliders/inputSlidersMain';
import { useLocation } from 'react-router-dom'; // Detect current route


export const HomesContext = createContext();
export const LayoutContext = createContext();

const ClientLayout = () => {
  const location = useLocation();
  const isPresentationMode = location.pathname.includes("/ClientLayout/PresentationPage");

  // Redux state
  const selectedClient = useSelector((state) => state.client.selected_client_id);
  const homesSlice = useSelector((state) => state.home.homes); // Homes data from Redux
  const user = useSelector((state) => state.user);

  const homes = Object.values(homesSlice);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local state
  const [selectedNav, setSelectedNav] = useState('Dashboard');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 576);
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Fetch current user on mount
  useEffect(() => {
    const fetchCurrentUser = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/');
        return;
      }
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/current_user`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        });
        const userData = await response.json();
        if (response.ok) {
          // Update user state if necessary
        } else {
          navigate('/');
        }
      } catch (error) {
        navigate('/');
      }
    };

    fetchCurrentUser();
  }, [navigate]);

  // Fetch homes for the selected client
  useEffect(() => {
    if (selectedClient) {
      fetchHomes(selectedClient, (homesData) => dispatch(setHomes(homesData)));
    }
  }, [selectedClient, dispatch]);

  // Handle window resize
  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 576);
    if (window.innerWidth > 576) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Toggle fullscreen mode
  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
    if (!isFullscreen) {
      document.documentElement.requestFullscreen().catch(console.error);
    } else {
      document.exitFullscreen().catch(console.error);
    }
  };

  // Sidebar navigation items
  const clientNavItems = [
    {
      icon: <FaChevronLeft className="icon" />,
      text: 'Back',
      to: '/HeaderLayout/Clients',
      onClick: () => dispatch(updateSelectedClient(null)),
    },
    {
      icon: <FaUser className="icon" />,
      text: 'Client',
      to: '/ClientLayout/EditClient',
    },
    {
      icon: <FaUser className="icon" />,
      text: 'Add Home',
      to: '/ClientLayout/AddHome',
    },
    // ...homes.map((home) => ({
    //   icon: <FaHome className="icon" />,
    //   text: home.address,
    //   to: `/ClientLayout/Client/${home.home_id}`,
    // })),
    // {
    //   icon: <FaChartLine className="icon" />,
    //   text: 'Analysis',
    //   to: '/ClientLayout/Analysis',
    // },
    // {
    //   icon: <FaFileAlt className="icon" />,
    //   text: 'Presentation',
    //   to: '/ClientLayout/PresentationPage',
    // },
    {
      icon: <FaFileAlt className="icon" />,
      text: 'Loan Approval Packet',
      to: '/ClientLayout/LoanPacket',
    },
  ];

  return (
    <HomesContext.Provider value={homes}>
      <LayoutContext.Provider value={{ isFullscreen, toggleFullscreen }}>
        <div className={`layout-container ${isFullscreen ? 'fullscreen' : ''}`}>
          {/* Header */}
          <header className="header d-flex justify-content-between align-items-center">
            <div className="logo">
              <img src={`${process.env.PUBLIC_URL}/assets/pf.png`} alt="logo" className="logo-text" />
            </div>
            <div className="user-info d-flex align-items-center">
              <Dropdown>
                <Dropdown.Toggle className="custom-dropdown-toggle">
                  <i className="pi pi-user mr-2"></i> Account
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => navigate('/SettingsLayout/Contact')}>
                    <i className="pi pi-cog mr-2"></i> Settings
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSignOut(dispatch, navigate)}>
                    <i className="pi pi-sign-out mr-2"></i> Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* Fullscreen Toggle Icon */}
              <button
                className="fullscreen-toggle"
                onClick={toggleFullscreen}
                aria-label={isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}
              >
                {isFullscreen ? <FaCompress /> : <FaExpand />}
              </button>
              {isMobileView && (
                <button
                  className="mobile-menu-toggle"
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                >
                  <FaEllipsisV />
                </button>
              )}
            </div>
          </header>
  
          {/* Sidebar */}
          {!isFullscreen && (
            <div className="sidebar">
              <ul className="navbar-nav flex-column">
                {clientNavItems.map((item, index) => (
                  <li className="nav-item" key={index}>
                    <NavLink
                      to={item.to}
                      className="nav-link"
                      onClick={item.onClick || (() => setSelectedNav(item.text))}
                    >
                      {item.icon}
                      <span>{item.text}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
              {isPresentationMode && <InputSlidersMain />}
            </div>
          )}
  
          {/* Main Content */}
          <main className="main-content">
            <Outlet />
          </main>
        </div>
      </LayoutContext.Provider>
    </HomesContext.Provider>
  );
  
};

export default ClientLayout;
