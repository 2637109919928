import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateSavingsInterestRate } from "../../../../../../../../state/client/clientAffordabilitySlice";
import FormattedNumberInput from "../../../../utils/FormattedNumberInput";

const SavingsInterestRateInput = () => {
  const dispatch = useDispatch();

  const savingsInterestRate = useSelector(
    (state) =>
      state.clientAffordability.selected_affordability_details.savings_interest_rate ?? 0
  );

  const [inputValue, setInputValue] = useState(savingsInterestRate.toString());

  useEffect(() => {
    if (savingsInterestRate.toString() !== inputValue) {
      setInputValue(savingsInterestRate.toString());
    }
  }, [savingsInterestRate]);

  const handleChange = (value) => {
    if (value === "" || value === ".") {
      setInputValue(value);
      return;
    }

    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setInputValue(value);
      dispatch(updateSavingsInterestRate(parseFloat(value) || 0));
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="savings-interest-rate">Savings Interest Rate (%):</label>
      <FormattedNumberInput
        id="savings-interest-rate"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter savings interest rate"
        allowDecimals={true}
      />
    </div>
  );
};

export default SavingsInterestRateInput;
