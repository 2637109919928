import React from 'react';
import { useDispatch } from 'react-redux';
import {
  updateHomePrice,
  updateDownPayment,
  updateInterestRate,
  updatePropertyTaxRate,
  updateLoanTermMonths,
  updateHOAFee,
  updatePaymentsPerYear,
  updateHomeInsurance,
  updatePMIrate
} from '../../../../../state/home/homeSlice';

const HomePurchaseDetails = ({ selectedHome }) => {
  const dispatch = useDispatch();

  const {
    home_price,
    down_payment,
    interest_rate,
    property_tax_rate,
    home_insurance_fee,
    term_months,
    payments_per_year,
    hoa,
    pmi_rate
  } = selectedHome || {};

  const convertedYears = term_months ? Math.round(term_months / 12) : 0;

  const labelStyle = {
    width: '180px',
    fontWeight: 'bold',
  };
  
  const rowStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
  };
  
  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '200px'
  };
  
  const inputStyle = {
    padding: '6px 10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    width: '140px',
  };
  
  const symbolSpacer = {
    width: '12px',
    display: 'inline-block',
    textAlign: 'center',
    fontWeight: 'bold',
  };
  

  return (
    <div className="section home-purchase-details">
      <h2>Purchase Details: {selectedHome?.address}</h2>
      {home_price ? (
        <div className="home-purchase-container" style={{ maxWidth: '460px' }}>
          <div className="column">
            {/* Price */}
            <div style={rowStyle}>
              <label style={labelStyle}>Price:</label>
              <div style={inputContainer}>
                <span style={symbolSpacer}>$</span>
                <input
                  type="number"
                  value={home_price}
                  onChange={(e) => dispatch(updateHomePrice(Number(e.target.value)))}
                  style={inputStyle}
                />
                <span style={symbolSpacer} />
              </div>
            </div>
  
            {/* Down Payment */}
            <div style={rowStyle}>
              <label style={labelStyle}>Down Payment:</label>
              <div style={inputContainer}>
                <span style={symbolSpacer}>$</span>
                <input
                  type="number"
                  value={down_payment}
                  onChange={(e) => dispatch(updateDownPayment(Number(e.target.value)))}
                  style={inputStyle}
                />
                <span style={symbolSpacer} />
              </div>
            </div>
  
            {/* Interest Rate */}
            <div style={rowStyle}>
              <label style={labelStyle}>Interest Rate:</label>
              <div style={inputContainer}>
                <span style={symbolSpacer} />
                <input
                  type="number"
                  step="0.01"
                  value={interest_rate}
                  onChange={(e) => dispatch(updateInterestRate(Number(e.target.value)))}
                  style={inputStyle}
                />
                <span style={symbolSpacer}>%</span>
              </div>
            </div>
  
            {/* Property Tax Rate */}
            <div style={rowStyle}>
              <label style={labelStyle}>Property Tax Rate:</label>
              <div style={inputContainer}>
                <span style={symbolSpacer} />
                <input
                  type="number"
                  step="0.01"
                  value={property_tax_rate}
                  onChange={(e) => dispatch(updatePropertyTaxRate(Number(e.target.value)))}
                  style={inputStyle}
                />
                <span style={symbolSpacer}>%</span>
              </div>
            </div>
  
            {/* Home Insurance Fee */}
            <div style={rowStyle}>
              <label style={labelStyle}>Home Insurance Fee:</label>
              <div style={inputContainer}>
                <span style={symbolSpacer}>$</span>
                <input
                  type="number"
                  value={home_insurance_fee}
                  onChange={(e) => dispatch(updateHomeInsurance(Number(e.target.value)))}
                  style={inputStyle}
                />
                <span style={symbolSpacer} />
              </div>
            </div>
  
            {/* PMI Rate */}
            <div style={rowStyle}>
              <label style={labelStyle}>PMI Rate:</label>
              <div style={inputContainer}>
                <span style={symbolSpacer} />
                <input
                  type="number"
                  step="0.01"
                  value={pmi_rate}
                  onChange={(e) => dispatch(updatePMIrate(Number(e.target.value)))}
                  style={inputStyle}
                />
                <span style={symbolSpacer}>%</span>
              </div>
            </div>
  
            {/* Years */}
            <div style={rowStyle}>
              <label style={labelStyle}>Years:</label>
              <div style={inputContainer}>
                <span style={symbolSpacer} />
                <input
                  type="number"
                  value={convertedYears}
                  onChange={(e) => dispatch(updateLoanTermMonths(Number(e.target.value) * 12))}
                  style={inputStyle}
                />
                <span style={symbolSpacer} />
              </div>
            </div>
  
            {/* Payments per Year */}
            <div style={rowStyle}>
              <label style={labelStyle}>Payments per Year:</label>
              <div style={inputContainer}>
                <span style={symbolSpacer} />
                <input
                  type="number"
                  value={payments_per_year}
                  onChange={(e) => dispatch(updatePaymentsPerYear(Number(e.target.value)))}
                  style={inputStyle}
                />
                <span style={symbolSpacer} />
              </div>
            </div>
  
            {/* HOA Fee */}
            <div style={rowStyle}>
              <label style={labelStyle}>HOA Fee:</label>
              <div style={inputContainer}>
                <span style={symbolSpacer}>$</span>
                <input
                  type="number"
                  value={hoa}
                  onChange={(e) => dispatch(updateHOAFee(Number(e.target.value)))}
                  style={inputStyle}
                />
                <span style={symbolSpacer} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>No home selected.</p>
      )}
    </div>
  );
  
};

export default HomePurchaseDetails;
