import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assumptions: {}, // Store assumptions by client_id
  selected_assumptions: {
    current_rent: null,
    annual_rent_increase: 3.5,
    monthly_rent_insurance: null,
    other_monthly_rent_expense: null,
    utilities: null,
  },
};

const clientAssumptionsSlice = createSlice({
  name: "clientAssumptions",
  initialState,
  reducers: {
    setAssumptions: (state, action) => {
      const assumptionsArray = action.payload;
      state.assumptions = assumptionsArray.reduce((acc, item) => {
        acc[item.client_id] = item;
        return acc;
      }, {});
    },
    updateSelectedAssumptions: (state, action) => {
      const clientId = action.payload;
      if (state.assumptions[clientId]) {
        state.selected_assumptions = { ...state.assumptions[clientId] };
      }
    },
    addAssumptionsAndSelect: (state, action) => {
      const newAssumptions = action.payload;
      state.assumptions[newAssumptions.client_id] = newAssumptions; // Add new assumptions to assumptions
      state.selected_assumptions = { ...newAssumptions }; // Set as selected assumptions
    },
    updateCurrentRent: (state, action) => {
      state.selected_assumptions.current_rent = action.payload;
    },
    updateAnnualRentIncrease: (state, action) => {
      state.selected_assumptions.annual_rent_increase = action.payload;
    },
    updateMonthlyRentInsurance: (state, action) => {
      state.selected_assumptions.monthly_rent_insurance = action.payload;
    },
    updateOtherMonthlyRentExpense: (state, action) => {
      state.selected_assumptions.other_monthly_rent_expense = action.payload;
    },
    updateUtilities: (state, action) => {
      state.selected_assumptions.utilities = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setAssumptions,
  updateSelectedAssumptions,
  addAssumptionsAndSelect, 
  updateCurrentRent,
  updateAnnualRentIncrease,
  updateMonthlyRentInsurance,
  updateOtherMonthlyRentExpense,
  updateUtilities,
  reset,
} = clientAssumptionsSlice.actions;

export default clientAssumptionsSlice.reducer;