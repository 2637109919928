import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import { useSelector, useDispatch } from "react-redux";
import { updateExpectedSalaryIncrease } from "../../../../../../state/presentation/adjustInputsSlice";

const ExpectedSalaryIncrease = () => {
  const dispatch = useDispatch();

  // Get expected salary increase from Redux
  const expectedSalaryIncrease = useSelector(
    (state) => state.adjustInputs.generalInputs.expectedSalaryIncrease
  );

  // Local state for smooth UI updates
  const [localValue, setLocalValue] = useState(expectedSalaryIncrease ?? 3); // Default to 3%

  // Sync local state with Redux when Redux value changes
  useEffect(() => {
    if (expectedSalaryIncrease !== null && expectedSalaryIncrease !== undefined) {
      setLocalValue(expectedSalaryIncrease);
    }
  }, [expectedSalaryIncrease]); // ✅ Syncs when Redux state changes

  const handleChange = (event, newValue) => {
    setLocalValue(newValue); // ✅ Update UI immediately
    dispatch(updateExpectedSalaryIncrease(newValue)); // ✅ Persist in Redux
  };

  return (
    <div>
      <label>Expected Salary Increase: {localValue}%</label>
      <Slider
        value={localValue}
        min={0}
        max={20}
        step={0.1}
        onChange={(event, newValue) => handleChange(event, newValue)}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${value}%`}
      />
    </div>
  );
};

export default ExpectedSalaryIncrease;
