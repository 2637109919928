import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './utils/theme';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import ForgotPassword from './modules/authentication/forgotPassword';
import ResetPassword from './modules/authentication/resetPassword';
import RegisterForm from './modules/authentication/register';
import ConfirmEmail from './modules/authentication/confirmEmail';
import HeaderLayout from './modules/HeaderLayout';
import ClientLayout from './modules/pages/clients/ClientLayout';

import Clients from './modules/pages/clients/Clients';
import { DataProvider } from './modules/dataContext';
import { ClientProvider } from './modules/pages/clients/ClientContext';

import LoadingOverlay from './componentsGlobal/LoadingOverlay';
import ConfirmEmailChange from './modules/pages/Settings/ConfirmEmailChange';
import ConfirmEmailChangePending from './modules/pages/Settings/ConfirmEmailChangePending';
import VerificationError from './modules/authentication/VerificationError';
import VerificationSuccess from './modules/authentication/VerificationSuccess';

import WelcomePage from './modules/pages/clientPresentationLayout/stuffNeedOrganize/WelcomePage';
import EditClient from './modules/pages/clientPresentationLayout/onboardingClient/EditClient';
// import HomeDetails from './modules/pages/clientPresentation/HomeClients/HomeDetails';
import AddHomePage from './modules/pages/clientPresentationLayout/stuffNeedOrganize/AddHomePage';
// import AddHomePage from './modules/pages/clientPresentationLayout/onboardingClient/components/UsedInPresentationInfo/OwnInformation/AddHomePage/AddHomePage';

import LoanPacket from './modules/pages/clientPresentationLayout/PresentMode/LoanPacket/LoanPacket';


import Analysis from './modules/pages/clientPresentationLayout/stuffNeedOrganize/Analysis';
import PresentationPage from './modules/pages/clientPresentationLayout/PresentMode/PresentationPage';
import LandingPage from './modules/authentication/LandingPage';
import MainComponent from './modules/authentication/MainComponent';
// import PresentationPreview from './modules/pages/clientPresentationLayout/PresentationPreview';
import Preview from './modules/pages/clientPresentationLayout/stuffNeedOrganize/Preview';
import store from './state/store';
import { Provider } from 'react-redux';
import FAQ from './modules/authentication/Faq';

import SettingsLayout from './modules/pages/Settings/SettingsLayout';
import ProfileSettings from './modules/pages/Settings/ProfileSettings';
import Account from './modules/pages/Settings/Account';
import HelpAndFeedback from './modules/pages/Settings/HelpAndFeedback';
import Privacy from './modules/pages/Settings/Privacy';
import Notifications from './modules/pages/Settings/Notifications';
import ConfirmEmailChangeSuccess from './modules/pages/Settings/ConfirmEmailChangeSuccess';
import ConfirmEmailChangeError from './modules/pages/Settings/ConfirmEmailChangeError';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './theme-overrides.css';
import './App.css';
import Chatbot from './modules/components/Chatbot';
import Contact from './modules/pages/Settings/Contact';
import Images from './modules/pages/Settings/Images';

function App() {
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isSignupOpen, setSignupOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showChatbot, setShowChatbot] = useState(false);

  const openLogin = () => {
    setSignupOpen(false);
    setLoginOpen(true);
  };

  const closeLogin = () => setLoginOpen(false);

  const openSignup = () => {
    setLoginOpen(false);
    setSignupOpen(true);
  };

  const closeSignup = () => setSignupOpen(false);

  // Update login status based on token
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  const handleLoginSuccess = () => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  };

  const AppContent = () => {
    const location = useLocation();

    useEffect(() => {
      const allowedPaths = ['/HeaderLayout', '/ClientLayout', '/SettingsLayout'];
      const shouldShow = allowedPaths.some((path) =>
        location.pathname.startsWith(path)
      );
      console.log('Location:', location.pathname, 'Should show Chatbot:', shouldShow);
      setShowChatbot(isLoggedIn && shouldShow);
    }, [location, isLoggedIn]);

    return (
      <>
        <Routes>
          <Route
            path="/"
            element={<LandingPage openLogin={openLogin} openSignup={openSignup} />}
          />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/verify/success" element={<VerificationSuccess openLogin={openLogin} />} />
          <Route path="/verify/error" element={<VerificationError />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword/:token" element={<ResetPassword />} />
          <Route path="/confirmEmail/:token" element={<ConfirmEmail />} />
          <Route path="/confirmEmailChange/:token" element={<ConfirmEmailChange />} />
          <Route path="/confirmEmailChangePending" element={<ConfirmEmailChangePending />} />
          <Route path="/HeaderLayout" element={<HeaderLayout />}>
            <Route path="EditClient" element={<EditClient />} />
            <Route path="Clients" element={<Clients />} />
          </Route>
          <Route path="/ClientLayout" element={<ClientLayout />}>
            <Route path="WelcomePage" element={<WelcomePage />} />
            <Route path="EditClient" element={<EditClient />} />
            <Route path="AddHome" element={<AddHomePage />} />
            <Route path="Analysis" element={<Analysis />} />
            <Route path="PresentationPage" element={<PresentationPage />} />
            <Route path="LoanPacket" element={<LoanPacket />} />
            {/* <Route path="PresentationPreview" element={<PresentationPreview />} /> */}
            {/* <Route path="Client/:homeId" element={<HomeDetails />} /> */}
          </Route>
          <Route path="/SettingsLayout" element={<SettingsLayout />}>
            <Route path="profile" element={<ProfileSettings />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="Contact" element={<Contact />} />
            <Route path="Images" element={<Images />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="account" element={<Account />} />
            <Route path="help" element={<HelpAndFeedback />} />
          </Route>
          <Route path="/confirmEmailChangeSuccess" element={<ConfirmEmailChangeSuccess />} />
          <Route path="/confirmEmailChangeError" element={<ConfirmEmailChangeError />} />
          <Route path="/preview/:presentationId" element={<Preview />} />
        </Routes>
        {showChatbot && <Chatbot />}
      </>
    );
  };

  return (
    <div className="App">
      <Provider store={store}>
      <LoadingOverlay />
        <DataProvider>
          <ClientProvider>
            <Router>
              <AppContent />
              <MainComponent
                openLogin={openLogin}
                openSignup={openSignup}
                closeLogin={closeLogin}
                closeSignup={closeSignup}
                isLoginOpen={isLoginOpen}
                isSignupOpen={isSignupOpen}
                onLoginSuccess={handleLoginSuccess}
              />
            </Router>
          </ClientProvider>
        </DataProvider>
      </Provider>
    </div>
  );
}

export default App;
