import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import { useSelector, useDispatch } from "react-redux";
import { updateGeneralMonthlyExpenses } from "../../../../../../state/presentation/adjustInputsSlice";

const GeneralMonthlyExpenses = () => {
  const dispatch = useDispatch();

  // Get general monthly expenses from Redux
  const generalMonthlyExpenses = useSelector(
    (state) => state.adjustInputs.generalInputs.generalMonthlyExpenses
  );

  // Local state for smooth UI updates
  const [localValue, setLocalValue] = useState(generalMonthlyExpenses ?? 0);

  // Sync local state with Redux when Redux value changes
  useEffect(() => {
    if (generalMonthlyExpenses !== null && generalMonthlyExpenses !== undefined) {
      setLocalValue(generalMonthlyExpenses);
    }
  }, [generalMonthlyExpenses]);

  const handleChange = (event, newValue) => {
    setLocalValue(newValue); // ✅ Update UI immediately
    dispatch(updateGeneralMonthlyExpenses(newValue)); // ✅ Persist in Redux
  };

  return (
    <div>
      <label>General Monthly Expenses: ${localValue}</label>
      <Slider
        value={localValue}
        min={0}
        max={5000}
        step={10}
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `$${value}`} // Display as currency
      />
    </div>
  );
};

export default GeneralMonthlyExpenses;
