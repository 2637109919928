import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateAnnualRentIncrease } from "../../../../../../../../state/client/clientAssumptionsSlice";
import FormattedNumberInput from "../../../../utils/FormattedNumberInput";

const RentGrowthRateInput = () => {
  const dispatch = useDispatch();

  // Get annual rent increase from Redux state
  const annualRentIncrease = useSelector(
    (state) => state.clientAssumptions.selected_assumptions.annual_rent_increase
  );

  // Handle input change
  const handleChange = (value) => {
    dispatch(updateAnnualRentIncrease(value));
  };

  return (
    <div className="form-group">
      <label htmlFor="annual-rent-increase">Annual Rent Increase (%)</label>
      <FormattedNumberInput
        id="annual-rent-increase"
        value={annualRentIncrease || ""}
        onChange={handleChange}
        placeholder="Enter annual rent increase (%)"
      />
    </div>
  );
};

export default RentGrowthRateInput;
