// import PropTypes from "prop-types";
// import React from "react";
// import "./HorizontalMiddle.css";

// export const HorizontalMiddle = ({
//   className,
//   dividerClassName,
//   divider = "divider.svg",
// }) => {
//   return (
//     <div className={`horizontal-middle ${className}`}>
//       <img
//         className={`divider ${dividerClassName}`}
//         alt="Divider"
//         src={divider}
//       />
//     </div>
//   );
// };

// HorizontalMiddle.propTypes = {
//   divider: PropTypes.string,
// };

import React from "react";
import { View, Image, StyleSheet } from "@react-pdf/renderer";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
  horizontalMiddle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingHorizontal: 16,
    width: 320,
  },
  divider: {
    height: 1,
    width: "100%",
  },
});

export const HorizontalMiddle = ({ divider }) => {
  return (
    <View style={styles.horizontalMiddle}>
      <Image style={styles.divider} src={divider} />
    </View>
  );
};

HorizontalMiddle.propTypes = {
  divider: PropTypes.string,
};

export default HorizontalMiddle;