import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateTaxBracket } from "../../../../../../../../state/client/clientAffordabilitySlice";
import { calculateTaxBracket } from "../../../../../../../../utils/calcUtils/editClientCalc";
import TaxBracketModal from "../components/TaxBracketModal";

const TaxBracketInput = () => {
  const dispatch = useDispatch();
  
  const filingStatus = useSelector(
    (state) => state.clientAffordability.selected_affordability_details.filing_status || ""
  );
  
  const annualIncome = useSelector(
    (state) => state.clientAffordability.selected_affordability_details.current_annual_gross_income || 0
  );

  const standardDeduction = useSelector(
    (state) => state.clientAffordability.selected_affordability_details.standard_deduction ?? 0
  );

  const taxBracket = useSelector(
    (state) => state.clientAffordability.selected_affordability_details.tax_bracket ?? 0
  );

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (filingStatus && annualIncome) {
      const calculatedTaxBracket = calculateTaxBracket(filingStatus, annualIncome);
      dispatch(updateTaxBracket(calculatedTaxBracket));
    }
  }, [filingStatus, annualIncome, dispatch]);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <div className="form-group">
      <label className="tax-bracket-label">
        Tax Bracket (%):
        <span onClick={handleOpenModal} className="info-icon"> ℹ️ </span>
      </label>
      <input
        type="text"
        value={taxBracket}
        readOnly
        className="non-editable-input"
      />
      <TaxBracketModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        filingStatus={filingStatus}
        grossIncome={parseFloat(annualIncome || 0)}
        standardDeduction={parseFloat(standardDeduction || 0)} // ✅ Pass standard deduction
      />
    </div>
  );
};

export default TaxBracketInput;
