import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateGeneralMonthlyExpenses } from '../../../../../../../../state/client/clientGeneralInfoSlice';
import FormattedNumberInput from '../../../../utils/FormattedNumberInput';

const GeneralMonthlyExpensesInput = () => {
  const dispatch = useDispatch();
  const reduxValue = useSelector((state) => state.clientGeneralInfo?.selected_general_info.general_monthly_expenses ?? 0);

  const [inputValue, setInputValue] = useState(reduxValue.toString());

  useEffect(() => {
    if (reduxValue.toString() !== inputValue) {
      setInputValue(reduxValue.toString());
    }
  }, [reduxValue]);

  const handleChange = (value) => {
    if (value === "" || value === ".") {
      setInputValue(value);
      return;
    }

    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setInputValue(value);
      dispatch(updateGeneralMonthlyExpenses(parseFloat(value) || 0 ));
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="general-monthly-expenses">General Monthly Expenses ($):</label>
      <FormattedNumberInput
        id="general-monthly-expenses"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter general monthly expenses"
        allowDecimals={true}
      />
    </div>
  );
};

export default GeneralMonthlyExpensesInput;
