import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const InvestmentBenefit = ({onInvestmentBenefitChange}) => {
  // Accessing data from Redux store
  const homeDetails = useSelector((state) => state.home.selected_home_details);
  const appreciationRate = (homeDetails.appreciation_rate) / 100;

  // Get initial home price and appreciation rate
  const initialHomePrice = homeDetails.home_price;
  const loanAmount = initialHomePrice - homeDetails.down_payment;

  const [investmentData, setInvestmentData] = useState([]);
  const [investmentBenefit, setInvestmentBenefit] = useState(0);

  useEffect(() => {
    // Calculate home value and ROI for each year (1 to 5)
    const data = [];
    for (let year = 1; year <= 5; year++) {
      const homeValue = initialHomePrice * Math.pow(1 + appreciationRate, year);
      const roi = ((homeValue - initialHomePrice) / (initialHomePrice - loanAmount)) * 100; // ROI in percentage
      data.push({
        year,
        homeValue: homeValue.toFixed(2),
        roi: roi.toFixed(2),
      });

      // Set the investment benefit for the first year (Home Value - Initial Home Price)
      if (year === 1) {
        const firstYearHomeValue = homeValue;
        const benefit = firstYearHomeValue - initialHomePrice;
        setInvestmentBenefit(benefit.toFixed(2)); // Setting the first year's investment benefit
      }
    }
    setInvestmentData(data);
  }, [initialHomePrice, loanAmount, appreciationRate]);

    useEffect(() => {
      if (onInvestmentBenefitChange) {
        onInvestmentBenefitChange(investmentBenefit);
      }
    }, [investmentBenefit, onInvestmentBenefitChange]);

  return (
    <div className="section investment-benefit">
      <h2>Investment Benefit</h2>      
      <div>
        <p>Appreciation Rate: {(appreciationRate * 100).toFixed(2)}%</p>
        <p>Down Payment: ${homeDetails.down_payment}</p>
      </div>

      <table>
        <thead>
          <tr>
            <th>Year</th>
            <th>Home Value</th>
            <th>Return on Investment</th>
          </tr>
        </thead>
        <tbody>
          {investmentData.map((data) => (
            <tr key={data.year}>
              <td>{data.year}</td>
              <td>${data.homeValue}</td>
              <td>{data.roi}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvestmentBenefit;
