import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  homes: {}, // Object to store homes with home_id as the key
  selected_client_id: -1,
  selected_home_id: -1,
  selected_home_details: {
    user_id: -1,
    client_id: -1,
    address: '',
    home_price: 0,
    down_payment: 0,
    interest_rate: 0,
    property_tax_rate: 0,
    home_insurance_fee: 0,
    pmi_rate: 0,
    hoa: 0,
    rent_amount_monthly: 0,
    term_months: 360,
    loan_type: '',
    interest_only_months: 0,
    balloon_payment_months: 0,
    utilities: 0,
    other: 0,
    payment_list: [],
    payments_per_year: 12,
    appreciation_rate: 3.5,
  },
  status: 'idle',
  error: null,
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setHomes: (state, action) => {
      const homesArray = action.payload || [];
      state.homes = homesArray.reduce((acc, home) => {
        const key = home.home_id ?? home.id;
        if (key !== undefined) acc[key] = home;
        return acc;
      }, {});
    },
    addHome: (state, action) => {
      const newHome = action.payload;
      const key = newHome.home_id ?? newHome.id;
      if (key !== undefined) {
        state.homes[key] = newHome;
      }
    },
    updateSelectedClient: (state, action) => {
      state.selected_client_id = action.payload;
      state.selected_home_id = -1;
      state.selected_home_details = { ...initialState.selected_home_details };
    },
    updateSelectedHome: (state, action) => {
      const homeId = action.payload;
      if (state.homes[homeId]) {
        state.selected_home_id = homeId;
        state.selected_home_details = { ...state.homes[homeId] };
      } else {
        state.selected_home_id = -1;
        state.selected_home_details = { ...initialState.selected_home_details };
      }
    },
    updateSelectedHomeDetails: (state, action) => {
      state.selected_home_details = action.payload;
    },
    updateHomePrice: (state, action) => {
      state.selected_home_details.home_price = action.payload;
      if (state.selected_home_id in state.homes) {
        state.homes[state.selected_home_id].home_price = action.payload;
      }
    },
    updateDownPayment: (state, action) => {
      state.selected_home_details.down_payment = action.payload;
      if (state.selected_home_id in state.homes) {
        state.homes[state.selected_home_id].down_payment = action.payload;
      }
    },
    updateInterestRate: (state, action) => {
      state.selected_home_details.interest_rate = action.payload;
      if (state.selected_home_id in state.homes) {
        state.homes[state.selected_home_id].interest_rate = action.payload;
      }
    },
    updatePropertyTaxRate: (state, action) => {
      state.selected_home_details.property_tax_rate = action.payload;
      if (state.selected_home_id in state.homes) {
        state.homes[state.selected_home_id].property_tax_rate = action.payload;
      }
    },
    updateSelectedClientForHome: (state, action) => {
      state.selected_client_id = action.payload;
      state.selected_home_id = -1;
      state.selected_home_details = { ...initialState.selected_home_details };
    },
    updateLoanTermMonths: (state, action) => {
      state.selected_home_details.term_months = action.payload; // Update loan term in months
      if (state.selected_home_id in state.homes) {
        state.homes[state.selected_home_id].term_months = action.payload;
      }
    },    
    updateHOAFee: (state, action) => {
      state.selected_home_details.hoa = action.payload; // ✅ Fix: Make sure Redux updates `hoa`
      if (state.selected_home_id in state.homes) {
        state.homes[state.selected_home_id].hoa = action.payload;  // ✅ Ensure stored in Redux
      }
    },    
    updatePaymentsPerYear: (state, action) => {
      state.selected_home_details.payments_per_year = action.payload;
      if (state.selected_home_id in state.homes) {
        state.homes[state.selected_home_id].payments_per_year = action.payload;
      }
    },
    
    updateHome: (state, action) => {
      const { home_id, ...updates } = action.payload; // Extract home_id from the payload
      if (!home_id || !state.homes[home_id]) {
        console.warn(`Home with ID ${home_id} not found.`);
        return;
      }
      // Replace the home entry in the dictionary
      state.homes[home_id] = { ...state.homes[home_id], ...updates };
    },
    updateUtilities: (state, action) => {
      state.selected_home_details.utilities = action.payload;
      if (state.selected_home_id in state.homes) {
        state.homes[state.selected_home_id].utilities = action.payload;
      }
    },
    updateOther: (state, action) => {
      state.selected_home_details.other = action.payload;
      if (state.selected_home_id in state.homes) {
        state.homes[state.selected_home_id].other = action.payload;
      }
    },
    updateAppreciationRate: (state, action) => {
      state.selected_home_details.appreciation_rate = action.payload;
      if (state.selected_home_id in state.homes) {
        state.homes[state.selected_home_id].appreciation_rate = action.payload;
      }
    },
    updateHomeInsurance: (state, action) => {
      state.selected_home_details.home_insurance_fee = action.payload;
      if (state.selected_home_id in state.homes) {
        state.homes[state.selected_home_id].home_insurance_fee = action.payload;
      }
    },
    updatePMIrate: (state, action) => {
      state.selected_home_details.pmi_rate = action.payload;
      if (state.selected_home_id in state.homes) {
        state.homes[state.selected_home_id].pmi_rate = action.payload;
      }
    },
    
    reset: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { 
  setHomes, 
  addHome,
  updateSelectedClient,
  updateSelectedHome, 
  updateSelectedHomeDetails,
  updateHomePrice, 
  updateDownPayment, 
  updateInterestRate, 
  updatePropertyTaxRate,
  updateSelectedClientForHome,
  updateLoanTermMonths,
  updateHOAFee,
  updateHome,
  updateUtilities,
  updateOther,
  updatePaymentsPerYear,
  updateAppreciationRate,
  updateHomeInsurance,
  updatePMIrate,
  reset,
} = homeSlice.actions;

export default homeSlice.reducer;
