import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setHomes, updateSelectedHome } from "../../../../state/home/homeSlice";
import { fetchHomes } from "../../../../apiFetch/fetchHomes";
import IndividualHome from "./IndividualHome";
import CompareMode from "./CompareMode";
import "./PresentationPage.css";
import {
  updateHomePrice,
  updateDownPayment,
  updateLoanTerm,
  updateMortgageRate,
  updatePropertyTaxRate,
  updateHoaFee,
  updateMonthlyRent,
  updateAnnualRentIncrease,
  updateMonthlyRentInsurance,
  updateUtilities,
  updateOtherRentExpenses,
  updateInvestmentGrowth,
  updateInflationRate,
  updateAppreciationRate,
  updateHomeUtilities,
  updateHomeOther,
  updateCurrentAnnualGrossIncome,  
  updateCreditStatus,              
  updateSavingsBalance,            
  updateSavingsInterestRate,      
  updateStandardDeduction,        
  updateTaxBracket,             
  updateFilingStatus,
  updateGeneralMonthlyExpenses,
  updateExpectedSalaryIncrease,
} from "../../../../state/presentation/adjustInputsSlice";


const PresentationPage = () => {
  const dispatch = useDispatch();

  // Memoized selector values to prevent unnecessary re-renders
  const selectedClientId = useSelector((state) => state.client.selected_client_id);
  const selectedHomeId = useSelector((state) => state.home.selected_home_id);

  // Memoize homes list so it doesn't change every render
  const homes = useSelector((state) => Object.values(state.home.homes)).filter(
    (home) => home.client_id === selectedClientId
  );

  // Memoize selected home object to avoid unnecessary renders
  const selectedHome = useMemo(
    () => homes.find((home) => home.home_id === selectedHomeId),
    [homes, selectedHomeId]
  );

  // Memoized original slices for updating adjustInputsSlice
  const affordability = useSelector((state) => state.clientAffordability.selected_affordability_details);
  const assumptions = useSelector((state) => state.clientAssumptions.selected_assumptions);
  const clientGeneralInflationRate = useSelector((state) => state.clientGeneralInfo.inflation_rate);
  const clientGeneralExpectedInvestmentGrowth = useSelector((state) => state.clientGeneralInfo.expected_investment_growth);
  const clientGeneralMonthlyExpenses = useSelector(
    (state) => state.clientGeneralInfo.general_monthly_expenses ?? 0
  );
  const clientGeneralExpectedSalaryIncrease = useSelector(
    (state) => state.clientGeneralInfo.expected_salary_increase ?? 0
  );
  
  
  // Memoized adjusted inputs (this is what gets passed down)
  const ownInputs = useSelector((state) => state.adjustInputs.ownInputs);
  const rentInputs = useSelector((state) => state.adjustInputs.rentInputs);
  const generalInputs = useSelector((state) => state.adjustInputs.generalInputs);

  // Local state for toggling compare mode
  const [compareMode, setCompareMode] = useState(false);

  // Fetch homes when the selected client changes
  useEffect(() => {
    if (selectedClientId) {
      fetchHomes(selectedClientId, (fetchedHomes) => {
        dispatch(setHomes(fetchedHomes));
      });
    }
  }, [selectedClientId, dispatch]);

  // Auto-select the first home if no home is selected and homes are available
  useEffect(() => {
    if (!compareMode && homes.length > 0 && (selectedHomeId === -1 || selectedHomeId === null)) {
      dispatch(updateSelectedHome(homes[0].home_id));
    }
  }, [homes, selectedHomeId, compareMode, dispatch]);

  // 🌟 Update Redux Store (`adjustInputsSlice`) when `selectedHome` or assumptions change
  // 🌟 Update Redux Store (`adjustInputsSlice`) when `selectedHome` or assumptions change
  useEffect(() => {
    if (selectedHome) {
      // ✅ Home Inputs (Own Inputs)
      dispatch(updateHomePrice(selectedHome.home_price || 0));
      dispatch(updateDownPayment(selectedHome.down_payment || 0));
      dispatch(updateLoanTerm(selectedHome.term_months ? selectedHome.term_months : 30)); // Convert to years
      dispatch(updateMortgageRate(selectedHome.interest_rate || 0));
      dispatch(updatePropertyTaxRate(selectedHome.property_tax_rate || 0));
      dispatch(updateHoaFee(selectedHome.hoa || 0));
      dispatch(updateAppreciationRate(3.5));
      dispatch(updateHomeUtilities((selectedHome.utilities || 0)));
      dispatch(updateHomeOther((selectedHome.other || 0)));
    }

  if (assumptions) {
    // ✅ Rent Inputs
    dispatch(updateMonthlyRent(assumptions.current_rent || 0));
    dispatch(updateAnnualRentIncrease(assumptions.annual_rent_increase || 3.5));
    dispatch(updateMonthlyRentInsurance(assumptions.monthly_rent_insurance || 0));
    dispatch(updateOtherRentExpenses(assumptions.other_monthly_rent_expense || 0));
    dispatch(updateUtilities(assumptions.utilities || 0));
  }

  if (affordability) {
    // ✅ Affordability Inputs
    dispatch(updateCurrentAnnualGrossIncome(affordability.current_annual_gross_income || 0));
    dispatch(updateCreditStatus(affordability.credit_status || ""));
    dispatch(updateSavingsBalance(affordability.savings_balance || 0));
    dispatch(updateSavingsInterestRate(affordability.savings_interest_rate || 0));
    dispatch(updateStandardDeduction(affordability.standard_deduction || 0));
    dispatch(updateTaxBracket(affordability.tax_bracket || 0));
    dispatch(updateFilingStatus(affordability.filing_status || ""));
    // dispatch(updateAnnualMcCTaxCredit(affordability.annual_mcc_tax_credit || 0));
    // dispatch(updateMaximumMcCTaxCredit(affordability.maximum_mcc_tax_credit || 0));
  }

  // ✅ General Inputs
  dispatch(updateInvestmentGrowth(clientGeneralExpectedInvestmentGrowth || 0));
  dispatch(updateInflationRate(clientGeneralInflationRate || 0));
  dispatch(updateGeneralMonthlyExpenses(clientGeneralMonthlyExpenses || 0));
  dispatch(updateExpectedSalaryIncrease(clientGeneralExpectedSalaryIncrease || 0));

}, [selectedHome, assumptions, affordability, clientGeneralExpectedInvestmentGrowth, clientGeneralInflationRate, clientGeneralMonthlyExpenses, dispatch]);

  return (
    <div className="presentation-page">
      <div className="controls">
        {!compareMode && (
          <>
            <select
              onChange={(e) => {
                const homeId = parseInt(e.target.value, 10);
                dispatch(updateSelectedHome(homeId));
              }}
              value={selectedHomeId || ""}
            >
              <option value="" disabled>
                Select a Home
              </option>
              {homes.map((home) => (
                <option key={home.home_id} value={home.home_id}>
                  {home.address}
                </option>
              ))}
            </select>
            <button onClick={() => setCompareMode(true)}>Compare Mode</button>
          </>
        )}
      </div>

      {!compareMode ? (
        selectedHome ? (
          <>
            <IndividualHome
              home={selectedHome}
              ownInputs={ownInputs} // ✅ Now using adjusted store
              rentInputs={rentInputs} // ✅ Now using adjusted store
              generalInputs={generalInputs} // ✅ Now using adjusted store
              affordabilityInputs={affordability}
            />
          </>
        ) : (
          <div>Loading home details...</div>
        )
      ) : (
        <CompareMode homes={homes} setCompareMode={setCompareMode} />
      )}
    </div>
  );
};

export default PresentationPage;
