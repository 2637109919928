import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUtilities } from "../../../../../../../../state/client/clientAssumptionsSlice";
import FormattedNumberInput from "../../../../utils/FormattedNumberInput";

const UtilitiesInput = () => {
  const dispatch = useDispatch();

  // Get utilities value from Redux state
  const utilities = useSelector(
    (state) => state.clientAssumptions.selected_assumptions.utilities
  );

  // Handle input change
  const handleChange = (value) => {
    dispatch(updateUtilities(value));
  };

  return (
    <div className="form-group">
      <label htmlFor="utilities">Utilities</label>
      <FormattedNumberInput
        id="utilities"
        value={utilities || ""}
        onChange={handleChange}
        placeholder="Enter utilities amount"
      />
    </div>
  );
};

export default UtilitiesInput;
