import React from "react";
import PropTypes from "prop-types";
import EquityOverTimeChart from "./CostAndEquityCharts/EquityOverTimeChart";
import CostsOverTimeChart from "./CostAndEquityCharts/CostOverTimeChart";
import "./CostAndEquityCharts/CostAndEquityCharts.css"; // Import the CSS file

const CostAndEquityCharts = ({ home, ownInputs, rentInputs, generalInputs, affordabilityInputs }) => {
  if (!home) {
    return <p>No home data available.</p>;
  }

  return (
    <div >
      <div className="chart">
        <h2>Home Ownership Against Renting - Yearly Costs</h2>
        <CostsOverTimeChart 
          home={home} 
          ownInputs={ownInputs} 
          rentInputs={rentInputs} 
          generalInputs={generalInputs} 
          affordabilityInputs={affordabilityInputs}
        />
      </div>
      <div className="chart">
        <h2>Equity Over Time</h2>
        <EquityOverTimeChart 
          home={home} 
          ownInputs={ownInputs} 
          rentInputs={rentInputs} 
          generalInputs={generalInputs} 
          affordabilityInputs={affordabilityInputs}
        />
      </div>

    </div>
  );
};

export default CostAndEquityCharts;
