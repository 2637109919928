import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateDownPayment } from '../../../../../../../../state/home/homeSlice';
import FormattedNumberInput from '../../../../utils/FormattedNumberInput';
import '../styling/DownPaymentInput.css'; // Import CSS for styling

const DownPaymentInput = () => {
  const dispatch = useDispatch();

  // Get values from Redux (always stored as dollars)
  const homePrice = useSelector((state) => state.home.selected_home_details.home_price) || 0;
  const reduxDownPayment = useSelector((state) => state.home.selected_home_details.down_payment);

  // Ensure reduxDownPayment is always treated as a number
  const storedDownPayment = Number(reduxDownPayment) || 0;

  // 🔥 State for display format
  const [isPercentage, setIsPercentage] = useState(false);
  const [inputValue, setInputValue] = useState("");

  // **Set input value correctly based on format (No trailing zeros)**
  useEffect(() => {
    if (isPercentage) {
      const percentage = homePrice ? (storedDownPayment / homePrice) * 100 : 0;
      setInputValue(percentage % 1 === 0 ? percentage.toString() : percentage.toFixed(2).replace(/\.?0+$/, ""));
    } else {
      setInputValue(storedDownPayment % 1 === 0 ? storedDownPayment.toString() : storedDownPayment.toFixed(2).replace(/\.?0+$/, ""));
    }
  }, [storedDownPayment, homePrice, isPercentage]);

  // **Toggle between % and $ while keeping the stored value the same**
  const handleToggle = () => {
    setIsPercentage((prev) => !prev);
  };

  // **Handle user input changes without breaking functionality**
  const handleChange = (value) => {
    if (value === "" || value === ".") {
      setInputValue(value);
      dispatch(updateDownPayment(null)); // ✅ Keep Redux clean
      return;
    }

    let newValue = parseFloat(value);
    if (isNaN(newValue) || newValue < 0) return;

    if (isPercentage) {
      newValue = (newValue / 100) * homePrice; // ✅ Convert % to $
    }

    setInputValue(value); // UI updates smoothly
    dispatch(updateDownPayment(newValue)); // ✅ Always store in dollars
  };

  return (
    <div className="down-payment-container">
      <label className="down-payment-label">Down Payment:</label>

      <div className="down-payment-toggle">
        <input
          type="checkbox"
          id="toggle-down-payment"
          checked={isPercentage}
          onChange={handleToggle}
        />
        <label htmlFor="toggle-down-payment" className="toggle-label">
          {isPercentage ? "%" : "$"}
        </label>
      </div>

      <FormattedNumberInput
        value={inputValue}
        onChange={handleChange}
        placeholder={isPercentage ? "Enter %" : "Enter $"}
      />
    </div>
  );
};

export default DownPaymentInput;
