import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateCurrentRent } from '../../../../../../../../state/client/clientAssumptionsSlice';
import FormattedNumberInput from '../../../../utils/FormattedNumberInput';

const CurrentRentInput = () => {
  const dispatch = useDispatch();
  const currentRent = useSelector(
    (state) => state.clientAssumptions.selected_assumptions.current_rent
  );

  const handleChange = (value) => {
    dispatch(updateCurrentRent(value));
  };

  return (
    <div className="form-group">
      <label htmlFor="current-rent">Current Rent</label>
      <FormattedNumberInput
        id="current-rent"
        value={currentRent || ''}
        onChange={handleChange}
        placeholder="Enter current rent"
      />
    </div>
  );
};

export default CurrentRentInput;
