import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Typography,
  Box,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

// Utility function for currency formatting
const formatCurrency = (value) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(value);

const RentVsOwnTable = ({ ownInputs, rentInputs, generalInputs }) => {
  const [expandedCells, setExpandedCells] = useState({});

  // Extracting values from inputs (default values provided)
  const homePrice = parseFloat(ownInputs?.homePrice) || 500000;
  const downPayment = parseFloat(ownInputs?.downPayment) || 100000;
  const loanTerm = parseFloat(ownInputs?.loanTerm) || 30;
  const mortgageRate = parseFloat(ownInputs?.mortgageRate) || 4.5;
  const homeAppreciationRate = parseFloat(ownInputs?.homeAppreciationRate) || 4;

  const monthlyRent = parseFloat(rentInputs?.monthlyRent) || 3500;
  const annualRentIncrease = parseFloat(rentInputs?.annualRentIncrease) || 3.5;

  const sp500GrowthRate = parseFloat(generalInputs?.investmentGrowth) || 7;

  // Calculation Functions
  const calculateFutureRentExpenses = (rentAmount, rentIncrease, years) => {
    return 12 * rentAmount * ((Math.pow(1 + rentIncrease / 100, years) - 1) / (rentIncrease / 100));
  };

  const calculateMonthlyMortgagePayment = (loanAmount, interestRate, termMonths) => {
    const monthlyRate = interestRate / 12 / 100;
    return (loanAmount * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -termMonths));
  };

  const calculateOutstandingLoanBalance = (loanAmount, interestRate, termMonths, elapsedMonths) => {
    const monthlyRate = interestRate / 12 / 100;
    if (elapsedMonths >= termMonths) return 0;

    const monthlyPayment = calculateMonthlyMortgagePayment(loanAmount, interestRate, termMonths);
    return (
      loanAmount * Math.pow(1 + monthlyRate, elapsedMonths) -
      monthlyPayment * ((Math.pow(1 + monthlyRate, elapsedMonths) - 1) / monthlyRate)
    );
  };

  const toggleCell = (id) => {
    setExpandedCells((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const specificYears = [1, 5, 10, 15, 20, 25, 30];
  const loanAmount = homePrice - downPayment;
  const termMonths = loanTerm * 12;

  const comparisonResults = specificYears.map((year) => {
    const elapsedMonths = year * 12;
    const homeValue = homePrice * Math.pow(1 + homeAppreciationRate / 100, year);
    const outstandingLoanBalance = calculateOutstandingLoanBalance(loanAmount, mortgageRate, termMonths, elapsedMonths);
    const rentExpense = calculateFutureRentExpenses(monthlyRent, annualRentIncrease, year);
    const sp500Value = downPayment * Math.pow(1 + sp500GrowthRate / 100, year);
    const netWealth = homeValue - outstandingLoanBalance;

    return {
      years: year,
      rentExpense,
      netWealth,
      sp500Value,
      details: {
        homeValue,
        outstandingLoanBalance,
        rentIncrease: annualRentIncrease,
        appreciationRate: homeAppreciationRate,
      },
    };
  });

  return (
    <TableContainer component={Paper} sx={{ maxWidth: 900, margin: "auto", mt: 3 }}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
            <TableCell sx={{ fontWeight: "bold" }}>Years</TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "red" }}>Future Total Rent Expenses</TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "blue" }}>Own Net Wealth</TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "green" }}>SP500 Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {comparisonResults.map((row) => (
            <React.Fragment key={row.years}>
              <TableRow>
                <TableCell>{row.years}</TableCell>

                {/* Rent Expense Cell */}
                <TableCell sx={{ color: "red", cursor: "pointer" }} onClick={() => toggleCell(`rent-${row.years}`)}>
                  <IconButton size="small">{expandedCells[`rent-${row.years}`] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
                  {formatCurrency(row.rentExpense)}
                </TableCell>

                {/* Net Wealth Cell */}
                <TableCell sx={{ color: "blue", cursor: "pointer" }} onClick={() => toggleCell(`wealth-${row.years}`)}>
                  <IconButton size="small">{expandedCells[`wealth-${row.years}`] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
                  {formatCurrency(row.netWealth)}
                </TableCell>

                {/* SP500 Value Cell */}
                <TableCell sx={{ color: "green", cursor: "pointer" }} onClick={() => toggleCell(`sp500-${row.years}`)}>
                  <IconButton size="small">{expandedCells[`sp500-${row.years}`] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
                  {formatCurrency(row.sp500Value)}
                </TableCell>
              </TableRow>

              {/* Rent Expense Details */}
              {expandedCells[`rent-${row.years}`] && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Collapse in={expandedCells[`rent-${row.years}`]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Typography variant="body2">Rent Increase Rate: {row.details.rentIncrease}% per year</Typography>
                        <Typography variant="body2">Total Rent Paid: {formatCurrency(row.rentExpense)}</Typography>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}

              {/* Net Wealth Details */}
              {expandedCells[`wealth-${row.years}`] && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Collapse in={expandedCells[`wealth-${row.years}`]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Typography variant="body2">Future Home Value: {formatCurrency(row.details.homeValue)}</Typography>
                        <Typography variant="body2">Outstanding Loan Balance: {formatCurrency(row.details.outstandingLoanBalance)}</Typography>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RentVsOwnTable;
