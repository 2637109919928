import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchClientAffordability, updateClientAffordabilityFetch } from "./fetches/fetchClientAffordability";
import { updateClientAffordability } from "./fetches/updateClientAffordability";
import { setAffordability, updateSelectedAffordability } from "../../../../../../../state/client/clientAffordabilitySlice";
import AnnualGrossIncomeInput from "./formInputs/AnnualGrossIncomeInput";
import FilingStatusInput from "./formInputs/FilingStatusInput";
import StandardDeductionInput from "./formInputs/StandardDeductionInput";
import TaxBracketInput from "./formInputs/TaxBracketInput";
import SavingBalanceInput from "./formInputs/SavingBalanceInput";
import SavingsInterestRateInput from "./formInputs/SavingsInterestRateInput";
import FicoScoreInput from "./formInputs/FicoScoreInput";

const AffordabilityMain = () => {
    const dispatch = useDispatch();
    const clientId = useSelector((state) => state.client.selected_client_id);
    const affordability = useSelector((state) => state.clientAffordability.selected_affordability_details);

    useEffect(() => {
        if (clientId !== -1) {
            fetchClientAffordability(clientId).then((data) => {
                if (data) {
                    dispatch(setAffordability([data])); // Ensure Redux gets data properly
                    dispatch(updateSelectedAffordability(clientId)); // Update selected
                }
            });
        }
    }, [clientId, dispatch]);

    const handleSave = async () => {
        console.log("💾 Saving Affordability Data:", affordability);
        const success = await updateClientAffordability(clientId, affordability);
        if (success) {
            alert("✅ Affordability data saved!");
        } else {
            alert("❌ Failed to save affordability data.");
        }
    };
    
    

    return (
        <div className="affordability-main">
            <h2>Affordability Information</h2>
            <form>
                <AnnualGrossIncomeInput />
                <FilingStatusInput />
                <StandardDeductionInput />
                <TaxBracketInput />
                <SavingBalanceInput />
                <SavingsInterestRateInput />
                <FicoScoreInput />
                <button type="button" onClick={handleSave} className="save-button">
                    Save Affordability Data
                </button>
            </form>
        </div>
    );
};

export default AffordabilityMain;
