export const getTaxRate = (filingStatus, income) => {
  const taxBrackets = {
    single: [
      { min: 0, max: 9875, rate: 10 },
      { min: 9876, max: 40125, rate: 12 },
      { min: 40126, max: 85525, rate: 22 },
      { min: 85526, max: 163300, rate: 24 },
      { min: 163301, max: 207350, rate: 32 },
      { min: 207351, max: 518400, rate: 35 },
      { min: 518401, max: Infinity, rate: 37 },
    ],
    married_filing_jointly: [
      { min: 0, max: 19750, rate: 10 },
      { min: 19751, max: 80250, rate: 12 },
      { min: 80251, max: 171050, rate: 22 },
      { min: 171051, max: 326600, rate: 24 },
      { min: 326601, max: 414700, rate: 32 },
      { min: 414701, max: 622050, rate: 35 },
      { min: 622051, max: Infinity, rate: 37 },
    ],
    married_filing_separately: [
      { min: 0, max: 9875, rate: 10 },
      { min: 9876, max: 40125, rate: 12 },
      { min: 40126, max: 85525, rate: 22 },
      { min: 85526, max: 163300, rate: 24 },
      { min: 163301, max: 207350, rate: 32 },
      { min: 207351, max: 311025, rate: 35 },
      { min: 311026, max: Infinity, rate: 37 },
    ],
    head_of_household: [
      { min: 0, max: 15900, rate: 10 },
      { min: 15901, max: 59850, rate: 12 },
      { min: 59851, max: 95350, rate: 22 },
      { min: 95351, max: 182100, rate: 24 },
      { min: 182101, max: 231250, rate: 32 },
      { min: 231251, max: 578100, rate: 35 },
      { min: 578101, max: Infinity, rate: 37 },
    ],
  };

  const brackets = taxBrackets[filingStatus];

  // ✅ Convert `income` to a number if it's not already
  const incomeNumber = typeof income === "string" ? parseFloat(income.replace(/,/g, "")) : Number(income);

  if (!incomeNumber || isNaN(incomeNumber) || !brackets) {
    return null;
  }

  for (let i = brackets.length - 1; i >= 0; i--) {
    if (incomeNumber >= brackets[i].min) {
      return brackets[i].rate;
    }
  }
  return null;
};
