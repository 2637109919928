import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Default to localStorage for web
import { combineReducers } from 'redux';

import homeReducer from './home/homeSlice';
import userReducer from './user/userSlice';
import userContactReducer from './user/userContactSlice';
import clientsReducer from './client/clientSlice';
import clientContactReducer from './client/clientContactSlice';
import clientAffordabilityReducer from './client/clientAffordabilitySlice';
import clientInfoReducer from './client/clientInfoSlice';
import clientGoalsReducer from './client/clientGoalsSlice';
import clientExpenditureReducer from './client/clientExpenditureSlice';
import clientAssumptionsReducer from './client/clientAssumptionsSlice';
import clientGeneralInfoReducer from './client/clientGeneralInfoSlice';

import loadingOverlayReducer from './loading/loadingSlice';
import presentationCompareReducer from './presentation/presentationCompareSlice';
import adjustInputsReducer from './presentation/adjustInputsSlice';


// import rentReducer from './calculations/rentSlice'

import clientMiddleware from '../middleware/onUpdateSelectedClient';
import homeMiddleware from '../middleware/onUpdateClientIDUpdateHomes';
import userContactMiddleware from '../middleware/onUpdateUserContact';
import clientMiddlewareRest from '../middleware/onUpdateSelectedClientRest';

// Combine all reducers into a root reducer
const rootReducer = combineReducers({
  user: userReducer,
  userContact: userContactReducer,
  home: homeReducer,
  client: clientsReducer,
  clientContact: clientContactReducer,
  clientAffordability: clientAffordabilityReducer,
  clientGoals: clientGoalsReducer,
  clientInfo: clientInfoReducer,
  clientAssumptions: clientAssumptionsReducer,
  clientExpenditure: clientExpenditureReducer,
  loading: loadingOverlayReducer,
  presentationCompare: presentationCompareReducer,
    clientGeneralInfo: clientGeneralInfoReducer,
    adjustInputs: adjustInputsReducer,
});

// Configure persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'user',
    'userContact',
    'home',
    'client',
    'clientContact',
    'clientAffordability',
    'clientGoals',
    'clientInfo',
    'clientAssumptions',
    'clientExpenditure',
    'presentationCompare',
  ], // Only reducers in this list will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable checks for redux-persist
    }).concat(clientMiddleware, userContactMiddleware), // homeMiddleware,
});

// Create a persistor
export const persistor = persistStore(store);

export default store;

/*
IF YOU FORGOT WHATS LOCATED IN EACH SLICE, ITS ALL HERE BELOW:
UPDATE AS YOU GO!!!!!!!!

CLIENT SLICE:

  clients: {}, // Store clients as an object keyed by `client_id`
  selected_client_id: null, // Tracks the currently selected client by `client_id`
  selected_client_details: {
    first_name: '',
    last_name: '',
    middle_name: '',
    date_of_birth: null,
    referred_by: '',
    email: '',
    created_at: null,
    updated_at: null,
  },

CLIENT INFO SLICE:
  clientInfo: {}, // All client info stored by `client_id`
  selected_client_info: {
    client_id: -1,
    presentation_type: "",
    strategy_template: "",
    client_type: "",
    rent_vs_own: "",
    referred_by: "",
    partner_email: "",
    presentation_name: "",
    co_borrower_first_name: "",
    co_borrower_last_name: "",
    co_borrower_dob: "",
    current_address_street: "",
    current_address_city: "",
    current_address_state: "",
    current_address_zip: "",
    current_estimated_home_value: 0,
    is_first_time_buyer: false,
    partner_phone: "",
  },

  CLIENT GENERAL INFO SLICE:

  const initialState = {
    client_id: -1, // Ensure it exists from the start
    inflation_rate: 0,
    expected_investment_growth: 0,
};


CLIENT EXPENDITURE SLICE:
  expenditure: {}, // Store expenditure by client_id
  selected_client_id: null, // Track the currently selected client ID
  selected_expenditure: {
    maximum_mortgage_payment: null,
    ideal_mortgage_payment: null,
    price_range_low: null,
    price_range_high: null,
  },

CLIENT CONTACT SLICE:
  contactInfo: {}, // All contact info by `client_id`
  selected_contact_details: {
    home_phone: '',
    cell_phone: '',
    fax: '',
    mailing_street: '',
    mailing_city: '',
    mailing_state: '',
    mailing_zip: '',
  },


  CLIENT ASSUMPTIONS SLICE:
    assumptions: {}, // Store assumptions by client_id
  selected_assumptions: {
    current_rent: null,
    annual_rent_increase: 3.5,
    monthly_rent_insurance: null,
    other_monthly_rent_expense: null,
    utilities: null,
  },

CLIENT AFFORDABILITY SLICE:
  affordability: {}, // All affordability info by `client_id`
  selected_client_id: null, // Track the currently selected client ID
  selected_affordability_details: {
    id: null,
    client_id: null,
    current_annual_gross_income: null,
    credit_status: '',
    savings_balance: null,
    savings_interest_rate: null,
    standard_deduction: null,
    tax_bracket: null,
    filing_status: '',
    annual_mcc_tax_credit: 0,
    maximum_mcc_tax_credit: 0,
  },


  HOME SLICE:
  const initialState = {
  homes: {}, // Object to store homes with home_id as the key
  selected_client_id: -1,
  selected_home_id: -1,
  selected_home_details: {
    user_id: -1,
    client_id: -1,
    address: '',
    home_price: 0,
    down_payment: 0,
    interest_rate: 0,
    property_tax_rate: 0,
    home_insurance_fee: 0,
    pmi_rate: 0,
    hoa: 0,
    rent_amount_monthly: 0,
    term_months: 360,
    loan_type: '',
    interest_only_months: 0,
    balloon_payment_months: 0,
    payment_list: [],
  },
  status: 'idle',
  error: null,
};


  */
