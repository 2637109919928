import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import { useSelector, useDispatch } from "react-redux";
import { updateInvestmentGrowth } from "../../../../../../state/presentation/adjustInputsSlice";

const ExpectedInvestmentGrowth = () => {
  const dispatch = useDispatch();

  // Get expected investment growth from Redux (adjustInputsSlice)
  const expectedInvestmentGrowth = useSelector(
    (state) => state.adjustInputs.generalInputs.investmentGrowth
  );

  // Local state for smooth UI updates
  const [localValue, setLocalValue] = useState(expectedInvestmentGrowth ?? 10); // Default to 5%

  // Sync local state with Redux when Redux value changes
  useEffect(() => {
    if (expectedInvestmentGrowth !== null && expectedInvestmentGrowth !== undefined) {
      setLocalValue(expectedInvestmentGrowth);
    }
  }, [expectedInvestmentGrowth]); // ✅ Syncs when Redux state changes

  const handleChange = (event, newValue) => {
    setLocalValue(newValue); // ✅ Update UI immediately
    dispatch(updateInvestmentGrowth(newValue)); // ✅ Persist in Redux
  };

  return (
    <div>
      <label>Expected Investment Growth: {localValue}%</label>
      <Slider
        value={localValue}
        min={0}
        max={20}
        step={0.1}
        onChange={(event, newValue) => handleChange(event, newValue)}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${value}%`}
      />
    </div>
  );
};

export default ExpectedInvestmentGrowth;
