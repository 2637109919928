import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { useSelector, useDispatch } from 'react-redux';
import { updateAnnualRentIncrease } from '../../../../../../state/presentation/adjustInputsSlice';

const RentGrowthRate = () => {
  const dispatch = useDispatch();

  // Fetch annual rent increase from Redux (adjustInputsSlice)
  const annualRentIncrease = useSelector(
    (state) => state.adjustInputs.rentInputs.annualRentIncrease
  );

  // Initialize local state with Redux value or default to 3.5%
  const [localValue, setLocalValue] = useState(annualRentIncrease ?? 3.5);

  // Only initialize local state on mount, preventing resets from other sliders
  useEffect(() => {
    if (annualRentIncrease !== null && annualRentIncrease !== undefined) {
      setLocalValue(annualRentIncrease);
    }
  }, []); // ✅ Empty dependency array prevents resets

  const handleChange = (event, newValue) => {
    setLocalValue(newValue); // Ensure UI updates smoothly
    dispatch(updateAnnualRentIncrease(newValue)); // ✅ Update Redux state
  };

  return (
    <div>
      <label>Annual Rent Increase: {localValue}%</label>
      <Slider
        value={localValue}
        min={0}
        max={10}
        step={0.1}
        onChange={(event, newValue) => handleChange(event, newValue)}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${value}%`} // Show percentage on the slider
      />
    </div>
  );
};

export default RentGrowthRate;
