import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { useSelector, useDispatch } from 'react-redux';
import { updateOtherRentExpenses } from '../../../../../../state/presentation/adjustInputsSlice';

const OtherRentExpensesInput = () => {
  const dispatch = useDispatch();

  // Get other rent expenses from Redux state (adjustInputsSlice)
  const otherRentExpenses = useSelector(
    (state) => state.adjustInputs.rentInputs.otherRentExpenses
  );

  // Initialize local state from Redux, defaulting to $50 if null
  const [localValue, setLocalValue] = useState(otherRentExpenses ?? 50);

  // Only initialize local state on mount, but avoid resetting it on every Redux update
  useEffect(() => {
    if (otherRentExpenses !== null && otherRentExpenses !== undefined) {
      setLocalValue(otherRentExpenses);
    }
  }, []); // ✅ Empty dependency array prevents unnecessary resets

  const handleChange = (event, newValue) => {
    setLocalValue(newValue); // Keep UI responsive
    dispatch(updateOtherRentExpenses(newValue)); // ✅ Dispatch update to Redux
  };

  return (
    <div>
      <label>Other Monthly Rent Expenses: ${localValue}</label>
      <Slider
        value={localValue}
        min={0}
        max={500}
        step={10}
        onChange={(event, newValue) => handleChange(event, newValue)}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `$${value}`} // Format value as currency
      />
    </div>
  );
};

export default OtherRentExpensesInput;
