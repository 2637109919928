import React from "react";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { calculateAmortization } from "../../calculations/presentModeCalculations/amortizationSchedule";

const EquityOverTimeChart = ({ ownInputs, rentInputs, generalInputs }) => {
  const years = ownInputs.loanTerm / 12 || 30; // Default to 30 years

  const {
    homePrice,
    downPayment,
    loanTerm,
    mortgageRate,
    appreciationRate,
    propertyTaxRate,
    hoaFee,
    homeUtilities,
    homeOther,
  } = ownInputs;

  const { monthlyRent, annualRentIncrease, monthlyRentInsurance, utilities, otherRentExpenses } = rentInputs;

  const { investmentGrowth } = generalInputs;

  const loanAmount = homePrice - downPayment;

  let renterInvestment = downPayment; // Initial investment
  let homeValue = homePrice; // Home value starts at purchase price
  let remainingMortgageBalance = loanAmount; // Initial mortgage balance

  const data = Array.from({ length: years }, (_, i) => {
    const year = i + 1;
    const isMortgagePaidOff = year * 12 > loanTerm;  // Mortgage ends after loanTerm years

    // **Mortgage Balance Reduction & Principal Paydown**
    const amortization = isMortgagePaidOff
      ? { remainingBalance: 0, principalPaid: 0 }
      : calculateAmortization(year * 12, loanAmount, mortgageRate, loanTerm) || {};
    
    remainingMortgageBalance = amortization.remainingBalance;

    // **Home Price Appreciation**
    homeValue *= (1 + appreciationRate / 100); // Home value grows by appreciation rate

    // **Home Equity Calculation**
    const homeEquity = homeValue - remainingMortgageBalance;

    // **Renting vs Owning Cost Difference**
    const yearlyRent = monthlyRent * 12 * Math.pow(1 + annualRentIncrease / 100, i);
    const yearlyHomeCosts =
      (isMortgagePaidOff ? 0 : amortization.monthlyPayment * 12) + // Mortgage stops after loan term
      (propertyTaxRate / 100) * homeValue +
      hoaFee * 12 +
      homeUtilities * 12 +
      homeOther * 12;

    const costDifference = Math.max(0, yearlyHomeCosts - yearlyRent); // If renting is cheaper, invest the difference

    // **Renter’s Investment Growth**
    renterInvestment = renterInvestment * (1 + investmentGrowth / 100) + costDifference; // Market growth + new investments

    return {
      year,
      homeEquity,
      renterInvestment,
    };
  });

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" label={{ value: "Years", position: "insideBottom", offset: -5 }} />
        <YAxis label={{ value: "Wealth ($)", angle: -90, position: "insideLeft" }} />
        <Tooltip />
        <Line type="monotone" dataKey="homeEquity" stroke="#8884d8" strokeWidth={2} name="Homeowner Equity" />
        <Line type="monotone" dataKey="renterInvestment" stroke="#82ca9d" strokeWidth={2} name="Renter's S&P 500 Investment" />
      </LineChart>
    </ResponsiveContainer>
  );
};

EquityOverTimeChart.propTypes = {
  ownInputs: PropTypes.shape({
    homePrice: PropTypes.number.isRequired,
    downPayment: PropTypes.number.isRequired,
    loanTerm: PropTypes.number.isRequired,
    mortgageRate: PropTypes.number.isRequired,
    appreciationRate: PropTypes.number.isRequired,
    propertyTaxRate: PropTypes.number.isRequired,
    hoaFee: PropTypes.number.isRequired,
    homeUtilities: PropTypes.number.isRequired,
    homeOther: PropTypes.number.isRequired,
  }).isRequired,
  rentInputs: PropTypes.shape({
    monthlyRent: PropTypes.number.isRequired,
    annualRentIncrease: PropTypes.number.isRequired,
    monthlyRentInsurance: PropTypes.number.isRequired,
    utilities: PropTypes.number.isRequired,
    otherRentExpenses: PropTypes.number.isRequired,
  }).isRequired,
  generalInputs: PropTypes.shape({
    investmentGrowth: PropTypes.number.isRequired,
  }).isRequired,
};

export default EquityOverTimeChart;
