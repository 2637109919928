// import React from "react";
// import { HorizontalMiddle } from "./HorizontalMiddle";
// import "./HomePurchase.css";

// export const HomePurchase = () => {
//   return (
//     <div className="home-purchase">
//       <div className="text-wrapper">Home Purchase Details</div>

//       <div className="overlap-group">
//         <HorizontalMiddle
//           className="horizontal-middle-inset"
//           divider="image.svg"
//           dividerClassName="horizontal-middle-instance"
//         />
//         <div className="flexcontainer">
//           <p className="text">
//             <span className="span">Price: $800,000 <br /></span>
//           </p>

//           <p className="text">
//             <span className="span">Down Payment: $40,000 <br /></span>
//           </p>

//           <p className="text">
//             <span className="span">Loan Amount: $760,000 <br /></span>
//           </p>

//           <p className="text">
//             <span className="span">Interest Rate: 6.88% <br /></span>
//           </p>

//           <p className="text">
//             <span className="span">Property Tax Rate: 1.25% <br /></span>
//           </p>

//           <p className="text">
//             <span className="span">Home Insurance: $150 <br /></span>
//           </p>

//           <p className="text">
//             <span className="span">PMI: 0.21% HOA: $150</span>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };


import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { HorizontalMiddle } from "./HorizontalMiddle";

const styles = StyleSheet.create({
  homePurchase: {
    height: 136,
    overflow: "hidden",
    position: "relative",
    width: 173,
    padding: 10,
  },
  textWrapper: {
    color: "#243144",
    fontSize: 12,
    fontWeight: "semibold",
    position: "absolute",
    top: -1,
    width: 257,
  },
  overlapGroup: {
    height: 105,
    position: "absolute",
    top: 17,
    width: 273,
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    height: 105,
    position: "absolute",
    top: 0,
    width: 257,
  },
  separator: {
    alignSelf: "stretch",
    height: 0,
    backgroundColor: "#D37F51",
    borderBottomWidth: 1,
    borderBottomColor: "#D37F51",
    position: "absolute",
    left: 6,
    top: 14, 
    width: 146,
  },
  text: {
    fontSize: 10,
    color: "#000000",
  },
  span: {
    fontSize: 10,
    color: "#000000",
  },
});

export const HomePurchase = () => {
  return (
    <View style={styles.homePurchase}>
      <Text style={styles.textWrapper}>Home Purchase Details</Text>
      <View style={styles.separator} />
      <View style={styles.overlapGroup}>
        
        <View style={styles.flexContainer}>
          <Text style={styles.text}><Text style={styles.span}>Price: $800,000</Text></Text>
          <Text style={styles.text}><Text style={styles.span}>Down Payment: $40,000</Text></Text>
          <Text style={styles.text}><Text style={styles.span}>Loan Amount: $760,000</Text></Text>
          <Text style={styles.text}><Text style={styles.span}>Interest Rate: 6.88%</Text></Text>
          <Text style={styles.text}><Text style={styles.span}>Property Tax Rate: 1.25%</Text></Text>
          <Text style={styles.text}><Text style={styles.span}>Home Insurance: $150</Text></Text>
          <Text style={styles.text}><Text style={styles.span}>PMI: 0.21% HOA: $150</Text></Text>
        </View>
      </View>
    </View>
  );
};

export default HomePurchase;
