import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateOtherMonthlyRentExpense } from "../../../../../../../../state/client/clientAssumptionsSlice";
import FormattedNumberInput from "../../../../utils/FormattedNumberInput";

const OtherRentExpensesInput = () => {
  const dispatch = useDispatch();

  // Get other rent expenses value from Redux state
  const otherRentExpenses = useSelector(
    (state) => state.clientAssumptions.selected_assumptions.other_monthly_rent_expense
  );

  // Handle input change
  const handleChange = (value) => {
    dispatch(updateOtherMonthlyRentExpense(value));
  };

  return (
    <div className="form-group">
      <label htmlFor="other-rent-expenses">Other Monthly Rent Expenses</label>
      <FormattedNumberInput
        id="other-rent-expenses"
        value={otherRentExpenses || ""}
        onChange={handleChange}
        placeholder="Enter other monthly rent expenses"
      />
    </div>
  );
};

export default OtherRentExpensesInput;
