import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { useSelector, useDispatch } from 'react-redux';
import { updateHoaFee } from '../../../../../../state/presentation/adjustInputsSlice';

const MonthlyHOAFeeInput = () => {
  const dispatch = useDispatch();

  // Get HOA fee from Redux (adjustInputsSlice)
  const hoaFee = useSelector((state) => state.adjustInputs.ownInputs.hoaFee);

  // Local state for smooth UI updates
  const [localValue, setLocalValue] = useState(hoaFee ?? 250); // Default to $250

  // Sync local state with Redux when Redux value changes
  useEffect(() => {
    if (hoaFee !== null && hoaFee !== undefined) {
      setLocalValue(hoaFee);
    }
  }, [hoaFee]); // ✅ Syncs when Redux state changes

  const handleChange = (event, newValue) => {
    setLocalValue(newValue); // ✅ Update UI immediately
    dispatch(updateHoaFee(newValue)); // ✅ Persist in Redux
  };

  return (
    <div>
      <label>Monthly HOA Fee: ${localValue}</label>
      <Slider
        value={localValue}
        min={0} // Minimum HOA fee is $0
        max={1000} // Maximum HOA fee is $1,000
        step={10} // Increment by $10
        onChange={(event, newValue) => handleChange(event, newValue)}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `$${value}`} // Display dollar amount when hovering
      />
    </div>
  );
};

export default MonthlyHOAFeeInput;
