import { setLoading } from '../state/loading/loadingSlice';
import { updateSelectedClient } from '../state/client/clientSlice';

import { fetchClientInfoBatch } from '../apiFetch/fetchClientInfoBatch';

import { addClientInfoAndSelect } from '../state/client/clientInfoSlice';
import { addContactAndSelect } from '../state/client/clientContactSlice';
import { addExpenditureAndSelect } from '../state/client/clientExpenditureSlice';
import { addAssumptionsAndSelect } from '../state/client/clientAssumptionsSlice';
import { addAffordabilityAndSelect } from '../state/client/clientAffordabilitySlice';

const clientMiddleware = (store) => (next) => async (action) => {
  if (action.type === updateSelectedClient.type) {
    const clientId = action.payload;

    if (clientId) {
      try {
        // Start loading
        store.dispatch(setLoading(true));

        // Fetch all client data in batch
        await fetchClientInfoBatch(clientId, (clientInfo) => {
          if (clientInfo) {
            console.log("ON UPDATE CLIENT MIDDLEWARE: ", clientInfo);

            // Dispatch each part of the client info correctly
            if (clientInfo.info && clientInfo.info.length > 0) {
              store.dispatch(addClientInfoAndSelect(clientInfo.info[0])); // Use first object
            }
            if (clientInfo.contact && clientInfo.contact.length > 0) {
              store.dispatch(addContactAndSelect(clientInfo.contact[0])); // Use first object
            }
            if (clientInfo.expenditure && clientInfo.expenditure.length > 0) {
              store.dispatch(addExpenditureAndSelect(clientInfo.expenditure[0])); // Use first object
            }
            if (clientInfo.assumptions && clientInfo.assumptions.length > 0) {
              store.dispatch(addAssumptionsAndSelect(clientInfo.assumptions[0])); // Use first object
            }
            if (clientInfo.affordability && clientInfo.affordability.length > 0) {
              store.dispatch(addAffordabilityAndSelect(clientInfo.affordability[0])); // Use first object
            }
          }
        });
      } catch (error) {
        console.error('Middleware: Error fetching client batch data:', error);
      } finally {
        // Stop loading
        store.dispatch(setLoading(false));
        store.dispatch(setLoading(false));
      }
    }
  }

  return next(action);
};

export default clientMiddleware;