// src/utils/fetchClientInfoBatch.js
export const fetchClientInfoBatch = async (clientId, setClientInfo) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log('No token found, please log in.');
      return;
    }
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/get_client_info_batch/${clientId}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
  
      const result = await response.json();
  
      if (response.ok) {
        console.log('Fetched Client Info Batch:', result); // Log fetched client info batch
        setClientInfo(result); // Pass the client data to the callback function
      } else {
        console.error(`Error fetching client info batch: ${result.status}`);
        setClientInfo(null); // Clear client info on error
      }
    } catch (error) {
      console.error(`An error occurred while fetching client info batch: ${error.message}`);
      setClientInfo(null); // Clear client info on error
    }
  };
  