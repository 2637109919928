import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { useSelector, useDispatch } from 'react-redux';
import { updatePropertyTaxRate } from '../../../../../../state/presentation/adjustInputsSlice';

const PropertyTaxRateInput = () => {
  const dispatch = useDispatch();

  // Get property tax rate from Redux (adjustInputsSlice)
  const propertyTaxRate = useSelector((state) => state.adjustInputs.ownInputs.propertyTaxRate);

  // Local state for smooth UI updates
  const [localValue, setLocalValue] = useState(propertyTaxRate ?? 1.25); // Default to 1.25%

  // Sync local state with Redux when Redux value changes
  useEffect(() => {
    if (propertyTaxRate !== null && propertyTaxRate !== undefined) {
      setLocalValue(propertyTaxRate);
    }
  }, [propertyTaxRate]); // ✅ Syncs when Redux state changes

  const handleChange = (event, newValue) => {
    setLocalValue(newValue); // ✅ Update UI immediately
    dispatch(updatePropertyTaxRate(newValue)); // ✅ Persist in Redux
  };

  return (
    <div>
      <label>Property Tax Rate: {localValue}%</label>
      <Slider
        value={localValue}
        min={0.1} // Minimum rate of 0.1%
        max={5.0} // Maximum rate of 5%
        step={0.05} // Increment by 0.05%
        onChange={(event, newValue) => handleChange(event, newValue)}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${value}%`} // Display percentage when hovering
      />
    </div>
  );
};

export default PropertyTaxRateInput;
