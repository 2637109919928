import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateStandardDeduction } from "../../../../../../../../state/client/clientAffordabilitySlice";
import FormattedNumberInput from "../../../../utils/FormattedNumberInput";
import { getDefaultStandardDeduction } from "../../../../../../../../utils/calcUtils/editClientCalc";

const StandardDeductionInput = () => {
  const dispatch = useDispatch();
  const filingStatus = useSelector(
    (state) => state.clientAffordability.selected_affordability_details.filing_status || ""
  );
  const standardDeduction = useSelector(
    (state) => state.clientAffordability.selected_affordability_details.standard_deduction ?? 0
  );

  const [inputValue, setInputValue] = useState(standardDeduction.toString());

  useEffect(() => {
    // Auto-set default deduction if filing status changes
    if (filingStatus) {
      const defaultDeduction = getDefaultStandardDeduction(filingStatus);
      dispatch(updateStandardDeduction(defaultDeduction));
      setInputValue(defaultDeduction.toString());
    }
  }, [filingStatus, dispatch]);

  const handleChange = (value) => {
    if (value === "" || value === ".") {
      setInputValue(value);
      return;
    }

    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setInputValue(value);
      dispatch(updateStandardDeduction(parseFloat(value) || 0));
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="standard-deduction">Standard Deduction ($):</label>
      <FormattedNumberInput
        id="standard-deduction"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter standard deduction"
        allowDecimals={true}
      />
    </div>
  );
};

export default StandardDeductionInput;
