import React from "react";
import PropTypes from "prop-types";
import InputSlidersMain from "./inputSliders/inputSlidersMain";
import CostAndEquityCharts from "./CostAndEquityCharts";
import ComparisonSection from "../../../components/ComparisonSection";
import ReducePayment from "./goals/ReducePayment";
import RentVsOwnBreakdown from "./RentVsOwnBreakdown/RentVsOwnBreakdown";

import { Collapse } from "antd";

const { Panel } = Collapse;

const IndividualHome = ({ home, ownInputs, rentInputs, generalInputs, affordabilityInputs }) => {
  if (!home) {
    return <p>No home selected.</p>;
  }

  return (
    <div>
      <h2>{home.address}</h2>

      <Collapse accordion>
        <Panel header="Rent vs Own vs S&P 500 Chart" key="2">
          <CostAndEquityCharts 
              home={home} 
              ownInputs={ownInputs} 
              rentInputs={rentInputs} 
              generalInputs={generalInputs} 
              affordabilityInputs={affordabilityInputs}
              />
        </Panel>
        <Panel header="Rent vs Own vs S&P 500 Comparison" key="3">
          <ComparisonSection 
              home={home} 
              ownInputs={ownInputs} 
              rentInputs={rentInputs} 
              generalInputs={generalInputs} />
        </Panel>
        <Panel header="Reduce Monthly Payment" key="4">
          <ReducePayment />
        </Panel>
        <Panel header="Rent Vs Own Breakdown" key="5">
          <RentVsOwnBreakdown 
              home={home}
              ownInputs={ownInputs} 
              rentInputs={rentInputs} 
              generalInputs={generalInputs}
              affordabilityInputs={affordabilityInputs} // ✅ Added
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default IndividualHome;
