import { useSelector } from "react-redux";
import { calculatePaymentPerPeriod } from "./taxCalcs";

export const useMortgageCalculations = () => {
  const selectedHome = useSelector((state) => state.home.selected_home_details) || {};

  // Ensure numerical conversions
  const homePrice = parseFloat(selectedHome.home_price) || 0;
  const downPayment = parseFloat(selectedHome.down_payment) || 0;
  const interestRate = parseFloat(selectedHome.interest_rate) || 0;
  const propertyTaxRate = parseFloat(selectedHome.property_tax_rate) || 0;
  const homeInsuranceFee = parseFloat(selectedHome.home_insurance_fee) || 0;
  const pmiRate = parseFloat(selectedHome.pmi_rate) || 0;
  const hoa = parseFloat(selectedHome.hoa) || 0;

  // Calculate Loan Amount
  const loanAmount = homePrice - downPayment;

  // Convert months to years
  const loanTermYears = (parseInt(selectedHome.term_months, 10) || 0) / 12;

  // Get payments per year (default to 12 if not set)
  const paymentsPerYear = parseInt(selectedHome.payments_per_year, 10) || 12;

  // Calculate Total Number of Payments
  const totalPayments = Math.round(loanTermYears * paymentsPerYear);

  // Calculate Payment Per Period using PMT formula
  const paymentPerPeriod = -calculatePaymentPerPeriod(loanAmount, interestRate, loanTermYears, paymentsPerYear) || 0;

  // Convert Property Taxes to $ per month
  const propertyTaxInDollars = ((homePrice * propertyTaxRate) / 100) / 12 || 0;

  // Convert PMI to $ per month
  const pmiInDollars = (loanAmount * (pmiRate / 100)) / 12 || 0;

  return {
    loanAmount,
    interestRate,
    loanTermYears,
    paymentsPerYear,
    totalPayments,
    paymentPerPeriod,
    propertyTaxInDollars,
    homeInsuranceFee,
    pmiInDollars,
    hoa,
  };
};
