export const fetchClientAffordability = async (clientId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, please log in.");
      return null;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get_client_affordability/${clientId}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        console.error(`HTTP Error: ${response.status} ${response.statusText}`);
        return null;
      }
  
      const data = await response.json();
      return data; // Returns affordability object
    } catch (error) {
      console.error(`An error occurred while fetching affordability data: ${error.message}`);
      return null;
    }
  };
  
  export const updateClientAffordabilityFetch = async (clientId, affordability) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, please log in.");
      return null;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_client_affordability`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ client_id: clientId, ...affordability }),
      });
  
      if (!response.ok) {
        console.error(`HTTP Error: ${response.status} ${response.statusText}`);
        return null;
      }
  
      const result = await response.json();
      return result; // Returns success message
    } catch (error) {
      console.error(`An error occurred while updating affordability data: ${error.message}`);
      return null;
    }
  };
  