import React, { useEffect, useRef } from "react";
import "./TaxBracketModal.css";

const filingStatusMap = {
  single: "Single",
  married_filing_jointly: "Married Filing Jointly",
  married_filing_separately: "Married Filing Separately",
  head_of_household: "Head of Household",
};

const normalizeFilingStatus = (filingStatus) => {
  return filingStatusMap[filingStatus] || filingStatus;
};

const TaxBracketModal = ({
  isOpen,
  onClose,
  filingStatus,
  income,
  grossIncome,
  standardDeduction,
  propertyTaxWriteOff = 2000, // Default write-off value
}) => {
  const modalRef = useRef();

  const taxBrackets = {
    single: [
      { min: 0, max: 9875, rate: 10 },
      { min: 9876, max: 40125, rate: 12 },
      { min: 40126, max: 85525, rate: 22 },
      { min: 85526, max: 163300, rate: 24 },
      { min: 163301, max: 207350, rate: 32 },
      { min: 207351, max: 518400, rate: 35 },
      { min: 518401, max: Infinity, rate: 37 },
    ],
    married_filing_jointly: [
      { min: 0, max: 19750, rate: 10 },
      { min: 19751, max: 80250, rate: 12 },
      { min: 80251, max: 171050, rate: 22 },
      { min: 171051, max: 326600, rate: 24 },
      { min: 326601, max: 414700, rate: 32 },
      { min: 414701, max: 622050, rate: 35 },
      { min: 622051, max: Infinity, rate: 37 },
    ],
    married_filing_separately: [
      { min: 0, max: 9875, rate: 10 },
      { min: 9876, max: 40125, rate: 12 },
      { min: 40126, max: 85525, rate: 22 },
      { min: 85526, max: 163300, rate: 24 },
      { min: 163301, max: 207350, rate: 32 },
      { min: 207351, max: 311025, rate: 35 },
      { min: 311026, max: Infinity, rate: 37 },
    ],
    head_of_household: [
      { min: 0, max: 15900, rate: 10 },
      { min: 15901, max: 59850, rate: 12 },
      { min: 59851, max: 95350, rate: 22 },
      { min: 95351, max: 182100, rate: 24 },
      { min: 182101, max: 231250, rate: 32 },
      { min: 231251, max: 578100, rate: 35 },
      { min: 578101, max: Infinity, rate: 37 },
    ],
  };
  

  const mergedBrackets = Object.entries(taxBrackets).flatMap(([status, brackets]) =>
    brackets.map((bracket) => ({
      ...bracket,
      filingStatus: normalizeFilingStatus(status),
    }))
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  // Calculation of adjusted income
  const adjustedIncome = Math.max(
    (grossIncome || 0) - (standardDeduction || 0) - propertyTaxWriteOff,
    0
  );

  return (
    <div className="modal-overlay-tax">
      <div className="modal-content-tax" ref={modalRef}>
        <button className="modal-close-tax" onClick={onClose}>
          &times;
        </button>
        <h3>Tax Bracket Details</h3>
        <div className="calculation-summary">
        <h4>Filing Status: {normalizeFilingStatus(filingStatus)}</h4>
        <div className="summary-grid">
          <div>
            <strong>Gross Income:</strong> ${grossIncome?.toLocaleString()}
          </div>
          <div>
            <strong>Standard Deduction:</strong> - ${standardDeduction?.toLocaleString()}
          </div>
          <div>
            <strong>Property Tax Maximum Write-Off:</strong> - $2,000
          </div>
          <div>
            <strong>Taxable Income:</strong> ${adjustedIncome.toLocaleString()}
          </div>
        </div>
      </div>

        <h4>Federal Tax Brackets</h4>
        <table>
          <thead>
            <tr>
              <th>Filing Status</th>
              <th>Income Range</th>
              <th>Rate (%)</th>
            </tr>
          </thead>
          <tbody>
            {mergedBrackets.map((bracket, index) => {
              const isHighlighted =
                normalizeFilingStatus(filingStatus) === bracket.filingStatus &&
                adjustedIncome >= bracket.min &&
                adjustedIncome <= bracket.max;
  
              return (
                <tr key={index} className={isHighlighted ? "highlighted" : ""}>
                  <td>{bracket.filingStatus}</td>
                  <td>
                    {bracket.max === Infinity
                      ? `$${bracket.min.toLocaleString()} +`
                      : `$${bracket.min.toLocaleString()} - $${bracket.max.toLocaleString()}`}
                  </td>
                  <td>{bracket.rate}%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
  
};

export default TaxBracketModal;
