// import React from "react";
// import { HorizontalMiddle } from "./HorizontalMiddle";
// import "./AmortizationBenefitInfo.css";

// export const AmortizationBenefitInfo = () => {
//   return (
//     <div className="amortization-benefit">
//       <div className="text-wrapper">Amortization Benefit</div>
//       <div className="overlap-group">
//         <HorizontalMiddle
//           className="horizontal-middle-inset"
//           divider="image.svg"
//           dividerClassName="horizontal-middle-instance"
//         />
//         <div className="div">Total Principal: $7900.44</div>
//       </div>
//     </div>
//   );
// };

import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  amortizationBenefit: {
    width: 192,
    height: 36,
    position: "relative",
    overflow: "hidden",
  },
  header: {
    width: 257,
    position: "absolute",
    left: 3,
    top: 0,
    color: "#243144",
    fontSize: 12,
    fontWeight: "bold",
  },
  separator: {
    alignSelf: "stretch",
    height: 0,
    position: "absolute",
    top: 17, // Positioned below the header
    left: 0,
    backgroundColor: "#D37F51",
    borderBottomWidth: 1,
    borderBottomColor: "#D37F51",
  },
  totalPrincipal: {
    width: 257,
    position: "absolute",
    left: 3,
    top: 18, // Positioned correctly below the separator
    color: "black",
    fontSize: 10,
    fontWeight: "normal",
  },
});

export const AmortizationBenefitInfo = () => {
  return (
    <View style={styles.amortizationBenefit}>
      <Text style={styles.header}>Amortization Benefit</Text>
      <View style={styles.separator} />
      <Text style={styles.totalPrincipal}>Total Principal: $7900.44</Text>
    </View>
  );
};

export default AmortizationBenefitInfo;
