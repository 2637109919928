export const updateClientGeneralInfoFetch = async (clientId, generalInfo) => {
    if (!clientId || clientId === -1) {
        console.error("❌ Invalid client ID provided to updateClientGeneralInfoFetch");
        return null;
    }

    const token = localStorage.getItem('token');
    if (!token) {
        console.error("❌ No token found, please log in.");
        return null;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/update_client_general_info`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ client_id: clientId, ...generalInfo }),
        });

        if (!response.ok) {
            console.error(`❌ HTTP Error: ${response.status} ${response.statusText}`);
            return null;
        }

        const result = await response.json();
        return result; // ✅ Returns success message
    } catch (error) {
        console.error(`❌ An error occurred while updating general info: ${error.message}`);
        return null;
    }
};
