import { calculatePaymentPerPeriod } from "./taxCalcs";

// export const calculateTotalPrincipal = (loanAmount, interestRate, years, paymentsPerYear) => {
//     const totalPayments = Math.min(12, years * paymentsPerYear); // Only calculate for the first 12 payments
//     const monthlyInterestRate = (interestRate / 100) / paymentsPerYear;
//     const yearlyInterestRate = interestRate / 100;
//     let totalPrincipal = 0;
//     let remainingLoan = loanAmount;
//     const paymentPerPeriod = - calculatePaymentPerPeriod(loanAmount, interestRate, years, paymentsPerYear); // Fixed payment per period
    
//     // Calculate the principal for each payment period (up to 12 payments)
//     for (let i = 0; i < totalPayments; i++) {
//       const interestPayment = remainingLoan * yearlyInterestRate / paymentsPerYear;
    
//       // Calculate the principal payment for the current period
//       const principalPayment = paymentPerPeriod - interestPayment;
//       totalPrincipal += principalPayment; // Add the principal to the total principal sum
    
//       // Log the principal per period and remaining loan balance for the first 12 payments
//       console.log(`Payment ${i + 1}: Principal Payment = ${principalPayment}, Remaining Loan = ${remainingLoan}`);
      
//       remainingLoan -= principalPayment; // Subtract principal from remaining loan balance
//     }
    
//     return totalPrincipal.toFixed(2); // Return the total principal paid for the first 12 months
//   };

  export const calculateTotalPrincipal = (loanAmount, interestRate, years, paymentsPerYear) => {
    const totalPayments = Math.min(12, years * paymentsPerYear); // Only calculate for the first 12 payments
    const monthlyInterestRate = (interestRate / 100) / paymentsPerYear;
    const yearlyInterestRate = interestRate / 100;
    let totalPrincipal = 0;
    let remainingLoan = loanAmount;
    const paymentPerPeriod = calculatePaymentPerPeriod(loanAmount, interestRate, years, paymentsPerYear); // Fixed payment per period
    // Calculate the interest for each payment period (up to 12 payments)
    for (let i = 0; i < totalPayments; i++) {
      const interestPayment = remainingLoan * yearlyInterestRate / paymentsPerYear;
      
  
      // Correctly calculate principal payment and update remaining loan balance

      const principalPayment = (paymentPerPeriod * -1) - interestPayment;
      remainingLoan -= principalPayment; // Subtract principal from remaining loan balance
      totalPrincipal += principalPayment;
    }
  
    return totalPrincipal.toFixed(2);
  };
  