import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  updatePresentationType,
  updateStrategyTemplate,
  updateClientType,
  updateRentVsOwn,
  updateReferredBy,
  updatePartnerEmail,
  updatePresentationName,
  updateCoBorrowerFirstName,
  updateCoBorrowerLastName,
  updateCoBorrowerDOB,
  updateCurrentAddressStreet,
  updateCurrentAddressCity,
  updateCurrentAddressState,
  updateCurrentAddressZip,
  updateCurrentEstimatedHomeValue,
  updateIsFirstTimeBuyer,
  updatePartnerPhone,
  updateSelectedClientInfo,
  updateSelectedClientField
} from "../../../../../../state/client/clientInfoSlice";
import "./ClientInfoForm.css";
import { saveClientInfo } from "../../utils/saveClientInfo";
import FormattedNumberInput from '../../utils/FormattedNumberInput';

const ClientInfoForm = () => {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);

  // Get selected client ID from Redux
  const selectedClientId = useSelector((state) => state.client.selected_client_id);

  // Get client info from Redux
  const selectedClientInfo = useSelector((state) => state.clientInfo.selected_client_info);

  // Sync Redux client info when selected client changes
  useEffect(() => {
    if (selectedClientId && !selectedClientInfo.client_id) {
      dispatch(updateSelectedClientInfo(selectedClientId));
    }
  }, [selectedClientId, dispatch, selectedClientInfo]);

  const handleInputChange = (field, value) => {
    // Update Redux state immediately
    switch (field) {
      case "presentation_type":
        dispatch(updatePresentationType(value));
        break;
      case "strategy_template":
        dispatch(updateStrategyTemplate(value));
        break;
      case "client_type":
        dispatch(updateClientType(value));
        break;
      case "rent_vs_own":
        dispatch(updateRentVsOwn(value));
        break;
      case "referred_by":
        dispatch(updateReferredBy(value));
        break;
      case "partner_email":
        dispatch(updatePartnerEmail(value));
        break;
      case "presentation_name":
        dispatch(updatePresentationName(value));
        break;
      case "co_borrower_first_name":
        dispatch(updateCoBorrowerFirstName(value));
        break;
      case "co_borrower_last_name":
        dispatch(updateCoBorrowerLastName(value));
        break;
      case "co_borrower_dob":
        dispatch(updateCoBorrowerDOB(value));
        break;
      case "current_address_street":
        dispatch(updateCurrentAddressStreet(value));
        break;
      case "current_address_city":
        dispatch(updateCurrentAddressCity(value));
        break;
      case "current_address_state":
        dispatch(updateCurrentAddressState(value));
        break;
      case "current_address_zip":
        dispatch(updateCurrentAddressZip(value));
        break;
      case "current_estimated_home_value":
        dispatch(updateCurrentEstimatedHomeValue(Number(value)));
        break;
      case "is_first_time_buyer":
        dispatch(updateIsFirstTimeBuyer(value));
        break;
      case "partner_phone":
        dispatch(updatePartnerPhone(value));
        break;
      default:
        break;
    }
  };

  const handleSave = async () => {
    if (selectedClientId === -1) {
      alert("No client selected. Please select a client before saving.");
      return;
    }
  
    // Update `client_id` in `selectedClientInfo`
    const updatedClientInfo = { ...selectedClientInfo, client_id: selectedClientId };
  
    setIsSaving(true);
    try {
      console.log("Saving client info:", updatedClientInfo);
      await saveClientInfo(updatedClientInfo); // Save to backend
      alert("Client information saved successfully!");
    } catch (error) {
      console.error("Error saving client information:", error);
      alert("Failed to save client information. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };
  
  

  return (
    <div className="client-info-form">
      <h2>Client Information</h2>
      <form>
        {/* General Information */}
        {/* <section className="form-section">
          <h3>General Information</h3>
          <label>
            Presentation Type:
            <input
              type="text"
              value={selectedClientInfo.presentation_type || ""}
              onChange={(e) =>
                handleInputChange("presentation_type", e.target.value)
              }
            />
          </label>
          <label>
            Strategy Template:
            <input
              type="text"
              value={selectedClientInfo.strategy_template || ""}
              onChange={(e) =>
                handleInputChange("strategy_template", e.target.value)
              }
            />
          </label>
          <label>
            Client Type:
            <input
              type="text"
              value={selectedClientInfo.client_type || ""}
              onChange={(e) => handleInputChange("client_type", e.target.value)}
            />
          </label>
          <label>
            Rent vs. Own:
            <select
              value={selectedClientInfo.rent_vs_own || ""}
              onChange={(e) => handleInputChange("rent_vs_own", e.target.value)}
            >
              <option value="">Select...</option>
              <option value="rent">Rent</option>
              <option value="own">Own</option>
            </select>
          </label>
        </section> */}

        {/* Referrer and Partner Info */}
        <section className="form-section">
          <h3>Referrer & Partner Information</h3>
          <label>
            Referred By:
            <input
              type="text"
              value={selectedClientInfo.referred_by || ""}
              onChange={(e) =>
                handleInputChange("referred_by", e.target.value)
              }
            />
          </label>
          <label>
            Partner Email:
            <input
              type="email"
              value={selectedClientInfo.partner_email || ""}
              onChange={(e) =>
                handleInputChange("partner_email", e.target.value)
              }
            />
          </label>
          <label>
            Partner Phone:
            <input
              type="tel"
              value={selectedClientInfo.partner_phone || ""}
              onChange={(e) =>
                handleInputChange("partner_phone", e.target.value)
              }
            />
          </label>
        </section>

        {/* Address */}
        <section className="form-section">
          <h3>Current Address</h3>
          <label>
            Street:
            <input
              type="text"
              value={selectedClientInfo.current_address_street || ""}
              onChange={(e) =>
                handleInputChange("current_address_street", e.target.value)
              }
            />
          </label>
          <label>
            City:
            <input
              type="text"
              value={selectedClientInfo.current_address_city || ""}
              onChange={(e) =>
                handleInputChange("current_address_city", e.target.value)
              }
            />
          </label>
          <label>
            State:
            <input
              type="text"
              value={selectedClientInfo.current_address_state || ""}
              onChange={(e) =>
                handleInputChange("current_address_state", e.target.value)
              }
            />
          </label>
          <label>
            ZIP:
            <input
              type="text"
              value={selectedClientInfo.current_address_zip || ""}
              onChange={(e) =>
                handleInputChange("current_address_zip", e.target.value)
              }
            />
          </label>
          <label>
  First Time Buyer:
  <input
    type="checkbox"
    checked={selectedClientInfo.is_first_time_buyer || false}
    onChange={(e) =>
      handleInputChange("is_first_time_buyer", e.target.checked)
    }
  />
</label>

{/* Conditionally render Estimated Home Value */}
{!selectedClientInfo.is_first_time_buyer && (
  <label>
    Estimated Home Value:
    <FormattedNumberInput
            value={selectedClientInfo.current_estimated_home_value || ""}
            onChange={(value) =>
              handleInputChange("current_estimated_home_value", value)
            }
            placeholder="Enter estimated home value"
          />
  </label>
)}

        </section>

        {/* Submit Button */}
        <button
          type="button"
          onClick={handleSave}
          disabled={isSaving}
        >
          {isSaving ? "Saving..." : "Save"}
        </button>
      </form>
    </div>
  );
};

export default ClientInfoForm;
