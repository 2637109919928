// import React from "react";
// import { HorizontalMiddle } from "./HorizontalMiddle";
// import "./InvestmentBenefitInfo.css";

// export const InvestmentBenefitInfo = () => {
//   return (
//     <div className="investment-benefit">
//       <div className="text-wrapper">Investment Benefit</div>
//       <div className="overlap-group">
//         <HorizontalMiddle
//           className="horizontal-middle-inset"
//           divider="image.svg"
//           dividerClassName="horizontal-middle-instance"
//         />
//         <div className="flexcontainer">
//           <p className="text">
//             <span className="span">Appreciation Rate: 5.00% <br /></span>
//           </p>
//           <p className="text">
//             <span className="span">Down Payment: $40000</span>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };
import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { HorizontalMiddle } from "./HorizontalMiddle";

const styles = StyleSheet.create({
  investmentBenefit: {
    height: 106,
    overflow: "hidden",
    position: "relative",
    width: 194,
    padding: 10,
  },
  textWrapper: {
    color: "#243144",
    fontSize: 12,
    fontWeight: "semibold",
    position: "absolute",
    top: -1,
    left: 3,
    width: 257,
  },
  overlapGroup: {
    height: 20,
    position: "absolute",
    top: 17,
    left: -13,
    width: 273,
  },
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    height: 20,
    position: "absolute",
    left: 16,
    top: 0,
    width: 257,
  },
  text: {
    fontSize: 10,
    color: "#d9d9d9",
  },
  span: {
    fontSize: 10,
    color: "#000000",
  },
});

export const InvestmentBenefitInfo = () => {
  return (
    <View style={styles.investmentBenefit}>
      <Text style={styles.textWrapper}>Investment Benefit</Text>
      <View style={styles.overlapGroup}>
        <HorizontalMiddle />
        <View style={styles.flexContainer}>
          <Text style={styles.text}><Text style={styles.span}>Appreciation Rate: 5.00%</Text></Text>
          <Text style={styles.text}><Text style={styles.span}>Down Payment: $40000</Text></Text>
        </View>
      </View>
    </View>
  );
};

export default InvestmentBenefitInfo;