import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateHOAFee } from '../../../../../../../../state/home/homeSlice';
import FormattedNumberInput from '../../../../utils/FormattedNumberInput';

const HOAFeeInput = () => {
  const dispatch = useDispatch();
  const reduxHOAFee = useSelector((state) => state.home.selected_home_details.hoa);

  // 🔥 Ensure a valid default value
  const safeHOAFee = reduxHOAFee !== undefined && reduxHOAFee !== null ? reduxHOAFee : 0;

  // 🔥 Local state for smooth typing experience
  const [inputValue, setInputValue] = useState(safeHOAFee.toString());

  useEffect(() => {
    if (reduxHOAFee !== undefined && reduxHOAFee !== null && reduxHOAFee.toString() !== inputValue) {
      setInputValue(reduxHOAFee.toString());
    }
  }, [reduxHOAFee]);

  const handleChange = (value) => {
    if (value === "" || value === ".") {
      setInputValue(value);
      return;
    }

    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setInputValue(value);
      dispatch(updateHOAFee(parseFloat(value) || 0)); // ✅ Always store as number in Redux
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="hoa-fee">HOA Fee ($):</label>
      <FormattedNumberInput
        id="hoa-fee"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter HOA Fee"
        allowDecimals={true}
      />
    </div>
  );
};

export default HOAFeeInput;
