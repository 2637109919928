import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { useSelector, useDispatch } from 'react-redux';
import { updateMonthlyRentInsurance } from '../../../../../../state/presentation/adjustInputsSlice';

const MonthlyRentInsurance = () => {
  const dispatch = useDispatch();

  // Fetch from Redux
  const monthlyRentInsurance = useSelector(
    (state) => state.adjustInputs.rentInputs.monthlyRentInsurance
  );

  // State should initialize from Redux, but not reset when other Redux states update
  const [localValue, setLocalValue] = useState(monthlyRentInsurance || 50);

  // Only initialize local state when component mounts or Redux changes from null to a valid value
  useEffect(() => {
    if (monthlyRentInsurance !== null && monthlyRentInsurance !== undefined) {
      setLocalValue(monthlyRentInsurance);
    }
  }, []); // ✅ Empty dependency array prevents unnecessary resets

  const handleChange = (event, newValue) => {
    setLocalValue(newValue); // Keep UI responsive
    dispatch(updateMonthlyRentInsurance(newValue)); // ✅ Dispatch update to Redux
  };

  return (
    <div>
      <label>Monthly Rent Insurance: ${localValue}</label>
      <Slider
        value={localValue}
        min={0}
        max={200}
        step={5}
        onChange={(event, newValue) => handleChange(event, newValue)}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `$${value}`} // Format value as currency
      />
    </div>
  );
};

export default MonthlyRentInsurance;
