import React from "react";

// ✅ Use a named export
export function calculateAmortization(month, loanAmount, annualInterestRate, loanTermYears) {
    const monthlyInterestRate = (annualInterestRate / 100) / 12;
    const totalMonths = loanTermYears * 12;
    const monthlyPayment = (loanAmount * monthlyInterestRate) / 
                           (1 - Math.pow(1 + monthlyInterestRate, -totalMonths));

    let remainingBalance = loanAmount;
    let interestPaid, principalPaid;

    for (let i = 1; i <= month; i++) {
        interestPaid = remainingBalance * monthlyInterestRate;
        principalPaid = monthlyPayment - interestPaid;
        remainingBalance -= principalPaid;
    }

    return {
        month,
        monthlyPayment: parseFloat(monthlyPayment.toFixed(2)),
        interestPaid: parseFloat(interestPaid.toFixed(2)),
        principalPaid: parseFloat(principalPaid.toFixed(2)),
        remainingBalance: parseFloat(remainingBalance.toFixed(2))
    };
}
