import { getTaxRate } from "../../modules/pages/clientPresentationLayout/onboardingClient/utils/TaxRate";


export const STANDARD_DEDUCTION_DEFAULTS = {
  single: 13850, // 2024 value for Single
  married_filing_jointly: 27700, // 2024 value for Married Filing Jointly
  head_of_household: 20800, // 2024 value for Head of Household
  married_filing_separately: 13850, // Default value
};

/**
 * Calculate the tax bracket based on filing status and gross income.
 * @param {string} filingStatus - Filing status (e.g., single, married).
 * @param {number} grossIncome - Current annual gross income.
 * @returns {number} Tax bracket as a percentage.
 */
export const calculateTaxBracket = (filingStatus, grossIncome) => {
  if (filingStatus && grossIncome) {
    return getTaxRate(filingStatus, Number(grossIncome)) || 0;
  }
  return 0;
};

/**
 * Get the default standard deduction for a given filing status.
 * @param {string} filingStatus - Filing status.
 * @returns {number} Standard deduction for the filing status.
 */
export const getDefaultStandardDeduction = (filingStatus) => {
  return STANDARD_DEDUCTION_DEFAULTS[filingStatus] || 0;
};

/**
 * Calculate adjusted taxable income based on gross income and deductions.
 * @param {number} grossIncome - Current annual gross income.
 * @param {number} standardDeduction - Standard deduction.
 * @param {number} propertyTaxWriteOff - Property tax write-off.
 * @returns {number} Adjusted taxable income.
 */
export const calculateAdjustedTaxableIncome = (grossIncome, standardDeduction, propertyTaxWriteOff = 0) => {
  return Math.max(0, grossIncome - standardDeduction - propertyTaxWriteOff);
};
