export const addHomeFetch = async (homeDetails) => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token found, please log in.');
    return null;
  }

  console.log("📤 Sending API request to add home with details:", homeDetails);

  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/add_home`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(homeDetails),
    });

    if (!response.ok) {
      console.error(`❌ HTTP Error: ${response.status} ${response.statusText}`);
      const errorMessage = await response.text();
      console.error(`❌ Response message: ${errorMessage}`);
      return null;
    }

    const result = await response.json();
    console.log("✅ API response from server:", result);
    return result;
  } catch (error) {
    console.error(`❌ An error occurred in addHomeFetch: ${error.message}`);
    return null;
  }
};
