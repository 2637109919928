const SERVER_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:8080";

const checkPopupBlocked = (newTab) => {
    if (!newTab || newTab.closed || typeof newTab.closed === "undefined") {
        alert("⚠️ Pop-ups are blocked! Please allow pop-ups for this site to open Google Sheets.");
    }
};

// ✅ Function to authenticate and generate the spreadsheet
export const authenticateGoogleAndGenerateSheet = async (ownInputs, navigate) => {
    try {
        // ✅ Save user inputs before redirecting
        localStorage.setItem("loanInputs", JSON.stringify(ownInputs));

        const isAuthenticated = await checkAuthentication();

        if (!isAuthenticated) {
            // ✅ Redirect to Google OAuth login
            window.location.href = `${SERVER_URL}/api/sheets/oauth-login`;
            return;
        }

        console.log("✅ User authenticated, generating Google Sheet...");

        // ✅ If authenticated, generate the sheet and open it in a new tab
        await generateGoogleSheet(ownInputs, navigate);

    } catch (error) {
        console.error("❌ Error during authentication and sheet generation:", error);
        alert("An error occurred. Please try again.");
    }
};




// ✅ Modify generateGoogleSheet to accept navigate
export const generateGoogleSheet = async (ownInputs, navigate) => {
    try {
        if (!ownInputs) {
            alert("No home data available for generating Google Sheet.");
            return;
        }

        // ✅ Open a blank tab immediately (avoids pop-up blockers)
        const newTab = window.open("about:blank", "_blank");

        const response = await fetch(`${SERVER_URL}/api/sheets/generate-sheet`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(ownInputs),
            credentials: "include",
        });

        const data = await response.json();
        if (response.ok) {
            console.log(`✅ Google Sheet successfully created! ${data.url}`);

            // ✅ Update the new tab with the spreadsheet URL
            if (newTab) {
                newTab.location.href = data.url;
            } else {
                alert(`✅ Google Sheet created! Open it manually: ${data.url}`);
            }

            // ✅ Keep the main site on the LoanPacket page
            navigate("/ClientLayout/LoanPacket");

        } else {
            alert(`❌ Error creating sheet: ${data.error}`);
            if (newTab) newTab.close(); // Close blank tab if sheet creation failed
        }
    } catch (error) {
        alert("Failed to generate Google Sheet.");
    }
};



// ✅ Function to check if user is authenticated
const checkAuthentication = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/api/sheets/check-auth`, {
        method: "GET",
        credentials: "include",
      });

      return response.ok;
    } catch (error) {
      console.error("Authentication check failed:", error);
      return false;
    }
};
