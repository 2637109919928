import React, { useState, useRef, useEffect } from 'react';
import { FaCommentDots, FaTimes, FaPaperPlane } from 'react-icons/fa';
import './Chatbot.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserContact } from '../../apiFetch/fetchUserContact';
import { setContactInfo } from '../../state/user/userContactSlice';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'; // Make sure this import is added



const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const chatbotRef = useRef(null);
  const dragHandleRef = useRef(null);
  const dispatch = useDispatch(); // Initialize the Redux dispatch function

  // Get state from Redux
  const clientId = useSelector((state) => state.client.selected_client_id);
  const home = useSelector((state) => state.home.selected_home_id);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const chatHistoryRef = useRef(null);

  // Log `clientId` changes
  useEffect(() => {
    console.log("Updated clientId:", clientId);
  }, [clientId]);

    // Initialize chatbot with a standard welcome message
useEffect(() => {
  setMessages([
    {
      sender: 'bot',
      text: "Hello! I'm Botimus Prime 🤖. I can help you with the following:\n\n" +
            "• Answering questions about your account\n" +
            "• Providing details on clients and homes\n" +
            "• Assisting with real estate-related inquiries\n\n" +
            "How can I assist you today?",
    },
  ]);
}, []);


  // Scroll chat history to the bottom when messages are added
  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]);

  // Add drag-and-resize functionality
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (dragHandleRef.current && dragHandleRef.current.isDragging) {
        const chatbot = chatbotRef.current;
        const deltaX = dragHandleRef.current.startX - e.clientX;
        const deltaY = dragHandleRef.current.startY - e.clientY;

        chatbot.style.width = `${dragHandleRef.current.startWidth + deltaX}px`;
        chatbot.style.height = `${dragHandleRef.current.startHeight + deltaY}px`;
      }
    };

    const handleMouseUp = () => {
      if (dragHandleRef.current) {
        dragHandleRef.current.isDragging = false;
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const startDragging = (e) => {
    const chatbot = chatbotRef.current;
    dragHandleRef.current = {
      isDragging: true,
      startX: e.clientX,
      startY: e.clientY,
      startWidth: chatbot.offsetWidth,
      startHeight: chatbot.offsetHeight,
    };
  };

  const toggleChatbot = () => {
    setIsOpen((prev) => !prev);
  };

  const [isBotTyping, setIsBotTyping] = useState(false);

  const handleSendMessage = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token found, please log in.');
      return;
    }
  
    if (input.trim()) {
      // Immediately show the user's message
      setMessages((prevMessages) => [...prevMessages, { sender: 'user', text: input }]);
      setInput(''); // Clear the input field right away
      setIsBotTyping(true); // Show "bot is typing" message
      
      try {
        // Construct the request payload
        const payload = {
          message: input,
          ...(clientId && clientId !== -1 && { client_id: clientId }), // Include client_id only if valid
          ...(clientId && clientId !== -1 && home && home !== -1 && { home_id: home }), // Include home_id only if clientId is valid and home exists
        };
  
        console.log('Payload being sent:', payload);
  
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/chat`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload), // Send the full payload to the backend
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        // Parse the JSON response
        const data = await response.json();
        console.log('Response from server:', data);
  
        if (data.reply) {
          setMessages((prevMessages) => [
            ...prevMessages.filter((msg) => msg.text !== 'Bot is typing...'), // Remove "typing" message when reply arrives
            { sender: 'bot', text: data.reply }
          ]);
        } else {
          setMessages((prevMessages) => [
            ...prevMessages.filter((msg) => msg.text !== 'Bot is typing...'),
            { sender: 'bot', text: "Sorry, I couldn't process your request." }
          ]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setMessages((prevMessages) => [
          ...prevMessages.filter((msg) => msg.text !== 'Bot is typing...'),
          { sender: 'bot', text: "Sorry, I couldn't process your request." }
        ]);
      } finally {
        setIsBotTyping(false);
      }
    }
  };
  
  

  return (
    <div className="chatbot">
      {isOpen ? (
        <div className="chatbot-window" ref={chatbotRef}>
          <div className="chatbot-header">
            <div
              className="drag-handle"
              onMouseDown={startDragging}
              title="Drag to resize"
            />
            <h3>Chatbot</h3>
            <FaTimes onClick={toggleChatbot} className="close-icon" />
          </div>
          <div className="chatbot-history" ref={chatHistoryRef}>
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.sender === 'bot' ? 'bot' : 'user'}`}>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>{msg.text}</ReactMarkdown>
              </div>
            ))}
            {isBotTyping && (
              <div className="message bot typing-indicator">
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>I'm thinking...</ReactMarkdown>
              </div>
            )}
          </div>
          <div className="chatbot-input-area">
            <input
              type="text"
              placeholder="Type your message..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            />
            <button onClick={() => handleSendMessage()} className="send-button">
              <FaPaperPlane />
            </button>
          </div>
        </div>
      ) : (
        <button className="chatbot-toggle" onClick={toggleChatbot}>
          <FaCommentDots />
        </button>
      )}
    </div>
  );
};

export default Chatbot;