import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchClientGeneralInfo } from './fetches/fetchClientGeneralInfo';
import { updateClientGeneralInfoFetch } from './fetches/updateGeneralInfoFetch'
import { updateSelectedGeneralInfo, setGeneralInfo } from '../../../../../../../state/client/clientGeneralInfoSlice';
import InflationRateInput from './formInputs/InflationRateInput';
import ExpectedInvestmentGrowthInput from './formInputs/ExpectedInvestmentGrowthInput';
import GeneralMonthlyExpensesInput from './formInputs/GeneralMonthlyExpensesInput';
import ExpectedSalaryIncreaseInput from './formInputs/ExpectedSalaryIncreaseInput';

const GeneralInfoMain = () => {
    const dispatch = useDispatch();
    const clientId = useSelector((state) => state.client.selected_client_id);
    const generalInfo = useSelector((state) => state.clientGeneralInfo.selected_general_info);

    useEffect(() => {
        if (clientId !== -1) {
            fetchClientGeneralInfo(clientId).then((data) => {
                if (data) {
                  dispatch(setGeneralInfo(data)); // Stores in generalInfoByClient
                  dispatch(updateSelectedGeneralInfo(data.client_id)); // Syncs selected fields
                }
              });              
        }
    }, [clientId, dispatch]);

    const handleSave = async () => {
        console.log("Saving General Information:", generalInfo);
        const success = await updateClientGeneralInfoFetch(clientId, generalInfo);
        if (success) {
            alert("✅ General information saved!");
        } else {
            alert("❌ Failed to save general information.");
        }
    };

    return (
        <div className="general-info-main">
            <h2>General Information</h2>
            <form>
                <InflationRateInput />
                <ExpectedInvestmentGrowthInput />
                <GeneralMonthlyExpensesInput />
                <ExpectedSalaryIncreaseInput />
                <button type="button" onClick={handleSave} className="save-button">
                    Save General Information
                </button>
            </form>
        </div>
    );
};

export default GeneralInfoMain;
