import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateHomePrice } from '../../../../../../../../state/home/homeSlice';
import FormattedNumberInput from '../../../../utils/FormattedNumberInput';

const HomePriceInput = () => {
  const dispatch = useDispatch();
  const reduxHomePrice = useSelector(
    (state) => state.home.selected_home_details.home_price
  );

  // 🔥 Local state for smooth typing experience
  const [inputValue, setInputValue] = useState(reduxHomePrice?.toString() || "");

  useEffect(() => {
    if (reduxHomePrice?.toString() !== inputValue) {
      setInputValue(reduxHomePrice?.toString() || "");
    }
  }, [reduxHomePrice]);

  const handleChange = (value) => {
    // ✅ Allow blank input or just a decimal for smoother typing
    if (value === "" || value === ".") {
      setInputValue(value);
      return;
    }

    // ✅ Ensure valid number input (allows two decimals)
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setInputValue(value);
      dispatch(updateHomePrice(parseFloat(value) || 0)); // ✅ Always store as number in Redux
    }
  };

  return (
    <div className="form-group">
      <label htmlFor="home-price">Home Price:</label>
      <FormattedNumberInput
        id="home-price"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter home price"
        allowDecimals={true}
      />
    </div>
  );
};

export default HomePriceInput;
