import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateMonthlyRentInsurance } from "../../../../../../../../state/client/clientAssumptionsSlice";
import FormattedNumberInput from "../../../../utils/FormattedNumberInput";

const MonthlyRentInsuranceInput = () => {
  const dispatch = useDispatch();

  // Get monthly rent insurance value from Redux state
  const monthlyRentInsurance = useSelector(
    (state) =>
      state.clientAssumptions.selected_assumptions.monthly_rent_insurance
  );

  // Handle input change
  const handleChange = (value) => {
    dispatch(updateMonthlyRentInsurance(value));
  };

  return (
    <div className="form-group">
      <label htmlFor="monthly-rent-insurance">Monthly Rent Insurance</label>
      <FormattedNumberInput
        id="monthly-rent-insurance"
        value={monthlyRentInsurance || ""}
        onChange={handleChange}
        placeholder="Enter monthly rent insurance"
      />
    </div>
  );
};

export default MonthlyRentInsuranceInput;
